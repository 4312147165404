import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { fetchUsers } from "redux/slices/users";
import bin from "assets/svg/icons/bin.svg";
import change_white_svg from "assets/svg/icons/change_white_svg.svg";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  item: any;
  profile: any;
  id: any;
  setIsEdit: any;
}

export const Description = ({
  item,
  profile,
  id,
  setIsEdit,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [value2, setValue2] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [edit, setEdit] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(descriptionsCRUD.fetch());
    setValue("emailContent", item?.description);
  }, []);

  const modules = {
    toolbar: false,
  };

  const deleteItem = (id: number) => {
    dispatch(descriptionsCRUD.remove(id));
    dispatch(descriptionsCRUD.fetch());
  };

  const onSubmit = (number: number) => {
    id
      ? dispatch(
          descriptionsCRUD.edit(number, {
            title: titleInput === "" ? item.title : titleInput,
            description: editorContent,
            my_order: 0,
            user: profile.id,
          })
        )
      : dispatch(
          descriptionsCRUD.edit(number, {
            title: titleInput === "" ? item.title : titleInput,
            description: editorContent,
            my_order: 0,
            user: profile.id,
          })
        );

    dispatch(descriptionsCRUD.fetch());
    dispatch(descriptionsCRUD.fetch());
  };

  useEffect(() => {
    register("emailContent", { required: true });
  }, [register]);

  const onEditorStateChange = (editorState: any) => {
    console.log(editorState);

    setValue("emailContent", editorState);
  };
  const editorContent = watch("emailContent");

  const text = item?.description;

  return (
    <>
      {edit ? (
        <div key={item.id} className="form--item">
          <div className="twice_inputs">
            <div style={{ width: "61%", paddingTop: '20px' }}>
              <div>
                <input
                  placeholder="Заголовок"
                  onChange={(e) => setTitleInput(e.target.value)}
                  type="text"
                  defaultValue={item.title}
                />
              </div>
              <div style={{ paddingTop: '15px' }}>
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={onEditorStateChange}
                />
              </div>
            </div>
            <div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div
                  style={{ width: "40px", background: "#125d98" }}
                  className="description__block_delete"
                  onClick={() => {
                    setEdit(false);
                    onSubmit(item.id);
                  }}
                >
                  ok
                </div>

               
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div key={item.id} className="profile__about" >
          <div className="profile__about__title">{item.title}</div>
         <div style={{display: 'flex', alignItems: 'center', gap: '10px',}}> 
         <div className="profile__about__text" style={{width: '62%'}}>
            <ReactQuill
              readOnly={true}
              theme="snow"
              value={item?.description}
              modules={modules}
            />
          </div>
          <div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <div
                style={{
                  width: "35px",
                  height: '35px',
                  background: "#125d98",
                  borderRadius: "50%",
                }}
                className="description__block_delete"
                onClick={() => {
                  setEdit(true);
                }}
              >
                <img src={change_white_svg} alt="" />
              </div>
              <div>
                  <img
                    className="edit_icon"
                    style={{ width: "25px", height: "25px" }}
                    src={bin}
                    alt=""
                    onClick={() => deleteItem(item.id)}
                  />
                </div>
            </div>
          </div>
         </div>
        </div>
      )}
    </>
  );
};
