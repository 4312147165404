import { Link, useLocation, redirect } from "react-router-dom";
import { useRoutePermissions } from 'hooks/usePermissions';

type CartItemProps = {
  to: string;
  title: string;
  img: string
};

export const SidebarItem: React.FC<CartItemProps> = ({ to, title, img}) => {
  const location = useLocation();
  const { pathname } = location;
  useRoutePermissions(pathname);

  const path = location.pathname.split('/')[2] ? location.pathname.split('/')[2] : "";
  
  return (
         <Link className={`sidebar__link ${to === (path) ? 'active' : ''}`} to={to}>
            <img className="dsd" src={img} alt="" />
            <div className="sidebar__title">{title}</div>
         </Link>
    );
};