import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getNormalFromToDate,getRemainingSeats } from 'utils/functions'
import { Modal } from "components/admin";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ToursCRUD } from "redux/slices/tours";

interface Props {
  item: any;
  isPublic : any;
}

export const Item = ({item, isPublic=false} : Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state);
  const role = profile?.data?.role
  
  const {
    id,
    user,
    date,
    amount_of_people,
    hajhead_duty,
    hajhead_registered,
    firm_duty,
    firm_registered,
    registered_people,
    extra_registered,
    free_places,
    amount_of_days,
    category,
    desired_hotels,
    total_price,
    public_price,
    flight,
    is_published,
    is_archived,
    airline,
    status,
  } = item;

  const [place, setPlace] = useState({
    amount_of_people,
    hajhead_duty,
    hajhead_registered,
    firm_duty,
    firm_registered,
    registered_people,
    extra_registered,
    free_places,
  });

  useEffect(() => {
    setPlace({
      amount_of_people : item.amount_of_people,
      hajhead_duty : item.hajhead_duty,
      hajhead_registered: item.hajhead_registered,
      firm_duty : item.firm_duty,
      firm_registered : item.firm_registered,
      registered_people : item.registered_people,
      extra_registered : item.extra_registered,
      free_places : item.free_places
    })
  }, [id])

  useEffect(() => {
    const registered_people =
      +place.hajhead_registered + +place.firm_registered;
    const free_places =
      +place.amount_of_people -
      (+place.hajhead_registered + +place.firm_registered);
    const extra_registered = +place.amount_of_people - registered_people;
    const firm_duty =
      +place.amount_of_people -
      Math.max(+place.hajhead_duty, +place.hajhead_registered);
    setPlace({
      ...place,
      registered_people: registered_people,
      free_places: free_places < 0 ? 0 : free_places,
      extra_registered: free_places < 0 ? Math.abs(extra_registered) : 0,
      firm_duty: firm_duty,
    });
  }, [place.hajhead_duty, place.hajhead_registered, place.firm_registered]);

  const handlePlaceChange = (e: any) => {
    if (
      e.target.name === "hajhead_duty" &&
      e.target.value > place.amount_of_people
    ) {
      return;
    } else {
      setPlace({ ...place, [e.target.name]: e.target.value ? e.target.value : 0 });
    }
  };

  const handlePlaceSave = (e: any) => {
    e.preventDefault();
    dispatch(ToursCRUD.patch(id, place));
    setIsOpen(false);
  };

    return(
      <tr>
        <td><label className="info_describe-text">№:</label><Link to={`/book/${id}`}>{user.id}</Link></td>
        <td><label className="info_describe-text">Авиакомпании:</label><Link to={`/book/${id}`}>{flight.airline_detail.title}</Link></td>
        <td><label className="info_describe-text">Дата:</label>{getNormalFromToDate(item?.flight.date_from, item?.flight.date_to)}</td>
        <td><label className="info_describe-text">Город вылета:</label>{flight.city.title}</td>
        <td><label className="info_describe-text">Маршрут:</label>{flight.routes[0].city_title}-{flight.routes[1].city_title}</td>
        <td>
        {isPublic ? (
          <>
            <label className="info_describe-text">Кол-во мест:</label>
            {getRemainingSeats((amount_of_people - firm_registered) - hajhead_registered)}
          </>
        ) :  
       ( registered_people >= 0 && (role !== 3 || role !== 4) ? (
        <div className="places" onClick={() => {
          if(role === 1 || role === 2 || role === 5){
            setIsOpen(!isOpen)
          }
          }}>
          <div className="places__count">
            {registered_people ? (
              <div
                className="reserved"
                style={{
                  width: `${registered_people * (100 / amount_of_people)}%`,
                }}
              >
                {registered_people}
              </div>
            ) : null}
            {amount_of_people - registered_people ? (
              <div
                className="unreserved"
                style={{
                  width: `${
                    (amount_of_people - registered_people) *
                    (100 / amount_of_people)
                  }%`,
                }}
              >
                {amount_of_people - registered_people}
              </div>
            ) : null}
          </div>
          <div className="passendjer__total--count">{amount_of_people}</div>
        </div>
      ) : (
        <div className="passendjer__total--count">{amount_of_people}</div>
      ))
      }
        
        <Modal
          active={isOpen}
          setActive={setIsOpen}
          element={
            <form className="modal__body tour_places">
              <div className="tour_places__counts">
                <div className="reserved">
                  <label>Запись обшый</label>
                  <span>{place.amount_of_people}</span>
                </div>
                <div className="reserved">
                  <label>Фирма обязуется</label>
                  <span>{place.firm_duty}</span>
                </div>
                <div className="reserved">
                  <label>Записанные</label>
                  <span>{place.registered_people}</span>
                </div>
                <div className="reserved">
                  <label>Дополнительные</label>
                  <span>{place.extra_registered}</span>
                </div>
                <div className="reserved">
                  <label>Свободные</label>
                  <span>{place.free_places}</span>
                </div>
              </div>
              <div className="tour_places__counts">
                <div className="tour_places__count">
                  <label>Ажы башы обязуется</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.hajhead_duty}
                    name="hajhead_duty"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
                <div className="tour_places__count">
                  <label>Записанные у ажи башы</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.hajhead_registered}
                    name="hajhead_registered"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
                <div className="tour_places__count">
                  <label>Записанные у фирмы</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.firm_registered}
                    name="firm_registered"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
              </div>
              <div className="modal__actions">
                <input
                  type="submit"
                  value="Сохранить"
                  onClick={(e) => handlePlaceSave(e)}
                />
              </div>
            </form>
          }
        />
        </td>
      </tr>
    )
} 