import React, { SetStateAction, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import { useAppSelector, useAppDispatch } from 'redux/hooks';

interface Props {
  routes: any[];
  HanleAddRoute: any;
  HanleDeleteRoute: any;
  HanleChangeRoute: any;
  // icon: any;
  title: any;
  type: any;
  control: any;
  errors: any;
  item: any;
  setValue: any
}

export const EditModelBlock = ({
  routes,
  HanleAddRoute,
  HanleDeleteRoute,
  HanleChangeRoute,
  // icon,
  title,
  type,
  control,
  errors,
  item,
  setValue
}: Props) => {

  
  // fetch data from our store
  const { cities } = useAppSelector((state) => state);

  // console.log('cities', cities);
  const routesValue = useMemo(()=> {
    return routes?.map(i => (
       {
        ...i,
        label: i.city_title,
        value: i.city
      }
    ))
  }, [routes])

  // setValue(`city${route.id}`, {
  //   id: route?.city,
  //   title: route?.city_title,
  //   value: route?.city,
  //   label: route?.city_title,
  // });

  return (
    <div className="block1">
      <div className="arrow_next">
        <div>{title} </div>
        {/* <img src={icon} alt="" /> */}
      </div>

      <div className="huy">
        {routesValue?.map((route, index) => {
  
          // setValue(`city${route.id}`, route)
        
          return (
            <div className="punkt" key={route.id}>
              <div className="flights__item error">
                <label className="flights_label">{index + 1} пункт</label> <br />
                {/* <input className="flights_input" name="city" /> */}
                <Controller
                  name={`city${route.id}`}
                  control={control}
                  defaultValue={(
                    {...cities.items.find((i:any)=> i.id === route.city), 
                        get title(){
                          return this.label
                        },
                        get value(){
                          return `${this.id}`
                        }
                    })}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      // isClearable
                      // defaultValue={route}
                      isDisabled={cities.loading}
                      isLoading={cities.loading}
                      options={cities.items}
                      placeholder={<div>Выберите.....</div>}
                      // formatCreateLabel={(userInput) => `Создать: ${userInput}`}
                      //   onCreateOption={option => onCityCreate(option)}
                      onChange={(newValue) => HanleChangeRoute(route.id, newValue)}
                    />
                  )}
                />
                {errors?.[String('city' + route.id)] && (
                  <div className="error_text">Выберите город</div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="buttons_popup">
        <div className="buttons_inner_popup">
          <button className="add_popup" onClick={(e) =>{e.preventDefault();HanleAddRoute(type)}}>
            Добавить{' '}
          </button>
          <button disabled={routes ? !routes.some((i:any)=> i.type === type) : true} className="remove_popup" onClick={(e) =>{ e.preventDefault();HanleDeleteRoute(type)}}>
            Удалить
          </button>
        </div>
      </div>
    </div>
  );
};
