import React, {useState} from 'react'
import { List } from "./List"

import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchCities, deleteCities, postCity} from 'redux/slices/cities';
import nextArrowSvg from "../../../../assets/svg/icons/next-arrow.svg";


interface IFormInput {
    title: string;
    isHasHotel: boolean;
}

export const Cities = () => {
    const dispatch = useAppDispatch()
    const {cities} = useAppSelector((state)=> state)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchCities())
    }, [])

    const { reset, register, handleSubmit, formState: { errors } } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        dispatch(
            postCity({
                ...data,
            })
        )
        setIsLoading(!isLoading)
        reset()
    }

    const removeCities = (id: number) =>{
        dispatch(deleteCities(id))
        reset()
    }
    return (
        <>
            <div className="airlines">
                <div className="directory__title">Города</div>
                <form className='airlines_form' onSubmit={handleSubmit(onSubmit)}>
                    <div className="form_price">
                        <div className={`form--item ${errors?.title && "error"}`}>
                            <label>Наименование</label>
                            <input type="text" {...register("title", { required: true })} />
                            {errors?.title && (
                                <div className="error_text">заполните поле </div>
                            )}
                        </div>
                        <div className='form--checkbox'>
                            <input style={{ width: "32px" }} type="checkbox"  {...register("isHasHotel", {})} />
                            Имеет ли гостиницу
                        </div>

                        <div className='form--item submit' style={{marginLeft:'30px'}}>
                            <button type="submit" className='btn'>
                                Добавить
                                <img src={nextArrowSvg} alt="" />
                            </button>
                        </div>
                    </div>
                </form>

                <table style={{ marginTop: "40px",maxWidth: '688px',width: "100%" }}>
                    <thead>
                        <tr>
                            <td>№</td>
                            <td style={{width: "80px"}}></td>
                            <td>Город</td>
                            <td>Имеет ли гостиницу</td>
                            <td>Город вылета</td>
                        </tr>
                    </thead>
                    <tbody>
                        {cities.items.map((item : any, index:number)=>(
                            <List 
                                index = {index}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                item={item}
                                removeCities={removeCities}
                                key={item.id}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

