import React, { useEffect, useState } from 'react'
import { CRUD, EMode, EType } from '../CRUD'
import { useParams, useNavigate } from 'react-router-dom'
import { API_URL } from 'services'

export const TemplateEdit = () => {
  const { id } = useParams()
  const [template, setTemplate] = useState<any>(null)

  const navigate = useNavigate()

  useEffect(() => {
    
    // dispatch(NCToursCRUD.fetch())
    fetch(`${API_URL}/tours/templates/${id}/`)
      .then(res => res.json())
      .then(data => {
        setTemplate(data)
      }
      )
  }, [])

  if (!id) {
    navigate(`/admin/control-panel/nct`);
  }

  return (
    template && <CRUD state={template} mode={EMode.EDIT} type={EType.TEMPLATE} />
  )
}
