import priceTour from 'assets/site-svgs/tour-price.svg'
import { Link, useNavigate, } from 'react-router-dom'
import '../../../Flights/flights.css'
import ModalBook from '../ModalBook/ModalBook'
import { useState, useEffect } from 'react'
import { FlightTime } from '../Flight-Times/FlightTIme'
import { useAppDispatch } from 'redux/hooks'
import defaultImg from 'assets/imgs/user_default.svg'
import cityosh from 'assets/site-svgs/cityosh.svg'
import date_of from 'assets/site-svgs/date.svg'
import port from 'assets/site-svgs/airway.svg'
import standart from 'assets/imgs/standarter.svg'
import lux from 'assets/imgs/lux.svg'
import premium from 'assets/imgs/premium.svg'
import mecca from 'assets/imgs/mecca.svg'
import bajus from 'assets/site-svgs/bajys.svg'
import morearrow from 'assets/site-svgs/morearrow.svg'
import fire from 'assets/fire.svg'
import moment from 'moment'
import { getRemainingSeats } from 'utils/functions'
import { useTranslation } from 'react-i18next'


interface Props {
    result: any,
    loading: any,
    title: any
}



export const FlightThings = ({ result, loading, title }: Props) => {
    const {t} = useTranslation()
    const [modalBook, setModalBook] = useState(false)
    const { flight, city, user, date, category, amount_of_days, desired_hotels, public_price, amount_of_people, firm_registered, hajhead_duty, is_archived, is_published, hajhead_registered} = result;
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/profile/${user.id}`)
    }



    const handleBook = (e: any) => {
        e.preventDefault()
        setModalBook(true)
    }

    return (
        <>
            {
                is_published === false ?
                    null
                    :
                    is_archived ?
                        null :
                        <div className={title ? 'flights__box hot' : 'flights__box'}>
                            <div className="hot__title">
                                {title}
                                {
                                    title ?
                                        <img src={fire} alt="" />
                                        : null
                                }
                            </div>
                            <div className={title ? "flights-box__inside active" : "flights-box__inside"}>
                                <div className="left_the-icon__ajy">
                                    {

                                        user.avatar ?
                                            <img src={user.avatar} alt="" className='fliht-ajy' width='102' height='102' />
                                            :
                                            <img src={defaultImg} alt="" className='fliht-ajy' width='102' height='102' />


                                    }
                                    <div className="flights-tours_hide">
                                        <div onClick={handleClick}>
                                            <p className='city'>{amount_of_days} {t("home.text13")}</p>
                                            <b>{user.first_name} {user.last_name}</b>
                                        </div>
                                        <Link to={`book/${result.id} `}>
                                            <div className="flights-more">{t("home.text10")}  <img src={morearrow} alt="" /></div>
                                        </Link>
                                    </div>

                                </div>
                                <div className="right_flight-all">
                                    <div className="top-display__flexed">
                                        <div className="flights-tours" onClick={handleClick}> <p className='city'>{amount_of_days} {t("home.text13")}</p> <b>{user.first_name} {user.last_name}</b> </div>
                                        <Link to={`/book/${result.id} `}>
                                            <div className="flights-more">{t("home.text10")}  <img src={morearrow} alt="" /></div>
                                        </Link>
                                    </div>
                                    <div className="right__side">
                                        <div className="indise__right-side">
                                            <div className="flight-inspection">
                                                <div className="date-media">
                                                    <div className="flights-date">
                                                        <img src={date_of} alt="" />
                                                        <div className="date">
                                                            {moment(flight?.date_from).format('L')} - {moment(flight?.date_to).format('L')}
                                                        </div>
                                                    </div>
                                                    <div className="flight-city">
                                                        <img src={cityosh} alt="" />
                                                        <div className="city">{city.title}</div>
                                                    </div>
                                                    <div className="flight-plane">
                                                        <img src={port} alt="" />
                                                        <div className="city">
                                                            {flight.airline_detail.title}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="hotel-media">
                                                    <div className="city">
                                                        {t('book.word6')}:
                                                    </div>
                                                    {
                                                        desired_hotels.map((item: any, index: any) => {
                                                            return (
                                                                <div className='two-hotelmap' key={item.id}>
                                                                    <div className='icon-in__hotel'>
                                                                        {
                                                                            item.hotel.icon ?
                                                                                <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><img src={item.hotel.icon} alt="" /></a>
                                                                                :
                                                                                <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><img src={mecca} alt="" /></a>
                                                                        }
                                                                    </div>
                                                                    <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><span className='city'>{item.hotel.title}</span></a>
                                                                </div>
                                                            )

                                                        })

                                                    }
                                                </div>

                                            </div>
                                            <div className="about-flight__time">

                                                <FlightTime flight={flight} />
                                                <div className="flight-times__right">
                                                    <div className="standart-flight">
                                                        <div className="category__icon-svg">
                                                            {
                                                                category?.id === 1 ?
                                                                    <img src={standart} alt="" />
                                                                    :
                                                                    <img src={standart} alt="" />
                                                                        &&
                                                                        category?.id === 2 ?
                                                                        <img src={lux} alt="" />
                                                                        :
                                                                        <img src={standart} alt="" />
                                                                            &&
                                                                            category?.id === 3 ?
                                                                            <img src={premium} alt="" />
                                                                            :
                                                                            <img src={standart} alt="" />

                                                            }
                                                        </div>
                                                        <div className="city">{category ? category.title : 'Стандарт'}</div>
                                                    </div>
                                                    <div className="flight-price">
                                                        <img src={priceTour} alt="" />
                                                        <div className="price" >{public_price}</div>
                                                    </div>
                                                    <div className="other-people">

                                                        <div className="people_icon">
                                                            <img src={bajus} alt="" />
                                                            <div className="city">  {t("home.text11")}: </div>
                                                            <b>
                                                                {getRemainingSeats((amount_of_people - hajhead_registered) - firm_registered)}</b>
                                                        </div>
                                                        <ModalBook tour={result} modalBook={modalBook} setModalBook={setModalBook} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="flight__footer">
                                                <button className="book" onClick={(e) => handleBook(e)}>{t("home.text12")}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
            }
        </>
    )
}


