import { useState, useEffect } from 'react';

import { Excursion } from "./Excursion"
import { AdditionalServices } from "./AdditionalServices"
import { ModalDescribe } from "./ModalDescribe";

export const Excursions = () => {
    const [activeDescribe, setActiveDescribe] = useState(false)
    return (
        <div>
            <div className="excursion">
                <Excursion/>
                <AdditionalServices />
            </div>

            <ModalDescribe activeDescribe={activeDescribe} setActiveDescribe={setActiveDescribe} />
        </div>
    );
};