import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { AirlinesState } from './types'

const URL = 'airlines'

const initialState: AirlinesState = {
  loading: false,
  error: false,
  items: [],
}

export const airlinesSlice = createSlice({
  name: 'airlines',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload.map((item: any) => {
        return {
          ...item,
          value: String(item.id),
          label: String(item.title)
        }
      });
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        ...payload,
        value: String(payload.id),
        label: String(payload.title)
      });
    },
    editItem: (state, {payload }) => {
      state.items = state.items.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            ...payload
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.items = state.items.filter(item => item.id !== payload)
    },
    
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, deleteItem, editItem } = airlinesSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const airlinesSelector = (state: { items: any }) => state.items;

// export the default reducer
export default airlinesSlice.reducer;


// fetch all airlines
export function fetchAirline() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?use_pagination=false`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}


// post airlines
export function postAirline(data = {}) {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function editAirline(id: number,data = {}) {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.patch(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}


export function deleteAirline(id: number) {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.delete(`/${URL}/${id}/`)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new hotel
export function saveAirline(data={}, setRoom: any) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
        setRoom({
          ...response.data,
          value: String(response.data.id),
          label: String(response.data.title)
        })
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}