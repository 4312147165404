import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import add__control from "../../../assets/svg/icons/add__package.svg";
import { Template } from "./Template";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { TemplatesCRUD } from 'redux/slices/templates';

export const Templates = () => {
  const dispatch = useAppDispatch();
  const { templates, profile } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(TemplatesCRUD.fetch());
  }, []);



  return (
    <div className="AddTour">
      <div className="package">
        <div className="package__title">Готовые пакеты</div>
        {profile.data.role !== 3 && profile.data.role !== 4 ? <Link className="package-add" to={'/admin/templates/add'}>
          <div className="package-add__block">
            <div className="package-add__block__text">Добавить тур </div>
            <img src={add__control} alt="" />
          </div>
        </Link> : null}
      </div>

      <div className="added__items">
        {templates.results.map((template) => (
          <Template key={template.id} template={template} />
        ))}
      </div>
    </div>
  );
};
