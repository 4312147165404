import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { apiWithoutToken, api } from '../../../services'

import { FlightsState } from './types'

const URL = 'flights'

const initialState: FlightsState = {
  loading: false,
  error: false,
  items: [],
}

export const flightsSliceId = createSlice({
  name: 'flightsId',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.items.push(payload);
    },
    editItem: (state, {payload }) => {
      state.items = state.items.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            payload
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.items = state.items.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = flightsSliceId.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const flightsSelector = (state: { items: any }) => state.items;

// export the default reducer
export default flightsSliceId.reducer;


// fetch all flights
export function fetchFlightsId(id:number) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/${id}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Item
export function saveFlight(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Item
export function editFlight(id: number, data={}) {
  return async (dispatch: Dispatch) => {
    api.put(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** add new Item  */
export function deleteFlight(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`/${URL}/${id}/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}