import React, { SetStateAction } from "react";

import profileNameIcon from "assets/svg/icons/profileNameIcon.svg";
import profileCountryIcon from "assets/svg/icons/profileCountryIcon.svg";
import profileNumberIcon from "assets/svg/icons/profileNumberIcon.svg";
import profileWhatsappNumberIcon from "assets/svg/icons/profileWhatsappNumberIcon.svg";
import facebook_icon from "assets/svg/facebook_icon.svg"
import logout from "assets/svg/icons/logout_white_bg.svg";
import profileIcon from "assets/svg/icons/profileIcon.svg";

import { useAppSelector, useAppDispatch } from "redux/hooks";
import { logoutProfile } from "redux/slices/profile";
import { Aji } from "./Aji";
import { fetchUsers } from "redux/slices/users";
import { getRoleNameByID } from "utils/functions"
import insta_icon from 'assets/svg/instagram.svg';
import tiktok_icon from 'assets/svg/tiktok.svg';

interface Props {
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  gallerList: any[];
  profile: any;
  role: number;
  filterDescriptions: any[];
}


export const ProfileShow = ({
  setIsEdit,
  gallerList,
  role,
  filterDescriptions,
}: Props) => {
  const dispatch = useAppDispatch();

  const { profile } = useAppSelector((state) => state);

  const { data } = profile

  const { first_name, last_name, phone, experience,instagram,tiktok, city, whatsapp,facebook, avatar, id} = data;

  const logoutHandler = () => {
    dispatch(logoutProfile());
    // dispatch(fetchUsers());
  };

  return (
    <>
      {role === 1 || role === 2 || role === 5 || role === 6 ? (
        <div className="profileShow">
          <div className="how__this-is__users">
            Вы вошли как <b>{getRoleNameByID(role)}</b>
          </div>
          <div className="profile__title">Профиль</div>
          <div className="profile-show">
            <img
              className="profile_avatar"
              src={avatar ? avatar : profileIcon}
              alt=""
            />

            <div className="profile-show__blocks">
              <div className="profile-show__block">
                <div className="profile__inform">
                  <img src={profileNameIcon} alt="" />
                  <div>
                    {first_name} {last_name}
                  </div>
                </div>

                {phone ? (
                <div className="profile__inform">
                  <img src={profileCountryIcon} alt="" />
                  <div>{city}</div>
                </div>
                ):null}
              </div>

             
              <div className="profile-show__block">
              {phone ? (
                <div className="profile__inform">
                  <img src={profileNumberIcon} alt="" />
                  <a className="black_link" target="_blank" href={`tel: ${phone}`}>{phone}</a>
                </div>
              ):null}
                

              {whatsapp ? (
                <div className="profile__inform">
                  <img src={profileWhatsappNumberIcon} alt="" />
                  <a className="black_link" target="_blank" href={`https://wa.me/${whatsapp}`}>{whatsapp}</a>
                </div>
              ):null}
                
              {facebook ? (
                <div className="profile__inform">
                  <img src={facebook_icon} alt="" />
                  <a style={{textDecoration: 'underline',maxWidth:'170px',overflow:'hidden', textOverflow : 'ellipsis'}} className="black_link" target="_blank" href={facebook}>{facebook}</a>
                </div>
              ):null}

              {instagram ? (
                <div className="profile__inform">
                  <img style={{width:'24px'}} src={insta_icon} alt="" />
                  <a style={{textDecoration: 'underline',maxWidth:'170px',overflow:'hidden', textOverflow : 'ellipsis'}} className="black_link" target="_blank" href={instagram}>{instagram}</a>
                </div>
              ):null}
              
              {tiktok ? (
                <div className="profile__inform">
                  <img style={{width:'24px'}} src={tiktok_icon} alt="" />
                  <a style={{textDecoration: 'underline',maxWidth:'170px',overflow:'hidden', textOverflow : 'ellipsis'}} className="black_link" target="_blank" href={tiktok}>{tiktok}</a>
                </div>
              ):null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {role === 4 || role === 3 ? (
        <div>
          <Aji
            filterDescriptions={filterDescriptions}
            profile={profile.data}
            gallerList={gallerList}
            role={role}
          />
        </div>
      ) : null}

      <div className="profile__button">
        <button className="exit" onClick={() => logoutHandler()}>
          <img src={logout} alt="" /> Выйти из аккаунта
        </button>
        <button onClick={() => setIsEdit(true)}>Редактировать</button>
      </div>
    </>
  );
};
