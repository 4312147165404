import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import "./style.css";
import Feed from '../Instagram/Feed'
import instaButtons from 'assets/svg/insta_buttons.svg'
import { apiWithoutToken , api} from 'services';

export const InstaFeeds = ({ token, ...props }) => {
    const [feeds, setFeedsData] = useState([]);
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;
    

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            try {
                api.get('/platform_configs/instagram/get_access_token_of_main_user/')
                .then((res) =>{
                    if(res.status === 200){
                        try{
                            axios
                            .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,username,thumbnail_url,caption&limit=${props.limit}&access_token=${res?.data?.instagram_long_token || ''}`)
                            .then((resp) => {
                                setFeedsData(resp.data.data)
                            })
                        }catch(err){
                            console.log(err);
                        } 
                    }
                })             
            } catch (err) {
                console.log('error', err)
            }
        }

        // manually call the fecth function 
        fetchInstagramPost();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort();
        };
    }, [props.limit])

    return (
        <div className="instafeed">
            {feeds.map((feed) => (<Feed key={feed.id} feed={feed} />))}
            <img className='insta-buttons' src={instaButtons} alt="" />
            <div className="liked-by">
                <div className="persons__liked">
                    Нравится <b>prolab.kg и еще 19</b>
                </div>
            </div>
        </div>
    );
}