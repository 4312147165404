import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { api, apiWithoutToken } from "services";

import { IPaginationState } from 'redux/types/RootState'
import { toast } from "react-toastify";

const URL = "reset-password-by-uuid";

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
};

export const resetSlice = createSlice({
  name: "reset",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.data.results.push(payload);
    },
    deleteItem: (state, { payload }) => {
      state.data.results = state.data.results.filter((item) => item.id !== payload);
    },
  },
});

// export the actions
export const { setLoading, setItems, setError, addItem, deleteItem } =
  resetSlice.actions;

// export the default reducer
export default resetSlice.reducer;

/**
 * Метод для получения данных из API
 * */
function fetch() {
  setLoading();
  return async (dispatch: Dispatch) => {
    api
      .get(`accounts/${URL}/`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для получения по id
 * */
function reset(id: number) {
  return async (dispatch: Dispatch) => {
    api
      .get(`accounts/${URL}/${id}/`)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для получения по id
 * */
function resetPassword(data = {},setError2: any, error2: object | null) {
  return async (dispatch: Dispatch) => {
    apiWithoutToken
      .post(`accounts/${URL}/reset/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        setError2({...error2, detail: "Введённый пароль состоит только из цифр."})
      });
  };
}

/**
 * Метод для добавления тура
 * */
function confirm(data = {}, setError: any, error: object | null) {
  return async (dispatch: Dispatch) => {
    apiWithoutToken
      .post(`accounts/${URL}/get/`, data)
      .then((response) => {
        toast.success("Запрос был отправлен")
        dispatch(deleteItem(response.data));
        window.location.href = '/reset-password-confirm'
        setError({})
      })
      .catch((er) => {
          setError({...error, detail: "Пользователя не существует или неверные данные"})
      });
  };
}

export const ResetCRUD = {
  fetch,
  reset,
  confirm,
  resetPassword,
};
