import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CreatableSelect, { useCreatable } from "react-select/creatable";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import bin from "assets/svg/icons/bin.svg";
import change_white_svg from "assets/svg/icons/change_white_svg.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";
import nextArrowSvgRed from "assets/svg/icons/next-arrow-blue.svg";
import checked_popup from "assets/svg/icons/checked_popup.svg";
import { fetchFlights } from "redux/slices/flights";
import { saveAirline } from "redux/slices/airlines";
import { saveCity } from "redux/slices/cities";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Route } from "./Route";
import { Link } from "react-router-dom";
import { getNormalFromToDate } from 'utils/functions'
import { ModalApplication } from "pages/admin/Home/pages/ModalApplication";
import { ToursOfFlight } from "./ToursOfFlight"

interface Props {
  item: any;
  remove: any;
  edit: any;
  click?: any;
  setDateRange: any;
  startDate: any;
  endDate: any;
  routes: any;
  setRoutes: any;
  index: number
}

export const List = ({
  item,
  remove,
  edit,
  click,
  index,
  setDateRange,
  startDate,
  endDate,
  routes,
  setRoutes,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false)

  const { airlines, tours_all } = useAppSelector((state) => state);
  const air = airlines.items.find((air: any) => air.id === item.airline);
  const [active, setActive] = useState(false)

  const route_to = item.routes
    .filter((route: any) => route.type === 1)
    .sort((a: any, b: any) => b.id - a.id)[0];
  const route_from = item.routes
    .filter((route: any) => route.type === 2)
    .sort((a: any, b: any) => a.id - b.id)[0];

  const hamdleClick = () => {
    if (click) {
      click(item);
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  // const onSubmit = (data: any) => {
  //   edit(item.id, data);
  //   setEditable(!editable);
  //   dispatch(fetchFlights());
  // };
  // const customStyles = {
  //   control: (provided: any) => ({
  //     ...provided,
  //     marginLeft: 30,
  //     background: "rgb(220, 236, 252)",
  //     width: 200,
  //     border: "none",
  //     borderBottom: "1px solid #474665",
  //     borderRadius: 0,
  //     boxShadow: "none",
  //   }),
  // };
  // const handleRoutesChange = (newValue: any) => {
  //   setValue("routes", newValue);
  // };
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  // const [yet, setYet] = useState(false);
  // const [modalActive, setModalActive] = useState(false);

  // const handleCityChange = (newValue: any) => {
  //   setValue("city", newValue);
  // };

  // const handleAirlineChange = (newValue: any) => {
  //   setValue("airline", newValue);
  // };

  const routesFrom2 = item?.routes.filter((route: any) => route.type === 1)
  const routesTo2 = item?.routes.filter((route: any) => route.type === 2)

  useEffect(() => {
    setValue("city", {
      id: item.city,
      title: item.city_title,
      value: item.city,
      label: item.city_title,
    });
    setValue("airline", {
      id: air?.id,
      title: air?.title,
      value: air?.value,
      label: air?.label,
    });
  }, []);

  document.addEventListener("keyup", (e) => {
    if (e.key == "Escape") {
      setEditable(false);
    }
  });
  return (
    <>
      <tr
        className={item.places === 100 ? "setactive" : ""}
        onMouseOut={() => setAppear(false)}
        onMouseOver={() => setAppear(true)}
        onClick={() => hamdleClick()}
      >
        <td>

          <label className="info_describe-text">№:</label>
          0{index + 1}</td>
        <td className="actions">
          <div className={appear ? "actions__block" : "actions__none"}>
            <div>
              <Link style={{ border: 'none' }} to={`/admin/directory/flights/${item.id}/`}>
                <img
                  className="edit_icon"
                  src={change_white_svg}
                  alt=""
                />
              </Link>
            </div>
            <img
              className="edit_icon"
              src={bin}
              alt=""
              onClick={() => remove(item.id)}
            />
          </div>
        </td>
        <td><label className="info_describe-text">Авиакомпании:</label>{air?.title}</td>
        <td><label className="info_describe-text">Город вылета:</label>{item.city?.title}</td>
        <td className="route" onClick={() => setActive(!active)}>
          <label className="info_describe-text">Маршрут:</label>
          {route_to?.city_title} - {route_from?.city_title}
        </td>
        <td>
          <label className="info_describe-text">Дата:</label>
          {getNormalFromToDate(item?.date_from, item?.date_to)}
        </td>
        <td><label className="info_describe-text">Цена ($)	:</label>{item.price}$</td>
        <td>
          <div className="main_prossent">

            <div className="twice" onClick={() => setIsOpen(!isOpen)}>
              <div className="prossent">
                {item.reserved_places ? (
                  <div
                    className="left"
                    style={{
                      width: `${item.reserved_places * (100 / item.places)}%`,
                    }}
                  >
                    {item.reserved_places}
                  </div>
                ) : null}
                {item.places - item.reserved_places ? (
                  <div
                    className="yet"
                    style={{
                      width: `${(item.places - item.reserved_places) *
                        (100 / item.places)
                        }%`,
                    }}
                  >
                    {item.places - item.reserved_places}
                  </div>
                ) : null}
              </div>

              <div className="answer"> {item.places}</div>
            </div>
          </div>
        </td>
      </tr>

      {/* <Route setActive={setModalActive} active={modalActive} /> */}

      {isOpen && <ToursOfFlight isOpen={isOpen} setIsOpen={setIsOpen} flightId={item.id}/>}
        <ModalApplication routesTo2={routesTo2} routesFrom2={routesFrom2} active2={active} setActive2={setActive}/>
    </>
  );
};
