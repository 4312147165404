import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api,apiWithoutToken } from 'services'

import { IPaginationState } from 'redux/types/RootState'

const URL = 'tours'

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
}

export const ToursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setItemToResult:  (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      console.log("payload",payload);
      state.data.results.push(payload);
    },
    editItem: (state, {payload }) => {
      state.data.results = state.data.results.map(item => {
        if(item.id === payload.id){
          return {
            ...item,
            ...payload,
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem ,setItemToResult} = ToursSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const notConfirmedToursSelector = (state: { items: any }) => state.items;

// export the default reducer
export default ToursSlice.reducer;


/** 
 * Метод для получения данных из API
 * */
function fetch(query = {
  flight_date_min: '',
  flight_date_max: '',
  category: '',
  price__gt: '',
  price__lt: '',
  user: ''
}) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/?flight_date_min=${query.flight_date_min}&flight_date_max=${query.flight_date_max}&category=${query.category}&user=${query.user}&price__lt=${query.price__lt}&price__gt=${query.price__gt}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

function fetchAllTours() {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/?use_pagination=false`)
      .then((response) => {
        dispatch(setItemToResult(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}


/** 
 * Метод для получения данных из API
 * */
function fetchPublic(query = {
  flight_date_min: '',
  flight_date_max: '',
  category: '',
  price__gt: '',
  price__lt: '',
  user: '',
  page: 1
}) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/?flight_date_min=${query.flight_date_min}&flight_date_max=${query.flight_date_max}&category=${query.category}&user=${query.user}&price__lt=${query.price__lt}&price__gt=${query.price__gt}&page=${query.page}&is_published=true&exclude_taken_places=true`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления тура
 * */
function create(data={}) {
  
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/create/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
        // toast.success('Тур Потвержден!')
        window.location.href = '/admin/control-panel/tours'
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError());
      });
  };
}

/** 
 * Метод для редактирования тура
 * */
function edit( data: any) {
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/${data.id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        window.location.href = '/admin/control-panel/tours'
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}


/** 
 * Метод для частичного редактирования тура
 * */
 function patch(id: number, data={}) {  
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления нового тура 
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`${URL}/${id}/delete/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}
// fetch user
export function fetchItem(id: number, handleData: any, handleError: any) {
  return async () => {
    apiWithoutToken
      .get(`/${URL}/${id}/`)
      .then((response) => {
        handleData(response.data)
      })
      .catch((er) => {
        handleError(er)
      });
  };
}

export const ToursCRUD = {
  fetchAllTours,
  fetch,
  fetchPublic,
  fetchItem,
  create,
  edit,
  patch,
  remove
}

