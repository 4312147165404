import React, { useEffect, useState } from "react";
import Select from "react-select";
import minus from 'assets/svg/icons/minus.svg'
import plus from 'assets/svg/icons/plus.svg'

interface IBlock {
  mode : any
  city: any
  setValue: any
  formState: any
  errors: any
}

export enum EMode {
  CREATE = "create",
  EDIT = "edit",
  READ = "read",
  SUBMIT = "submit",
}

interface SelectedHotel {
  value: number;
  label: string;
  hotel: {
    id: number;
    title: string;
    room_category_title: string;
    places: string;
  };
  amount_of_days: number;
}


export const HotelBlock = ({mode, city, setValue, formState, errors }: IBlock) => {
  let hotel = formState?.desired_hotels.map((item: any) => {
    return {
      value: item.hotel.id,
      label: `${item.hotel.title} - ${item.hotel.room_category_title} - ${item.hotel.places} местный номер`,
      hotel: item.hotel,
      amount_of_days: item.amount_of_days
    }
  }).find((item: any) => item.hotel.city === city.id)

  // useEffect(() => {
  //   console.log('hotel', hotel);
  // }, [hotel])
    

  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState<SelectedHotel | null>(null);
  const onPlus = () => {
    setValue('desired_hotels', [...formState['desired_hotels'].map((item: any) => {
      if(item.hotel.city === city.id){
        return {
          ...item,
          amount_of_days: item.amount_of_days + 1
      }
      }
      return item
    })])
  };

  useEffect(() => {
    // Update the selected hotel when the component mounts or when formState changes
    const newHotel = formState?.desired_hotels.find((item: any) => item.hotel.city === city.id);
    setSelectedHotel(newHotel);
  }, [formState, city]);

  const onMinus = () => {
    if (selectedHotel && selectedHotel['amount_of_days'] > 1) {
      setValue('desired_hotels', [...formState['desired_hotels'].map((item: any) => {
        if (item.hotel.city === city.id) {
          return {
            ...item,
            amount_of_days: item.amount_of_days - 1
          };
        }
        return item;
      })]);
    } else if (selectedHotel && selectedHotel['amount_of_days'] === 1) {
      setValue('desired_hotels', [...formState['desired_hotels'].filter((item: any) => item.hotel.city !== city.id)]);
      setSelectedHotel(null); // Clear the selected hotel
    }
  };

  useEffect(() => {
    setHotels(city.hotels.map((item: any) => {
      return {
        value: item.id,
        label: `${item.title} - ${item.room_category_title} - ${item.places} местный номер`,
        hotel: item
      }
    }))

  }, [city])

  const onSelected = (e: any) => {    
    if(formState['desired_hotels'].length >= formState['amount_of_days']){
      setValue('desired_hotels', formState['desired_hotels'].map((item: any) => {
        if(item.hotel.city === city.id){
          return {
            ...item,
            hotel: e.hotel,
          }
        }
        return item
      }))
    } else {
      setValue('desired_hotels', [...formState['desired_hotels'].filter((item: any) => item.hotel.city !== city.id), {
        hotel: e.hotel,
        amount_of_days: 1
      }])
    }
  }
  return (<div className="hotel__block">
    <div className="hotel__block__titles">
      <div className="hotel__block__titles__title">
        Гостиницы {city.title}
      </div>
      <div className="hotel__block__titles__day-title">
        Количество ночей
      </div>
    </div>

    <div className="hotel__block__items">
      <div className="flex">
        {selectedHotel ? (
            <Select
                isDisabled={mode === EMode.READ}
                options={hotels}
                onChange={(e: any) => onSelected(e)}
                placeholder="Выберите гостиницу"
                value={{
                  value: selectedHotel.hotel.id,
                  label: `${selectedHotel.hotel.title} - ${selectedHotel.hotel.room_category_title} - ${selectedHotel.hotel.places} местный номер`,
                  hotel: selectedHotel.hotel,
                }}
            />
        ) : (
            <Select
                isDisabled={mode === EMode.READ}
                options={hotels}
                onChange={(e: any) => onSelected(e)}
                placeholder="Выберите гостиницу"
            />
        )}
        {hotel?.amount_of_days ? <div className="item__counter item-counter">
          <div className="item__counter__minus"><img src={minus} alt="" onClick={() => mode !== EMode.READ ? onMinus() : null} /></div>
          <div className="item__counter__count--number">  {hotel.amount_of_days} </div>
          <div className="item__counter__plus"><img src={plus} alt="" onClick={() => mode !== EMode.READ ? onPlus() : null} /></div>
        </div> : null}
      </div>
      {errors?.desired_hotels && <div className="error_text">Выберите гостиницу</div>}
    </div>

  </div>)
}