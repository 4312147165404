import React from "react";

    
    import { useState, useEffect } from "react";
    import { useForm, SubmitHandler } from "react-hook-form";
    
    import { useAppSelector, useAppDispatch } from "redux/hooks";
    
    import {
      deleteIncome,
      editIncome,
      fetchIncomes,
      saveIncome,
    } from "redux/slices/income";
    import { List } from "./List";
    
    interface IFormInput {
      price: number;
      title: string;
    }
    
    export const Surcharge = () => {
      const [activeDescribe, setActiveDescribe] = useState(false);
    
      const [get, setGet] = useState(false);
    
      const {
        reset,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<IFormInput>();
    
      // set up dispatch
      const dispatch = useAppDispatch();
    
      // hook to fetch items
      useEffect(() => {
        dispatch(fetchIncomes());
      }, [dispatch, get]);
    
      // fetch data from our store
      const { incomes } = useAppSelector((state) => state);
    
      const onSubmit: SubmitHandler<IFormInput> = (data) => {
        dispatch(
          saveIncome({
            ...data,
            type: 1,
          })
        );
    
        reset();
      };
    
      const handleDeleteHotel = (id: number) => {
        dispatch(deleteIncome(id));
      };
    
      const handleEditHotel = (id: number, data: any) => {
        dispatch(
          editIncome(id, {
            ...data,
            type: 1,
          })
        );
        setGet(!get);
      };
    
      // render the items
      const renderExcursions = () => {
        // loading state
        if (incomes.loading)
          return (
            <tr>
              <td>Загрузка, пожалуйста подождите...</td>
            </tr>
          );
    
        // error state
        if (incomes.error)
          return (
            <tr>
              <td colSpan={10}>Список, недоступен в данный момен...</td>
            </tr>
          );
    
        // regular data workflow
        return incomes.data.results.map((item: any, index: number) => (
          <List
            index={index}
            key={item.id}
            item={item}
            remove={handleDeleteHotel}
            edit={handleEditHotel}
          />
        ));
      };
    
      return (
        <div className="excursion__inner">
          <div className="title">Надбавка</div>
    
          <div className="excursion__data">
          
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={`form--item ${errors?.title && "error"}`}>
                <label>Название надбавки</label>
                <input
                  type="text"
                  {...register("title", { required: true, min: 0 })}
                />
                {errors?.title && <div className="error_text">заполните поле </div>}
              </div>
    
              <div className={`form--item ${errors?.price && "error"}`}>
                <label>Цена ($)</label>
                <input
                  type="number"
                  min={0}
                  {...register("price", { required: true, min: 0 })}
                />
                {errors?.price && <div className="error_text">заполните поле </div>}
              </div>
    
              <div
                className="form--item submit"
                onClick={() => setActiveDescribe(true)}
              >
                <button type="submit">Добавить услугу</button>
              </div>
            </form>
    
            <table>
              <thead>
                <tr>
                  <td>№</td>
                  <td></td>
                  <td>Название надбавки</td>
                  <td>Цена ($)</td>
                </tr>
              </thead>
    
              <tbody>{renderExcursions()}</tbody>
            </table>
    
            
          </div>
        </div>
      );
    };
    