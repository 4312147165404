import { Visa } from "./Visa"
import { Transport } from "./Transport"
import { Surcharge } from "./Surcharge";



export const Visas = () => {

  return (
    <div className="visa">
      <Visa />
      <Surcharge/>
      {/* <Transport /> */}
    </div>
  );
};