import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { api, apiWithoutToken } from "services";

import { IPaginationState } from 'redux/types/RootState'

const URL = "accounts/descriptions";

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
};

export const descriptionsSlice = createSlice({
  name: "descriptions",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.data.results.push(payload);
    },
    deleteItem: (state, { payload }) => {
      state.data.results = state.data.results.filter((item) => item.id !== payload);
    },
    editItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            ...payload
          }
        }
        return item
      })
    },
  },
});

// export the actions
export const { setLoading, setItems, setError, addItem, deleteItem, editItem } = descriptionsSlice.actions;

// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const gallerySelector = (state: { items: any }) => state.items;

// export the default reducer
export default descriptionsSlice.reducer;

/**
 * Метод для получения данных из API
 * */
// function fetch(user_id: number) {
//   setLoading()
//   return async (dispatch: Dispatch) => {
//     api.get(`/${URL}/?user=${user_id}`)
//       .then((response) => {
//         dispatch(setItems(response.data));
//       })
//       .catch((er) => {
//         dispatch(setError());
//       });
//   };
// }

function fetch() {
  setLoading();
  return async (dispatch: Dispatch) => {
    apiWithoutToken
      .get(`/${URL}/`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для добавления тура
 * */
function create(data: any) {
  return async (dispatch: Dispatch) => {
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("my_order", data.my_order);
    formData.append("user", data.user);
    api
      .post(`/${URL}/`, formData)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для редактирования тура
 * */
function edit(id: number, data = {}) {
  console.log("data", data, id);

  return async (dispatch: Dispatch) => {
    api
      .patch(`${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для добавления нового тура
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api
      .delete(`${URL}/${id}/`)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const descriptionsCRUD = {
  fetch,
  create,
  remove,
  edit,
};
