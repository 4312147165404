import React, { SetStateAction } from "react";
import close_x from "assets/svg/icons/x_close.svg";
import nextArrow from 'assets/svg/icons/next-arrow-blue.svg';
import prevArrow from 'assets/svg/icons/prev-arrow-blue.svg';

interface Props {
  active2: boolean;
  setActive2: React.Dispatch<SetStateAction<boolean>>;
  routesTo2: any[]
  routesFrom2: any[]
}

export const ModalApplication = ({ setActive2, active2, routesFrom2, routesTo2 }: Props) => {
  return (
    <div
      className={`modal ${active2 ? "active" : ""}`}
      onClick={() => setActive2(false)}
    >
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <div className="title">Маршрут</div>
          <img onClick={() => setActive2(false)} src={close_x} alt="" />
        </div>
        <div className="app">
          <div className="app__main">
            <div className="app__block">
              <div className="app__title">Туда <img src={nextArrow} alt="" /></div>
              {
                routesFrom2.map((item: any, index: number) => <div key={item.id} className="app__items">
                <div className="app__item">
                  <div className="app__item_title">{index + 1} пункт</div>
                  <div className="app__item_text">{item.city_title}</div>
                </div>
              </div>)
              }
            </div>
            <div className="app__block">
              <div className="app__title">Обратно <img src={prevArrow} alt="" /></div>
              {
                routesTo2.map((item: any, index) => <div key={item.id} className="app__items">
                <div className="app__item">
                  <div className="app__item_title">{index + 1} пункт</div>
                  <div className="app__item_text">{item.city_title}</div>
                </div>
              </div>)
              }
            </div>
          </div>
        </div>
        <div className="app__btn">
           <button onClick={() => setActive2(false)}>Назад</button>
        </div>
      </div>
    </div>
  );
};
