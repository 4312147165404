import React, { useEffect, useState } from "react";
import CreatableSelect, { useCreatable } from "react-select/creatable";
import bin from "../../../../assets/svg/icons/bin.svg";
import change_blue_svg from "../../../../assets/svg/icons/change_blue_svg.svg";
import change_white_svg from "../../../../assets/svg/icons/change_white_svg.svg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import cities, { saveCity } from "redux/slices/cities";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchHotels } from "redux/slices/hotels";
import { saveRoomCategory } from "redux/slices/room_category";
import Select from "react-select";
import { Link } from "react-router-dom";

interface Props {
  item: any;
  remove: any;
  // edit: any;
  index: number
}

export const HotelsInfo = ({ item, remove, index }: Props) => {
  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      marginLeft: 30,
      background: "rgb(220, 236, 252)",
      width: 200,
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  const dispatch = useAppDispatch();

  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  const onSubmit = (data: any) => {
    // edit(item.id, data);
    setEditable(!editable);
    dispatch(fetchHotels());
  };

  const { hotels, room_category, cities } = useAppSelector((state) => state);

  const [items, setItems] = useState([]);

  const handleCityChange = (newValue: any) => {
    setValue("city", newValue);
  };

  const handleRoomChange = (newValue: any) => {
    setValue("room_category", newValue);
  };

  useEffect(() => {
    setValue("city", {
      id: item.city,
      title: item.city_title,
      value: item.city,
      label: item.city_title,
    });
    setValue("room_category", {
      id: item.room_category,
      title: item.room_category_title,
      value: item.room_category,
      label: item.room_category_title,
    });
  }, []);

  document.addEventListener("keyup", (e) => {
    if (e.key == "Escape") {
      setEditable(false);
    }
  });

  return (
    <tr onMouseOver={() => setAppear(true)} onMouseOut={() => setAppear(false)}>
      <td>
        <label className="info_describe-text">№:</label>
        0{index + 1}
      </td>
      <td className="actions">
        
          <div className={appear ? "actions__block" : "actions__none"}>
            <div>
              <Link
                style={{
                  border: "none",
                }}
                to={`/admin/directory/hotel/${item.id}/`}
              >
                <img
                  className="edit_icon"
                  src={change_white_svg}
                  alt=""
                  onClick={() => setEditable(!editable)}
                />
              </Link>
            </div>

            <div>
              <img
                className="edit_icon"
                src={bin}
                alt=""
                onClick={() => remove(item.id)}
              />
            </div>
          </div>
      </td>
      <td>
        <label className="info_describe-text">Название:</label>
        {item.title}
      </td>
      <td>
        <label className="info_describe-text">Город:</label>
        {item.city_title}
      </td>
      <td>
        <label className="info_describe-text">Категория:</label>
        {item.room_category_title}
      </td>
      <td>
        <label className="info_describe-text">Мест в одном номере:</label>
        {item.places} местный номер
      </td>
      <td>
        <label className="info_describe-text">Цена ($):</label>
        {item.price}$
      </td>
    </tr>
  );
};
