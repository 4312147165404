import { Pagination } from 'components/admin/Pagination'
import Footer from 'components/Footer/Footer'
import HeaderMenu from 'components/Header/header-menu/header-menu'
import { FlightThings } from 'components/HeaderFoot/Flights/component/flight-things'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ToursCRUD } from 'redux/slices/tours'


const Hotpresent = () => {
    const dispatch = useAppDispatch()
    const { tours } = useAppSelector((state) => state)

    useEffect(() => {
        dispatch(ToursCRUD.fetchAllTours())
    }, [])

    const [active, setActive] = useState()
    const [items, setItems] = useState([])

    return (
        <div className='hotpresent'>
            <HeaderMenu active={active} setActive={setActive} />
            <div className="mens">
                <div className="container">
                    <div className="title__hot__present">
                        Горячие предложения
                    </div>
                    {
                    tours.data.results?.map((result) => result.isHotOffers ? <FlightThings title='Горячее предложение' key={result.id} result={result} /> : [])
                    }
                    {/* <Pagination items={tours.data.results.filter((item) => item.is_archived === false && item.is_published === true && item.is_urgent === true)} setItems={setItems} len={2} /> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Hotpresent
