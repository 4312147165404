import HeaderFoot from '../HeaderFoot/HeaderFoot'
import './header.css'
import { useState,useEffect } from 'react'
import Modal from '../Modal/Modal'
import Goluboi from 'assets/imgs/logo-zamcgik.svg'
import BlockMenu from './block-menu/block-menu'
import { Link, useNavigate } from 'react-router-dom'
import {useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ToursCRUD } from 'redux/slices/tours'



const Header = () => {
  const {t } = useTranslation() 
  const [active, setActive] = useState(false)
  const { tours } = useAppSelector((state) => state);
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ToursCRUD.fetchAllTours())
  }, [])

  const [fix, setFix] = useState(false)
  function setFixed() {
    if (window.scrollY >= 400) {
      setFix(true)
    } else {
      setFix(false)
    }
  }

  const navigate = useNavigate()
  window.addEventListener('scroll', setFixed)

  const Hotclick = () => {
    navigate('hot-present')
  }


  return (
    <>
      <div className='header-main'>
        <div className="intro">


          <div className="video__background">
            <div className="video__zamchik">

            </div>
            <div className={fix ? 'menu-block-side' : 'menu-block__side'}>
              <BlockMenu active={active} setActive={setActive} />
              <Modal active={active} setActive={setActive} />
            </div>
            <div className="hots__presents-hide">
              <Link to='hot-present'>
                <button className='hot__slices'>
                  {t('home.text3')}
                </button>
              </Link>

            </div>
          </div>
          <div className="goluboi__background">
            <div className="goluboi-zam_zam">
              <div className="inside-goluboi_zam">

                <div className="image-zam_zam">
                  <img src={Goluboi} alt="Logo" />
                </div>

                <div className="will-be__Mecca">
                  {t('home.text1')}
                </div>

                <div className="umra-in__ramadan">
                  {t('home.text2')}
                </div>

                <div className="hots__presents" style={{width : '420px'}}>
                {tours.data.results?.filter((result) => result.isHotOffers===true && result.is_published===true).length > 0 ?
                  <Link to='hot-present'>
                    <button className='hot__slices'>
                      {t('home.text3')}
                    </button>
                  </Link>
                : null}
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="wrapper-footer">
          </div>
        </div>
      </div>


    </>


  )
}

export default Header
