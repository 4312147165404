import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Items } from "pages/admin/ControlPanelPages/Items";
import { NCToursCRUD, fetchNotConfirmedToures } from 'redux/slices/not_confirmed_tours';
import { EMode, EType } from 'pages/admin/Tour/CRUD'


export const RequestItems = () => {
  const dispatch = useAppDispatch();

  const { not_confirmed_tours, profile } = useAppSelector(
    (state: any) => state
  );

  useEffect(() => {
    dispatch(fetchNotConfirmedToures({}));
  }, [dispatch]);

  if (Number(profile?.data?.role) === 4 || Number(profile?.data?.role) === 3) {
    
    return <Items
      items={not_confirmed_tours.results.filter(
        (item: any) => item.user.id === profile.data.id
      )}
    />
  }

  return <Items loading={not_confirmed_tours.loading} items={not_confirmed_tours.results} />
};
