import { Link } from "react-router-dom";
import page404_Img from "assets/img/page404_Img.png";

export const Page404 = () => {
  return (
    <div className="page-404">
      <div className="page-404__content">
        <div className="page-404__background">
          <img src={page404_Img} alt="" />
        </div>
        <div className="page-404__texts">
          <p className="page-404__title">Страница не найдена</p>
          <p className="page-404__text">
            Извините, страница на которую вы хотите зайти не найдено.
          </p>
          <p className="page-404__text">Пожалуйста вернитесь назад</p>
        </div>
      </div>
    </div>
  );
};
