import React, { useEffect, useState } from "react";
import Mecca from "assets/imgs/image 13.png";
import Footer from "components/Footer/Footer";
import HeaderMenu from "components/Header/header-menu/header-menu";

export const ResetPasswordConfirm = () => {
  const [active, setActive] = useState(false);

  return (
    <div className="enter-page">
    <HeaderMenu active={active} setActive={setActive} />
      <div className="log-in">
        <div className="container">
          <div className="log-in__wrapper">
            <div className="wrapper-log__inside">
              <div className="mecca__image-enter">
                <img src={Mecca} alt="" />
              </div>
                <h2>Ссылку на восстановление пароля вам менеджер отправит!</h2>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
