import React from "react";
import { Link } from "react-router-dom";
import content__block_title_icon from "../../../assets/svg/icons/content__block_title_icon.svg";
import profileIcon from "../../../assets/svg/icons/profileIcon.svg";


interface Props {
  item: any;
}

export const Card = ({ item }: Props) => {
  return (
    <div className="content__block">
      <div className="content__block__top">
        {/* <img src={content__block_title_icon} alt="" />
        <div className="content__block__title">
          <span>{item.id}</span> котегория
        </div> */}
      </div>
      <div className="content__center">
        <div className="content__block__icon">
          <img src={item.avatar ? item.avatar : profileIcon} alt="" />
        </div>

        <div className="content__block__user--name">
          {item.first_name} {item.last_name}
        </div>
        <div className="content__block__user--experience">{item.role === 4 ? 'Ажы-Баши' : item.role === 3 ? 'Субагент' : item.role === 2 ? 'Сотрудник' : null}</div>
        <div className="content__block__btn">
          <Link to={`/admin/agents/${item.id}`}>
            <button>Посмотреть профиль</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
