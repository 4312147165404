import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import bin from "assets/svg/icons/bin.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";
import change_white_svg from "assets/svg/icons/change_white_svg.svg";
import { fetchExcursions } from "redux/slices/excursions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { saveCity } from "redux/slices/cities";
import Select from "react-select";

import checked_svg from "assets/svg/icons/checked.svg";
import closed_svg from "assets/svg/icons/close.svg";
import { editExcursion } from "redux/slices/excursions";

interface Props {
  editId: string,
  setEditId: any,
  loading: any,
  item: any;
  remove: any;
  edit: any;
  index: number
}

export const ExcursionList = ({ editId, setEditId, loading, item, remove, edit, index }: Props) => {
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);
  const { cities } = useAppSelector((state) => state);


  useEffect(() => {
    setValue("place", {
      id: item.place,
      title: item.place_title,
      value: item.place,
      label: item.place_title,
    });
  }, []);


  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();



  const onSubmit = (data: any) => {
    setEditId(item.id)
    edit(item.id, {
      ...data,
      place: data.place.id
    })
    setEditable(!editable);
  };


  // ______________________________________________________________

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      marginLeft: 30,
      background: "rgb(220, 236, 252)",
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  return loading && item.id === +editId ? (
    <tr>
      <td colSpan={7}>Загрузка, пожалуйста подождите...</td>
    </tr>
  ) : editable ? (
    <tr className={editable ? "active-bc" : ""}>
      <td>0{index + 1}</td>
      <td className="actions">
        {appear ? (
          <div className="actions__flex">
            <span className="editing_icon">
              ...
              <img src={change_blue_svg} alt="" />
            </span>
            <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
              ok
            </span>
          </div>
        ) : null}
      </td>
      <td>
        <div className={`form--item ${errors?.place && "error"}`}>
          <Controller
            name="place"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={cities.loading}
                isLoading={cities.loading}
                options={cities.items}
                styles={customStyles}
              />
            )}
          />
          {errors?.place && <div className="error_text">Выберите город</div>}
        </div>
      </td>
      <td>
        <div>
          <form>
            <input
              type="number"
              defaultValue={item.price}
              {...register("price")}
            />
          </form>
        </div>
      </td>
      <td>
        <div>
          <form className="checkbox_form">
            <label className="designation" htmlFor="">Активный :</label>
            <input
              type="checkbox"
              defaultChecked={item.isActive}
              {...register("isActive")}
            />
          </form>
        </div>
      </td>
      <td>
        <div>
          <form className="checkbox_form">
            <label className="designation" htmlFor="">Выбранный :</label>
            <input
              type="checkbox"
              defaultChecked={item.isSelected}
              {...register("isSelected")}
            />
          </form>
        </div>
      </td>
      <td>
        <div>
          <form className="checkbox_form">
            <label className="designation" htmlFor="">Обязательный :</label>
            <input
              type="checkbox"
              defaultChecked={item.isSpecified}
              {...register("isSpecified")}
            />
          </form>
        </div>
      </td>
    </tr>
  ) : (
    <tr onMouseOver={() => setAppear(true)} onMouseOut={() => setAppear(false)}>
      <td>0{index + 1}</td>
      <td className="actions">
        {appear ? (
          <div className="actions__flex">
            <div>
              <img
                className="edit_icon"
                src={change_white_svg}
                alt=""
                onClick={() => setEditable(!editable)}
              />
            </div>
            <div>
              <img
                className="edit_icon"
                src={bin}
                alt=""
                onClick={() => remove(item.id)}
              />
            </div>
          </div>
        ) : null}
      </td>
      <td><span className="designation name">Город :</span>{item.place_title}</td>
      <td><span className="designation">Цена :</span>{item.price}$</td>
      <td><span className="designation is_active">Активный :</span> {item.isActive ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
      <td><span className="designation is_choosen">Выбранный :</span> {item.isSelected ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
      <td><span className="designation is_choosen">Обязательный :</span> {item.isSpecified ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
    </tr>
  );
};
