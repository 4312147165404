import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams } from "react-router-dom";

import { editUser, deleteUser } from 'redux/slices/users';
import { UsersAllCRUD } from 'redux/slices/users_all';
import { User } from './User';

export const EntryUsers = () => {
  const { category } = useParams<any>();
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(UsersAllCRUD.fetchAll());
  }, [dispatch]);

  const ConfirmUser = (id: any) => {
    dispatch(
      editUser(id, {
        is_active: true,
      }),
    );
  };

  const RejectNewUser = (id: any) => {
    dispatch(
      deleteUser(id),
    );
  };

  return (
    <>
      {users.data.results.filter((item) => item.role === Number(category) && item.is_active === false).length > 0 ? users.data.results.filter((item) => item.role === Number(category) && item.is_active === false).map((item: any) => (
        <User uuid={category} key={item.id} item={item} ConfirmUser={ConfirmUser} RejectNewUser={RejectNewUser} />
      )) : "No data"}
    </>
  );
};
