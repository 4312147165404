import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ResetCRUD, setError } from "redux/slices/reset";
import { useNavigate, useParams } from "react-router-dom";
import Mecca from "assets/imgs/image 13.png";
import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";
import HeaderMenu from "components/Header/header-menu/header-menu";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/";

export const ResetPassword = () => {
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<any>({});
  // const { id, uuid } = useParams<{
  //   id: string | undefined;
  //   uuid: string | undefined;
  // }>();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    dispatch(
      ResetCRUD.confirm(
        {
          phone: data.phone,
          // first_name: 'User',
          // last_name: 'User',
        },
        setError,
        error
      )
    );
    reset()
  };

  return (
    <div className="enter-page">
      <HeaderMenu active={active} setActive={setActive} />
      <Modal active={active} setActive={setActive} />
      <div className="log-in">
        <div className="container">
          <div className="log-in__wrapper">
            <div className="wrapper-log__inside">
              <div className="mecca__image-enter">
                <img src={Mecca} alt="" />
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="reset__form">
                <div className="inputs">
                  <div>
                    <input
                      defaultValue={"+996"}
                      {...register("phone", { required: true })}
                      type="phone"
                      placeholder="Номер телефона (+996)"
                    />
                    {errors.phone && (
                      <div className="error_text">
                        Пожалуйста заполните это поле!
                      </div>
                    )}
                  </div>
                </div>
                {!!error?.detail && (
                  <div className="error_text">{error.detail}!</div>
                )}
                <button style={{ width: "300px" }} type="submit">Восстановить</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer/>
    </div>
  );
};
