import React, { useState } from "react";
import HeaderBook from "../../components/Header/Header-book/Header-book";
import flightToZam from "assets/imgs/bargyn.png";
import Footer from "components/Footer/Footer";
import "./about-us.css";
import Modal from "components/Modal/Modal";
import HeaderMenu from "components/Header/header-menu/header-menu";
import { useTranslation } from "react-i18next";



const AboutUs = () => {
  const {t} = useTranslation()
  const [active, setActive] = useState();
  return (
    <div className="about-us">
      <HeaderMenu active={active} setActive={setActive} />
      <Modal active={active} setActive={setActive} />
      <div className="filter-blur"></div>
      <div className="max__container">
        <div className="main">
          <div className="container">
            <div className="second__main-wrapper">
              <div className="main-wrapper__left">
                <div className="main-wrapper__right-inside">
                  <div className="right-inside__turist-reality">
                    {t('about.text1')}
                  </div>
                  <div className="right-inside__hotel-place">
                    {t('about.text2')}
                  </div>
                  <div className="right-inside__bars">
                    <div className="right-inside__left-bar">
                      <div className="left-bar__list">
                        {" "}
                        <b>1</b> {t('book.word6')}{" "}
                      </div>
                      <div className="left-bar__list">
                        {" "}
                        <b>2</b> {t('home.text15')} {" "}
                      </div>
                      <div className="left-bar__list">
                        {" "}
                        <b>3</b> Виза{" "}
                      </div>
                    </div>
                    <div className="right-inside__right-bar">
                      <div className="right-bar__list">
                        <b>4</b>{t('home.text16')}
                      </div>
                      <div className="right-bar__list">
                        <b>5</b>{t('home.text17')}
                      </div>
                      <div className="right-bar__list">
                        <b>6</b>{t('home.text18')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="abous__us-image__background">
            <img className="image-about__us" src={flightToZam} alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
