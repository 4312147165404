import React, { useState, useEffect } from "react";
import {
  Outlet
} from "react-router-dom";
import { RestState } from 'redux/types/RootState'
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { Item } from "../../components/admin/directory/Header/Item";
import { NCToursCRUD, fetchNotConfirmedToures } from 'redux/slices/not_confirmed_tours';
import { ToursAllCRUD, fetchAllTours } from 'redux/slices/tours_all';
import loading from "assets/loading_umra.gif"


export const ControlPanel = () => {
  // set up dispatch
  const dispatch = useAppDispatch();
  const { not_confirmed_tours, tours_all, profile } = useAppSelector(state => state);
  const is_agent = profile.data.role === 3 || profile.data.role === 4 ? true : false
  // hook to fetch items
  useEffect(() => {
    if(is_agent){
      dispatch(fetchAllTours({user : profile.data.id}));
      dispatch(fetchNotConfirmedToures({user : profile.data.id}));
    } else {
    dispatch(fetchAllTours({}));
    dispatch(fetchNotConfirmedToures({}));
    }
}, [dispatch, profile]);


const filtered = tours_all.results.filter((item: any) => item.is_published === true && item.flight !== null && item.amount_of_people > item.registered_people)

const hides = tours_all.results.filter((item: any) => item.is_archived === false && item.is_published === false && item.amount_of_people > item.registered_people);

const closed = tours_all.results.filter((item: any) => item.amount_of_people <= item.registered_people && item.is_archived === false);

const archive = tours_all.results.filter((item: any) => item.is_archived === true);

const not_departed = tours_all.results.filter((item: any) => item.is_archived === false);

const agents_nct = not_confirmed_tours.results.filter(item => item.user.id === profile.data.id).length

return (
  <div className="ControlPanel">
    <div className="header">
      <div className="title">Расписание рейсов</div>
      <div className="header__titles">
        <Item to="nct">Заявки ({not_confirmed_tours.loading ? (<img width={13} height={13} src={loading}/>) : (is_agent ? (not_confirmed_tours.error ? 'X' : agents_nct)  : (not_confirmed_tours.error ? 'X' : not_confirmed_tours.results.length) ) })</Item>
        <Item to="tours">Потдвержденные ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : filtered.length)})</Item>
        <Item to="hidden">Скрытые ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : hides.length)})</Item>
        <Item to="closed">Закрытые ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : closed.length)})</Item>
        <Item to="not_departed" >Не Улетевшие ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : not_departed.length)})</Item>
        <Item to="archive" >Архив ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : archive.length)})</Item>
        <Item to="all">Все ({tours_all.loading ? (<img width={13} height={13} src={loading}/>) : (tours_all.error ? 'X' : tours_all.results.length)})</Item>
      </div>
    </div>
    <Outlet />
  </div>
);
};
