import React, { useState, useEffect } from 'react'
import AjyBashyMan from './component/ajybashy-man/ajybashy-man'
import Footer from '../../components/Footer/Footer'
import HeaderBook from '../../components/Header/Header-book/Header-book'
import './ajy-bashy-page.css'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import arrow from 'assets/site-svgs/aroow_top.svg'


export const AjyBashy = () => {
  const dispatch = useAppDispatch()

  const [activeIcon, setActiveIcon] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        setActiveIcon(true)
      } else {
        setActiveIcon(false)
      }
    })
  }, [dispatch])

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }
  
  return (
    <div className='ajy-bashy'>
      <HeaderBook />
      <div className="filter-blur">
      </div>
      <AjyBashyMan />
      <Footer />
      {
        activeIcon &&
        <div className='icon-arow' onClick={scrollUp} >
          <img src={arrow} alt="" />
        </div>
      }
    </div>
  )
}


