import React, { useState, useEffect } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";

const ModalBook = ({ tour, modalBook, setModalBook, }) => {

  const defaultState = {
    name: '',
    phone: '+996',
    message: '',
  }
  const [state, setState] = useState(defaultState)

  function fethDeal(data) {
    return fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.deal.add.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "fields": {
          "TITLE": `Запрос с cайта | ID тура: ${tour.id} / ${tour.user.first_name} ${tour.user.last_name}`,
          "TYPE_ID": "GOODS",
          "STAGE_ID": "PREPARATION",
          "CONTACT_ID": data.result,
          "UF_CRM_1671430670995": `${tour.user.first_name} ${tour.user.last_name}`,
          "UF_CRM_1671430683659": tour.date,
          "UF_CRM_1671430658107": tour?.category?.title,
          "COMMENTS": data.message,
          "CURRENCY_ID": "USD",
          "OPPORTUNITY": tour.public_price,
        }
      }),
    })
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setModalBook(false)
  }

  const handleSend = (e) => {
    e.preventDefault()
    fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.contact.list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "filter": { "PHONE": state.phone },
        "select": ["ID"]
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.result.length === 0) {
          fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.contact.add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "fields": {
                "NAME": state.name,
                "PHONE": [
                  {
                    "VALUE": state.phone,
                    "VALUE_TYPE": "MOBILE"
                  }
                ]
              }
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data)
              if (data.result) {
                fethDeal({ result: data.result, message: state.message })
                  .then((res) => res.json())
                  .then((data) => {
                    console.log(data)
                    if (data.result) {
                      setModalBook(false)
                      setState(defaultState)
                    }
                  })
              }
            })
        } else {
          fethDeal({ result: data.result[0].ID, message: state.message })
            .then((res) => res.json())
            .then((data) => {
              console.log(data)
              if (data.result) {
                setModalBook(false)
                setState(defaultState)
              }
            })
        }
      }
      )
  }

  return (
    <div className={modalBook ? 'modalBook active' : 'modalBook'} onClick={() => setModalBook(false)}>
      <div className={modalBook ? 'modalBook__content active' : 'modalBook__content'} onClick={(e) => e.stopPropagation()}>
        <div className="fill__details">
          Заполните данные
        </div>
        <div className="to-book__call">
          <div className='thingBook'>Имя</div>
          <input type="text" value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} />
        </div>
        <div className="call__number-book">
          <div className='thingBook'> Телефон</div>
          <PhoneInput
            country="KG"
            value={state.phone}
            onChange={(value) => setState({ ...state, phone: value })}
          />
        </div>

        <div className="call__message-book">
          <div className='thingBook'>Сообщение</div>
          <input type="text" value={state.message} onChange={(e) => setState({ ...state, message: e.target.value })} />
        </div>
        <div className="friendly__buttons">
          <button className='backto' onClick={(e) => handleCancel(e)}>Отменить</button>
          <button className='sendtoAdmin'
            type='button'
            onClick={(e) => handleSend(e)}
            disabled={state.name === '' || state.phone === '' ? true : false}
          >Отправить</button>
        </div>
      </div>

    </div>
  )
}

export default ModalBook
