import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { IState } from './types'

const URL = 'tours'

const initialState: IState = {
  loading: false,
  error: false,
  results: [],
}

export const toursAllSlice = createSlice({
  name: 'tours_all',
  initialState,
  extraReducers : builder => {
    builder
    .addCase(fetchAllTours.pending, state => {
      state.loading = true;
    })
    .addCase(fetchAllTours.fulfilled, (state,action) => {
      state.loading = false;
      state.results = action.payload;
    })
    .addCase(fetchAllTours.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.results = [];
    })
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.results.push(payload);
    },
    editItem: (state, {payload }) => {
      state.results = state.results.map(item => {
        if(item.id === payload.id){
          return {
            ...item,
            ...payload,
          }
        }
        return item
      })
      state.loading = false;
    },
    deleteItem: (state, {payload }) => {
      state.results = state.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = toursAllSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const notConfirmedToursSelector = (state: { items: any }) => state.items;

// export the default reducer
export default toursAllSlice.reducer;



export const fetchAllTours = createAsyncThunk('toures_all/fetchAllTours', 
async ({user='', ordering='flight__date_from'}:{user?:string; ordering?:string}) => {
  try{
    let response = await api.get(`/${URL}/?use_pagination=false&user=${user}&ordering=${ordering}`)
    return response.data
  }catch (error:any){
    return error
  }
})  

/** 
 * Метод для получения данных из API
 * */
function fetch(user='', ordering='flight__date_from') {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?use_pagination=false&user=${user}&ordering=${ordering}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для получения по id
 * */
 function get(id: number) {
  return async (dispatch: Dispatch) => {
    api.get(`${URL}/${id}`)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления тура
 * */
function create(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/create/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
        window.location.href = '/admin/control-panel/nct'
        toast.success('Отправлено на потверждение')
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для редактирования тура
 * */
function edit(id: number, data={}) {
  
  return async (dispatch: Dispatch) => {
    api.put(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для частичного редактирования тура
 * */
function patch(id: number, data={}, loading:any = null) {  
  setLoading()
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        if(loading){
          loading((prev:any) => !prev)
        }
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления нового тура 
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`${URL}/${id}/delete/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const ToursAllCRUD = {
  fetch,
  get,
  create,
  edit,
  patch,
  remove
}