import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru"; 
// import { ru } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/ru';

const container = document.getElementById('root')!;
const root = createRoot(container);

// const monthsBG = ['Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'];
// const daysBG = ['нед', 'пон', 'вт', 'ср', 'четв', 'пет', 'съб'];

// registerLocale('ru', {
//   ...ru,
//   localize: {
//     ...ru.localize,
//     month: n => monthsBG[n],
//     day: n => daysBG[n]
//   },
// });

moment.locale('ru')
registerLocale("ru", ru);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);

reportWebVitals();
