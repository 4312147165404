import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";

import {
  setLoading,
  deleteAdditionalService,
  editAdditionalService,
  fetchAdditionalServices,
  saveAdditionalService,
} from "../../../../../redux/slices/additional_services";
import { List } from "./List";

interface IFormInput {
  price: number;
  title: string;
}

export const AdditionalServices = () => {
  const [activeDescribe, setActiveDescribe] = useState(false);
  const [editId, setEditId] = useState("")

  const [get, setGet] = useState(false);

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  // set up dispatch
  const dispatch = useAppDispatch();

  // hook to fetch items
  useEffect(() => {
    dispatch(fetchAdditionalServices());
  }, [dispatch, get]);

  // fetch data from our store
  const { additional_services } = useAppSelector((state) => state);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      saveAdditionalService({
        ...data,
        type: 1,
      })
    );

    reset();
  };

  const handleDeleteHotel = (id: number) => {
    dispatch(deleteAdditionalService(id));
  };

  const handleEditHotel = (id: number, data: any) => {
    dispatch(setLoading())
    dispatch(
      editAdditionalService(id, {
        ...data,
        type: 1,
      })
    );
    dispatch(fetchAdditionalServices())
    setGet(!get);
  };

  // render the items
  const renderExcursions = () => {
    if (additional_services.error)
      return (
        <tr>
          <td colSpan={10}>Список, недоступен в данный момен...</td>
        </tr>
      );

    // regular data workflow
    return additional_services.data.results.map((item: any, index: number) => (
      <List
        editId={editId}
        setEditId={setEditId}
        loading={additional_services.loading}
        index={index}
        key={item.id}
        item={item}
        remove={handleDeleteHotel}
        edit={handleEditHotel}
      />
    ));
  };

  return (
    <div className="excursion__inner">
      

      <div className="title">Доп.услуги</div>
      <div className="excursion__data">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={`form--item ${errors?.title && "error"}`}>
            <label>Название дополнительной услуги</label>
            <input
              type="text"
              {...register("title", { required: true, min: 0 })}
            />
            {errors?.title && <div className="error_text">заполните поле </div>}
          </div>

          <div className={`form--item ${errors?.price && "error"}`}>
            <label>Цена ($)</label>
            <input
              type="number"
              {...register("price", { required: true, min: 0 })}
            />
            {errors?.price && <div className="error_text">заполните поле </div>}
          </div>

          <div
            className="form--item submit"
            onClick={() => setActiveDescribe(true)}
          >
            <button type="submit">Добавить услугу</button>
          </div>
        </form>

        <table>
          <thead>
            <tr>
              <td>№</td>
              <td></td>
              <td>Название дополнительной услуги</td>
              <td>Цена ($)</td>
              <td>Активный</td>
              <td>Выбранный</td>
              <td>Обязательный</td>
            </tr>
          </thead>

          <tbody>{renderExcursions()}</tbody>
        </table>

        
      </div>
    </div>
  );
};
