import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";
import Mecca from "assets/imgs/image 13.png";
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { loginProfile } from "redux/slices/profile";
import Footer from "components/Footer/Footer";
import Modal from 'components/Modal/Modal'
import HeaderMenu from "components/Header/header-menu/header-menu";
import { Closed, Eye } from "assets/svgs";
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input'



interface IFormInput {
  phone: string
  password: string
}


export const Login = () => {
  const { reset, register, handleSubmit,control, setValue, formState: { errors}} = useForm<IFormInput>();
  const [type, setType] = useState('password')
  const [icon, setIcon] = useState(<Closed/>)




  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IFormInput> = data => {    
    console.log(data);
    
    dispatch(loginProfile({
      ...data,

    }
    )
    )
    reset()

  };

  const [active, setActive] = useState(false)

  const ChangeIcon = () => {
    if (type === 'password') {
      setIcon(Eye)
      setType('text')
    } else {
      setIcon(<Closed/>)
      setType('password')
    }

  }



  return (
    <div className="enter-page">
      <HeaderMenu active={active} setActive={setActive} />
      <Modal active={active} setActive={setActive} />
      <div className="log-in">
        <div className="container">
          <div className="log-in__wrapper">
            <div className="wrapper-log__inside">
              <div className="mecca__image-enter">
                <img src={Mecca} alt="" />
              </div>
              <div className="log-in__how">Войти</div>
              <form onSubmit=
                {handleSubmit(onSubmit)}>
                <div className="register__inputs">

                  <div className={`form--item ${errors?.phone && 'error'}`}>
                    <label>Номер телефона</label>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue="+996"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <PhoneInput 
                        country="KG"
                        {...field}
                        value={field.value}
                        onChange={(value: any) => {field.onChange(value)}}
                        />
                      )}
                    />
                    {errors?.phone && <div className="error_text">заполните поле </div>}
                  </div>
                  <div className={`form--item ${errors?.password && 'error'}`}>
                    <label>Пароль</label>
                    <div className="password__eyes">
                      <input type={type} {...register("password", { required: true })} placeholder="Пароль" />
                      <span onClick={ChangeIcon}>{icon}</span>
                    </div>
                    {errors?.password && <div className="error_text">заполните поле </div>}
                  </div>
                </div>

                <div className="sendButton">
                  <NavLink end to="/reset-password">Забыли пароль?</NavLink>
                  <button type="submit">Войти</button>
                  <NavLink end to="/registration">Зарегистрироваться?</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>

  );
}