import React, { useEffect, useState } from 'react'
// import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams, useNavigate } from 'react-router-dom'

import { CRUD, EMode, EType } from '../CRUD'
// import { NCToursCRUD } from 'redux/slices/not_confirmed_tours';
import { API_URL } from 'services';


export const NCTSubmit = () => {
  const { id } = useParams()
  const [tour, setTour] = useState<any>(null)

  const navigate = useNavigate()

  if (!id) {
    navigate(`/admin/control-panel/nct`);
  }

  // const dispatch = useAppDispatch()

  useEffect(() => {
    // dispatch(NCToursCRUD.fetch())
    fetch(`${API_URL}/tours/not_confirmed_tours/${id}/`)
      .then(res => res.json())
      .then(data => {
        setTour(data)
      }
      )
  }, [])
  
  return (tour && <CRUD state={tour} mode={EMode.SUBMIT} type={EType.NOT_CONFIRMED} />)
}
