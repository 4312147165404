import moment from 'moment'

export function getNewID(list: any[]) {
    return list.length > 0 ? list[list.length - 1].id + 1 : 1
}

export function getRemainingSeats(num: number) {
    console.log('num', num);
    
    // if (num >= 50) {
    //     return `50 +`;
    // } else if (num > 40) {
    //     return `40 +`;
    // } else if (num > 30) {
    //     return `30 +`;
    // } else 
    // if (num > 20) {
    //     return `20 +`;
    // } else 
    if (num > 10) {
        return `10 +`;
    }else if(num < 0){
        return `0`
    } else {
        return num;
    }
}

export function getRoleNameByID(id: number) {
    switch (id) {
        case 1:
            return 'Администратор'
        case 2:
            return 'Сотрудник'
        case 3:
            return 'Субагент'
        case 4:
            return 'Ажы-Баши'
        case 5:
            return 'Директор'
        default:
            return 'Ошыбка'
    }
}

export function getNormalFromToDate(from: any, to: any) {
    return `${moment(from).format('L')} - ${moment(to).format('L')}`
};