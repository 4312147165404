import { Modal } from "components/admin";
import React, { useEffect, useState } from "react";
import { ApplicationItems } from "pages/admin/Home/pages/ApplicationItems";
import { apiWithoutToken } from 'services'

export const ToursOfFlight = ({ isOpen, setIsOpen, flightId }: any) => {
    const [tours, setTours] = useState<any>([])
    useEffect(() => {
        apiWithoutToken.get(`/tours/?use_pagination=false&flight=${flightId}`)
            .then((response) => {
                setTours(response.data)
            })
            .catch((er) => {
                console.log(er);
            });
    }, [])

    return (
        <>{isOpen && tours &&
            <Modal active={isOpen && tours} setActive={setIsOpen} title="Туры этого рейса"
                element={
                    <ApplicationItems items={tours} title='' />
                } />}
        </>
    )
}