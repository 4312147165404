import React from 'react'
import { CRUD, EMode, EType } from '../CRUD'


export const NCTCreate = () => {
  return (
    <div>
      <CRUD mode={EMode.CREATE} type={EType.NOT_CONFIRMED} />
    </div>
  )
}
