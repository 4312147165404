import React from 'react'
import './question.css'
import { useTranslation } from 'react-i18next'

const Question = () => {
  const {t} = useTranslation()
  return (
    <div className='question'> 
      <div className="container">
        <div className="question-wrapper">
        <div className="question-wrapper__usually">
         {t('home.text20')}
        </div>
        </div>
      </div>
    </div>
  )
}

export default Question
