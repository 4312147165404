import { Item } from "./Item"


export const Header = () => {

    return (
        <div className='header'>
            <div className="header__titles">
                <Item to="hotel">Гостиницы</Item>
                <Item to="flights">Рейсы</Item>
                <Item to="excursion">Дополнительные...</Item>
                <Item to="visa">Виза...</Item>
                <Item to="airline">Авиакомпании...</Item>
                <Item to="city">Города...</Item>
            </div>
        </div>
    );
};