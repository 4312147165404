import FixLogo from 'assets/imgs/Vector-5.svg'
import WrapperLogo from 'assets/svg/zam zam new logo 1.svg'
import modal from 'assets/site-svgs/modal_close.svg'
import burger from 'assets/site-svgs/zb.svg'
import logout from 'assets/site-svgs/logout.svg'
import { useState } from 'react'
import './header-menu.css'
import { NavLink } from 'react-router-dom'
import { getToken } from 'utils/auth'
import { useTranslation } from 'react-i18next'


const HeaderMenu = ({ active, setActive }) => {
  const {t, i18n} = useTranslation()
  const [fix, setFix] = useState(false)
  function setFixed() {
    if (window.scrollY >= 400) {
      setFix(true)
    } else {
      setFix(false)
    }
  }

  window.addEventListener('scroll', setFixed)


  return (
    <header>
      <div className={ active || fix ? 'header-menu__fixed': 'header-menu' }>
        <div className="container">

        <div className='wrapper-home'>
          <NavLink end to='/' className="wrapper-home__logo">
            <img src={active || fix ? (FixLogo) : (WrapperLogo)} alt="" />
          </NavLink>
          <nav className="wrapper-home__nav-barses">
            <ul>
              <NavLink end to='/' >{t('navbar.word1')} </NavLink>
              <NavLink end to='/about'>{t('navbar.word2')}</NavLink>
              <NavLink end to='/ajy-bashy'> Ажы-Баши </NavLink>
              <NavLink end to='/contact'>{t('navbar.word3')}</NavLink>

            </ul>
          </nav>
          <div className="toggle-button-cover">
            <div className="button-cover">
              <div className="button r" id="button-3">
                <input 
                checked={i18n.resolvedLanguage === 'ru' ? false : true }
                onChange={(e) => e.target.checked ?  i18n.changeLanguage('ky') : i18n.changeLanguage('ru')} 
                type="checkbox" className="checkbox"
                 />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
          <div className="wrapper-home__comees-in">
            <NavLink end to='/admin'>
              {getToken() ? 'Админ панель' : t('navbar.word4')}
              <img src={logout} alt="" />
            </NavLink>

          </div>
          <div className="hidden__menu-white" onClick={() => setActive(!active)}>
          {  active?   <img className='firster' src={modal} alt="" /> :  <img className='seconder' src={burger} alt="" />}
          </div>
        </div>
      </div>
      </div>

    </header>
  )
}

export default HeaderMenu
