import React, { useState, SetStateAction, useEffect, useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import profileIcon from "assets/svg/icons/profileIcon.svg";
import { Item } from "components/admin/Binding/Item";
import loading from "assets/loading1.gif"

import { useAppSelector, useAppDispatch } from "redux/hooks";
import { galleryCRUD } from "redux/slices/gallery";
import { editUser, editUserAvatar, fetchUsers } from "redux/slices/users";
import Pencil from "assets/svg/icons/Pencil.svg";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { Description } from "./Description";

import { GalleryItem } from "./GalleryItem";
import { Params, useParams } from "react-router-dom";
import { Role } from "./Role";
import {Income} from "./Income";
import { fetchIncomes } from "redux/slices/income";
import axios from "axios";
import { API_URL } from "services"


interface IFormInput {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  experience: number;
  city: string;
  instagram: string;
  whatsapp: string;
  facebook:string;
  tiktok:string;
  price: number;
}

interface Props {
  setUser : React.Dispatch<SetStateAction<any>>;
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  gallerList: any[];
  profile: any;
  filterDescriptions: any[];
  setFetch: React.Dispatch<SetStateAction<boolean>>;
  fetch: boolean;
}


function clearFileList(input:any){
  const dt = new DataTransfer()
  input.files = dt.files 
}


export const ProfileEdit = ({
  setUser,
  setIsEdit,
  gallerList,
  profile,
  filterDescriptions,
  setFetch,
  fetch,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false)
  const { id }: Readonly<Params<string>> = useParams();

  const { tours, profile:user} = useAppSelector((state) => state);
  const {
    role,
    first_name,
    last_name,
    phone,
    experience,
    instagram,
    city,
    whatsapp,
    tiktok,
    facebook
  } = profile;


  

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [check, setCheck] = useState(false);



  useEffect(() => {
    axios.get(`${API_URL}/incomes/?use_pagination=false`).then((res) => {
      setIncomes(res.data);
    });
  }, []);

  const [incomes, setIncomes] = useState<any>([]);

  const renderIncome =
  incomes.find(
    (item: any) => item.id === profile.income
  )

  console.log(profile?.id)
  const [inf, setInf] = useState<any>({
    id: renderIncome?.id,
    price: renderIncome?.price,
    title : renderIncome?.title
  });

  useEffect(() => {
    setInf({
      id: renderIncome?.id,
      price: renderIncome?.price,
      title : renderIncome?.title
    });
  }, [renderIncome]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      editUser(profile.id, {
        ...data
      }, setUser)
    );
    setIsEdit(false);
  };

  const addImage = async(e: any) => {
    console.log(e.target.files);
    
    setLoading(true)
    await dispatch(
      galleryCRUD.create({
        image: e.target.files[0],
        user: id
      },setLoading)
    );
    clearFileList(e.target)    
  };

  const deleteImg = (id: any) => {
    dispatch(galleryCRUD.remove(id));
  };

  useEffect(() => {
    setValue("first_name", first_name);
    setValue("last_name", last_name);
    setValue("experience", experience);
    setValue("city", city);
    setValue("whatsapp", whatsapp);
    setValue("phone", phone);
    setValue("instagram", instagram);
    setValue("facebook", facebook);
    fetchIncomes()
  }, []);

  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    setAvatar(profile.avatar)
  }, [profile.avatar]);

  const [avatarFake, setAvatarFake] = useState<any>("")

  const changeAvatar = (img: any) => {
    setAvatarFake(URL.createObjectURL(img.target.files[0]))
    dispatch(
      editUserAvatar(profile.id, {
        avatar: img.target.files[0],
      })
    )

    // dispatch(fetchUsers());
    setFetch(!fetch);
  };
  const sendDescription = () => {
    dispatch(
      descriptionsCRUD.create({
        title: text1,
        description: text2,
        my_order: 0,
        user: profile.id
      })
    );

    setText1("");
    setText2("");
    setCheck(false);
  };

  const addItem = () => {
    text1 && text2.length !== 0 ? sendDescription() : setCheck(true);
    dispatch(descriptionsCRUD.fetch());
  };

  const filterTours = tours.data.results.filter(
    (item: any) => item.user.id === profile.id
  );

  const [active, setActive] = useState(false);

  const [modalActive, setModalActive] = useState(false);
  const [modalIncomes, setModalIncomes] = useState(false)
  return (
    <>
      <div className="main__profile">
        <div className="profileEdits">
          <div className="profile__title">Профиль</div>

          <div className="profile-change">
            <div className="profile-change__img">
              <form className="editAvatar">
                <label htmlFor="avtarimg" className="">
                  <div className="editAvatar-block">
                    <div
                      className="editModal"
                      onMouseOver={() => setActive(true)}
                      onMouseOut={() => setActive(false)}
                    >
                      <div className="editModal_content">
                        <img src={active ? Pencil : ""} alt="" />
                      </div>
                    </div>
                    <img
                      className="editAvatar-Icon"
                      style={{
                        objectFit: 'cover'
                      }}
                      src={
                        avatar
                          ? avatarFake ? avatarFake : avatar
                          : profileIcon
                      }
                      alt=""
                    />
                    <label className="media_avatar" htmlFor="avtarimg">
                      <div>Редактировать</div> <div>фото</div>
                    </label>
                  </div>
                  <input
                    type="file"
                    onChange={(e) => changeAvatar(e)}
                    name="avtarimg"
                    id="avtarimg"
                    required
                    hidden
                  />
                </label>
              </form>

              <div
                className="profileAji__info"
                style={{flexDirection:'column'}}
              >
                <div
                  className="profileAji__add--btn"
                  onClick={() => setModalActive(true)}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  <div className="radio">
                    <div style={{ width: "200px" }} className="radio_item">
                      <div className="radio_number">
                        {/* {inf.id !== null ? inf.id : null} */}
                      </div>
                      <div className="radio_twice">
                        <div className="radio_text">
                          Изменить роль этого пользователя
                        </div>
                        <div className="radio_price">
                          {/* {inf.price !== null ? inf.price : null}$ */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {(role === 3 || role === 4) && user.data.role === 5?
                 (profile.income === null ?  
                  <div
                    onClick={()=> setModalIncomes(true)}
                    className="profileAji__add--btn"
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    
                    <div className="radio">
                      <div style={{ width: "200px" }} className="radio_item">
                        <div className="radio_number">
                          {/* {inf.id !== null ? inf.id : null} */}
                        </div>
                        <div className="radio_twice">
                          <div className="radio_text">
                            Установить категорию наценок
                          </div>
                          <div className="radio_price">
                            {/* {inf.price !== null ? inf.price : null}$ */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:
                  
                   <div
                   className="profileAji__info"
                   onClick={()=> setModalIncomes(true)}
                 >
                   <div style={{border:'none'}}>
                     
                       <div className="radio">
                         <div
                           style={{ width: "200px" }}
                           className="radio_item"
                         >
                           <div className="radio_twice">
                             <div className="radio_text">
                               Категория наценок
                             </div>
                             <div className="radio_price">
                               {inf.title}-{inf.price !== null ? inf.price : null}$
                             </div>
                           </div>
                         </div>
                       </div>
                   </div>
                 </div> )
                  
                  : null}


              </div>
            </div>
            <form className="profile-change__form">
              <div
                style={{ paddingTop: "28px" }}
                className="profile-change__form__small-inputs"
              >
                {role === 1 ||
                  role === 2 ||
                  role === 3 ||
                  role === 4 ||
                  role === 5 ||
                  role === 6 ? (
                  <div
                    className={`form--item ${errors?.first_name && "error"}`}
                  >
                    <label>Имя</label>
                    <input
                      defaultValue={
                        first_name
                      }
                      type="text"
                      {...register("first_name", { required: true })}
                    />
                    {errors?.first_name && (
                      <div className="error_text">заполните поле </div>
                    )}
                  </div>
                ) : null}

                <div className={`form--item ${errors?.last_name && "error"}`}>
                  <label>фамилия</label>
                  <input
                    defaultValue={
                      last_name
                    }
                    type="text"
                    {...register("last_name", { required: true })}
                  />
                  {errors?.last_name && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.city && "error"}`}>
                  <label>Город проживание</label>
                  <input
                    defaultValue={city}
                    type="text"
                    {...register("city")}
                  />
                  {errors?.city && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.phone && "error"}`}>
                  <label>Номер телефона</label>
                  <input
                    defaultValue={phone}
                    type="text"
                    {...register("phone")}
                  />
                  {errors?.phone && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.whatsapp && "error"}`}>
                  <label>Номер ватсапа</label>
                  <input
                    defaultValue={whatsapp}
                    type="text"
                    {...register("whatsapp")}
                  />
                  {errors?.whatsapp && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.facebook && "error"}`}>
                  <label>Cсылка на facebook</label>
                  <input
                    defaultValue={facebook}
                    type="url"
                    {...register("facebook")}
                  />
                  {errors?.facebook && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.instagram && "error"}`}>
                  <label>Cсылка на instagram</label>
                  <input
                    defaultValue={instagram}
                    type="url"
                    {...register("instagram")}
                  />
                  {errors?.instagram && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.instagram && "error"}`}>
                  <label>Cсылка на TikTok</label>
                  <input
                    defaultValue={tiktok}
                    type="url"
                    {...register("tiktok")}
                  />
                  {errors?.tiktok && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                {role === 3 || role === 4 ? (
                  <div
                    className={`form--item ${errors?.experience && "error"}`}
                  >
                    <label>Год опыта</label>
                    <input
                      defaultValue={
                        experience
                      }
                      type="number"
                      {...register("experience")}
                    />
                    {errors?.experience && (
                      <div className="error_text">заполните поле </div>
                    )}
                  </div>
                ) : null}
              </div>

              {role === 4 || role === 3 ? (
                <div className="add_describe_form">
                  <div className="add_describe_form_block">
                    <div>
                      <input
                        className={
                         
                          "add_describe_form_input"
                        }
                        value={text1}
                        placeholder="Заголовок"
                        type="text"
                        onChange={(e) => setText1(e.target.value)}
                      />
                    </div>
                    <div>
                      <textarea
                        className={
                          // check
                            // ? "add_describe_form_error"
                             "add_describe_form_input"
                        }
                        value={text2}
                        placeholder="Описание"
                        style={{ width: "100%" }}
                        onChange={(e) => setText2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{ width: "100px", background: "#125d98" }}
                    className="description__block_delete"
                    onClick={() => addItem()}
                  >
                    Добавить
                  </div>
                </div>
              ) : null}

              <div className="profile-change__form__big-inputs">
                {role === 4 || role === 3 ? (
                  <div>
                    {filterDescriptions.map((item: any) => (
                      <Description
                        key={item.id}
                        setIsEdit={setIsEdit}
                        id={id}
                        item={{ ...item }}
                        user={profile}
                        profile={profile}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
        {role === 4 || role === 3 ? (
          <div className="ProfileAjiEdit">
            <div className="profileAji-info1">
              <div className="profileAji__twice--blok">
                <div className="profileAjiEdit-inputs">
                  <div className=""></div>
                </div>

                <div className="profile-info__right">

                  <div className="profile__raises">
                    <div className="title">Рейсы</div>
                    <table>
                      <thead>
                        <tr>
                          <td>№</td>
                          <td>Дата вылета</td>
                          <td>Дата прилета</td>
                          <td>Маршрут</td>
                          <td>Пакет</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filterTours.length !== 0
                          ? filterTours.map((item: any) => (
                            <Item isPublic={false} key={item.id} item={item} />
                          ))
                          : <div className="profileAji__warning">
                            <div>Вы не создали рейс</div>
                          </div>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="gallery" style={{ marginTop: "50px" }}>
                <div className="title">Галерея </div>

                <div className="gallery__photos">
                  <form>
                    <label htmlFor="image2" className="profileAji__add--btn">
                      Добавить фотографию
                      <input
                        type="file"
                        onChange={(e) => addImage(e)}
                        name="image2"
                        id="image2"
                        required
                        hidden
                      />
                    </label>
                  </form>
                 
                  {gallerList.map((item: any) => (
                    <GalleryItem
                      key={item.id}
                      deleteImg={deleteImg}
                      item={item}
                    />
                  ))}
                  {isLoading ? 
                     <div className={"gallery_modal"}>
                     <img
                     style={{width: '239px', height: '239px', objectFit:'cover', border:'2px dashed #9a9a9a5c'}}
                     src={loading} alt="" />
                   </div>
                   :null
                }
                
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <form className="profile__save">
          <button onClick={() => setIsEdit(false)}>Отменить</button>
          <button onClick={handleSubmit(onSubmit)}>Cохранить</button>
        </form>
      </div>

      <Role
        user={profile}
        setModalActive={setModalActive}
        modalActive={modalActive}
      />
      <Income
        user={profile}
        setModalIncomes={setModalIncomes}
        modalIncomes={modalIncomes}
      />
    </>
  );
};
