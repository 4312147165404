import React, { useState } from "react";
import { useForm } from "react-hook-form";
import bin from "assets/svg/icons/bin.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";
import change_white_svg from "assets/svg/icons/change_white_svg.svg";
import { useAppDispatch } from "redux/hooks";
import { fetchAdditionalServices } from "redux/slices/additional_services";

import checked_svg from "assets/svg/icons/checked.svg";
import closed_svg from "assets/svg/icons/close.svg";
interface Props {
  editId : string,
  setEditId : any,
  loading: any,
  item: any;
  remove: any;
  edit: any;
  index: number
}

export const List = ({editId,setEditId, loading, item, remove, edit, index }: Props) => {
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setEditId(item.id)
    edit(item.id, data);
    setEditable(!editable);
  };

  document.addEventListener("keyup", (e) => {
    if (e.key == 'Escape') {
      setEditable(false)
    }
  });
  return loading && item.id === +editId ?
    <tr>
      <td colSpan={8}>Загрузка, пожалуйста подождите...</td>
    </tr>
    : editable ? (
      <tr className={editable ? "active-bc" : ""}>
        <td>0{index + 1}</td>
        <td >
          <div className="actions">
            {appear ? (
              <div className="actions__flex">
                <span className="editing_icon">
                  ...
                  <img src={change_blue_svg} alt="" />
                </span>
                <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
                  ok
                </span>
              </div>
            ) : null}
          </div>
        </td>
        <td>
          <div>
            <form>
              <input
                type="text"
                defaultValue={item.title}
                {...register("title")}
              />
            </form>
          </div>
        </td>
        <td>
          <div>
            <form>
              <input type="text" defaultValue={item.price} {...register("price")} />
            </form>
          </div>
        </td>
        <td>
          <div>
            <form className="checkbox_form">
              <label className="designation" htmlFor="isActive">Активный :</label>
              <input
                id="isActive"
                type="checkbox"
                defaultChecked={item.isActive}
                {...register("isActive")}
              />
            </form>
          </div>
        </td>
        <td>
          <div>
            <form className="checkbox_form">
              <label className="designation" htmlFor="isChoosen">Выбранный :</label>
              <input
                id="isChoosen"
                type="checkbox"
                defaultChecked={item.isSelected}
                {...register("isSelected")}
              />
            </form>
          </div>
        </td>
        <td>
          <div>
            <form className="checkbox_form">
              <label className="designation" htmlFor="isChoosen">Обязательный :</label>
              <input
                id="isChoosen"
                type="checkbox"
                defaultChecked={item.isSpecified}
                {...register("isSpecified")}
              />
            </form>
          </div>
        </td>
      </tr>
    ) : (
      <tr onMouseOver={() => setAppear(true)} onMouseOut={() => setAppear(false)}>
        <td>0{index + 1}</td>
        <td >
          <div className="actions">
            {appear ? (
              <div className="actions__flex">
                <img
                  className="edit_icon"
                  src={change_white_svg}
                  alt=""
                  onClick={() => setEditable(!editable)}
                />
                <img
                  className="edit_icon"
                  src={bin}
                  alt=""
                  onClick={() => remove(item.id)}
                />
              </div>
            ) : null}
          </div>
        </td>
        <td><span className="designation name">Услуга :</span>{item.title}</td>
        <td><span className="designation">Цена :</span>{item.price}$</td>
        <td><span className="designation">Активный  :</span>{item.isActive ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
        <td><span className="designation">Выбранный  :</span>{item.isSelected ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
        <td><span className="designation">Обязательный  :</span>{item.isSpecified ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
      </tr>
    );
};
