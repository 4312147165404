import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import './scss/app.scss';

import { Home, Page404, ControlPanel, Tour, NCTCreate, TourCreate, TemplateCreate, NCTEdit, NCTSubmit, TemplateEdit, TemplateRead, TourRead, TourEdit, Directory, Packages, Profile, Binding, Users, Agent, InstaAuth } from './pages/admin';
import { Hotels, Cities,Flights, Visas, Excursions, Templates, Airlines, EditFlight } from './components/admin';
import { RequestItems, ComfirmedFlights, Closed, Hidden, Archive, All, NotDeparted } from 'pages/admin/ControlPanelPages';
import { AjyBashy, BookPage, EnterPage, HomePage, AjiBashiProfile, ResetPage } from './pages';

import MainLayout from './layouts/MainLayout';
import AboutUs from './pages/about-us-page';
import ContactToUs from './pages/Contact-to-us';
import Hotpresent from 'pages/Hot-present';

import { RegisterConfirm } from 'components/log-in/RegisterCOnfirm/RegisterConfirm';
import { EditHotel } from 'components/admin/directory/Hotels/EditHotel';
import { ResetPassword } from 'pages/ResetPassword';
import { ResetPasswordConfirm } from 'pages/ResetPasswordConfirm';
import { Agents } from 'pages/admin/Binding/Agents';
import { EntryUsers } from 'pages/admin/Users/EntryUsers';
import { Recover } from 'pages/admin/Users/Recover';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';



function App() {
  return (
    <I18nextProvider i18n={i18n}>
    <Routes>
      <Route path="/admin" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="directory" element={<Directory />} >
          <Route index element={<Navigate to="hotel" replace />} />
          <Route path="hotel" element={<Outlet />} >
            <Route index element={<Hotels />} />
            <Route path=":id" element={<EditHotel />} />
            <Route path="*" element={<Page404 />} />
          </Route>
          <Route path="flights" element={<Outlet />} >
            <Route index element={<Flights />} />
            <Route path=":id" element={<EditFlight />} />
            <Route path="*" element={<Page404 />} />
          </Route>
          <Route path="excursion" element={<Excursions />} />
          <Route path="visa" element={<Visas />} />
          <Route path="airline" element={<Airlines />} />
          <Route path="city" element={<Cities />} />
        </Route>
        <Route path="tour" element={<Tour />} >
          <Route index element={<Navigate to="create" replace />} />
          <Route path="create" element={<TourCreate />} />
          <Route path=":id" element={<TourRead />} />
          <Route path=":id/edit" element={<TourEdit />} />
        </Route>
        <Route path="nct" element={<Tour />} >
          <Route index element={<Navigate to="create" replace />} />
          <Route path="create" element={<NCTCreate />} />
          <Route path=":id" element={<NCTSubmit />} />
          <Route path=":id/edit" element={<NCTEdit />} />
        </Route>
        <Route path="templates" element={<Packages />} >
          <Route index element={<Templates />} />
          <Route path="add" element={<TemplateCreate />} />
          <Route path=":id" element={<TemplateRead />} />
          <Route path=":id/edit" element={<TemplateEdit />} />
        </Route>
        <Route path="control-panel" element={<ControlPanel />}>
          <Route index element={<Navigate to="nct" replace />} />
          <Route path="nct" element={<RequestItems />} />
          <Route path="tours" element={<ComfirmedFlights />} />
          <Route path="hidden" element={<Hidden />} />
          <Route path="closed" element={<Closed />} />
          <Route path="archive" element={<Archive />} />
          <Route path="not_departed" element={<NotDeparted />} />
          <Route path="all" element={<All />} />
        </Route>
        <Route path="users" element={<Users />} >
          <Route index element={<Navigate to="4" replace />} />
          <Route path=":category" element={<EntryUsers />} />
          <Route path="recover" element={<Recover />} />
        </Route>
        <Route path="*" element={<Page404 />} />
        <Route path="agents" element={<Binding />} >
          <Route index element={<Navigate to="role/4" replace />} />
          <Route path="role/:role" element={<Agents />} />
          <Route path=":id" element={<Agent />} />
        </Route>
      </Route>
      <Route path='/insta-auth/' element={<InstaAuth />} />
      <Route path='/about' element={<AboutUs />} />
      <Route path='/ajy-bashy' element={<AjyBashy />} />
      <Route path='/contact' element={<ContactToUs />} />
      <Route path='/registration' element={<EnterPage />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/reset-password-confirm' element={<ResetPasswordConfirm />} />
      <Route path='/treatment' element={<RegisterConfirm />} />
      <Route path='/book/:id' element={<BookPage />} />
      <Route path='/profile/:id' element={<AjiBashiProfile />} />
      <Route path='/reset/:id/:uuid' element={<ResetPage />} />
      <Route path='/hot-present' element={<Hotpresent />} />
      <Route path='/' element={<HomePage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
    </I18nextProvider>
  );
}

export default App;
