import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { IState } from './types'

const URL = 'tours/not_confirmed_tours'

const initialState: IState = {
  loading: false,
  error: false,
  results: [],
}

export const notConfirmedToursSlice = createSlice({
  name: 'not_confirmed_tours',
  initialState,
  extraReducers : builder => {
      builder
        .addCase(fetchNotConfirmedToures.pending, state => {
          state.loading = true;
        })
        .addCase(fetchNotConfirmedToures.fulfilled, (state,action) => {
          state.loading = false;
          state.results = action.payload;
        })
        .addCase(fetchNotConfirmedToures.rejected, (state) => {
          state.loading = false;
          state.error = true;
          state.results = [];
        })
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.results.push(payload);
    },
    editItem: (state, {payload }) => {
      state.results = state.results.map(item => {
        if(item.id === payload.id){
          return {
            ...item,
            ...payload,
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.results = state.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = notConfirmedToursSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const notConfirmedToursSelector = (state: { items: any }) => state.items;

// export the default reducer
export default notConfirmedToursSlice.reducer;


/** 
 * Метод для получения данных из API
 * */
export const fetchNotConfirmedToures = createAsyncThunk('not_confirmed_tours/fetchNotConfirmedToures',
async ({user='', ordering='data'}: {user?: string; ordering?: string;} = {}) => {
  try{
  const response = await api.get(`/${URL}/?use_pagination=false&user=${user}&ordering=${ordering}`)
    return response.data
  }catch(error:any){
    return error
  }
})


function fetch(user='',ordering='date') {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?use_pagination=false&user=${user}&ordering=${ordering}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для получения по id
 * */
 function get(id: number) {
  return async (dispatch: Dispatch) => {
    api.get(`${URL}/${id}`)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления тура
 * */
function create(data={},is_template=false) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/create/`, data)
      .then((response) => {
        dispatch(addItem(response.data));        
        window.location.href = '/admin/control-panel/nct'
        toast.success('Отправлено на потверждение')
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для редактирования тура
 * */
function edit(id: number, data={}) {
  console.log('data', data, id);
  
  return async (dispatch: Dispatch) => {
    api.put(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для частичного редактирования тура
 * */
 function patch(id: number, data={}) {
  console.log('data', data, id);
  
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        window.location.href = '/admin/control-panel/nct/'
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления нового тура 
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`${URL}/${id}/delete/`,)
      .then((response) => {
        dispatch(deleteItem(id));
        if(window.location.href !== 'admin/control-panel/nct/'){
          window.location.href = '/admin/control-panel/nct/'
        }
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const NCToursCRUD = {
  fetch,
  get,
  create,
  edit,
  patch,
  remove
}