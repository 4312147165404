import {
  Outlet
} from "react-router-dom";

export const Packages = () => {
  return (
    <div className="">
      <Outlet />
    </div>
  );
};