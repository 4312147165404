import { useState, useEffect, useRef } from "react";
import { List } from "./List";
import { Pagination } from "../../Pagination";
import { useAppDispatch } from "redux/hooks";
import { editFlight, deleteFlight, fetchFlights } from "redux/slices/flights";

interface IProps {
  click?: any;
  data: any;
  setGet: any;
  list: any[];
  get: any;
}

export const Info = ({ click, data, setGet, get, list }: IProps) => {
  // set up dispatch
  const sortedList = [...list].sort((a,b) => (+(new Date(b.date_from))) - (+(new Date(a.date_from)))  )
  const dispatch = useAppDispatch();
  
  const handleDelete = (id: number) => {
    dispatch(deleteFlight(id));
  };

  const handlePaginate = (page: number) => {
    dispatch(fetchFlights(page));
  };
  
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [routes, setRoutes] = useState([]);
  
  const dataStart = new Date(`${startDate}`)
  const dataEnd = new Date(`${endDate}`)
  

  const handleEdit = (id: number, data: any) => {
    dispatch(
      editFlight(id, {
        airline: data.airline.id,
        city: data.city.id,
        date_from: dataStart.toISOString().slice(0, 10),
        date_to: dataEnd.toISOString().slice(0, 10),
        price: data.price,
        places: data.places,
        routes: routes,
      })
    );


    setGet(!get);
  };
  

  

  return (
    <div className="flights__info">
      <table className="table flights__table">
        <thead>
          <tr>
            <td>№</td>
            <td></td>
            <td>Авиакомпании</td>
            <td>Город вылета</td>
            <td>Маршрут</td>
            <td>Дата</td>
            <td>Цена ($)</td>
            <td>Количество места</td>
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item: any, index: number) => (
            <List
              index={index}
              routes={routes}
              setRoutes={setRoutes}
              setDateRange={setDateRange}
              startDate={startDate}
              endDate={endDate}
              key={item.id}
              item={item}
              remove={handleDelete}
              edit={handleEdit}
              click={click}
            />
          ))}
        </tbody>
      </table>
      <Pagination data={data} handle={handlePaginate} />
    </div>
  );
};
