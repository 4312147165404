import { api } from 'services'
const URL = 'calculation'

// fetch calculation
export function calculate(type: string, data: any,  handleData: any, handleError: any) {
  return async () => {
    api
      .post(`/${URL}/${type}/`, {
        "airline": data.airline,
        "flight": data.flight,
        "user": data.user ? data.user : null,
        "city": data.city,
        "category": data.category,
        "amount_of_people": data.amount_of_people,
        "amount_of_days": data.amount_of_days,
        "excursions": data.excursions,
        "desired_hotels": data.desired_hotels,
        "additional_services": data.additional_services
    })
      .then((res) => {
        handleData(res.data)
      })
      .catch((er) => {
        handleError(er)
      });
  };
}