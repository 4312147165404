import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { CitiesState } from './types'
import { toast } from 'react-toastify';

const initialState: CitiesState = {
  loading: false,
  error: false,
  items: [],
}

export const hotelsSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload.map((item: any) => {
        return {
          ...item,
          value: String(item.id),
          label: String(item.title)
        }
      });
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        ...payload,
        value: String(payload.id),
        label: String(payload.title)
      });
    },
    deleteItem: (state, {payload }) => {
      state.items = state.items.filter(item => item.id !== payload)
    },
    editItem : (state, {payload}) => {
      const posts = [...state.items]
      const findIndex = posts.findIndex(city => city.id === payload.id)
      posts[findIndex] = payload;
      return {
        ...state,
        posts,
      }
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, deleteItem, editItem } = hotelsSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const hotelsSelector = (state: { items: any }) => state.items;

// export the default reducer
export default hotelsSlice.reducer;


// fetch all cities
export function fetchCities() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get("/cities/?use_pagination=false")
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new cities
export function saveCity(data={}, setCity: any) {
  return async (dispatch: Dispatch) => {
    api.post("/cities/", data)
      .then((response) => {
        dispatch(addItem(response.data));
        setCity({
          ...response.data,
          value: String(response.data.id),
          label: String(response.data.title)
        })
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function deleteCities(id: number) {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.delete(`/cities/${id}/`)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        if(er.response && er.response.status === 423){
        toast.error("Невозможно удалить город, так как было создано (гостиница,рейсы...)")   
        }
        dispatch(setError());
      });
  };
}

export function postCity(data = {}) {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.post(`/cities/`, data)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function editCities(id: number,data = {}) {
  // setLoading()
  return async (dispatch: Dispatch) => {
    api.patch(`/cities/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
} 