import { configureStore } from '@reduxjs/toolkit';

import counterReducer from './slices/hotels'
import roomCategoryReducer from './slices/room_category'
import citiesReducer from './slices/cities'
import airlinesSlice from './slices/airlines'
import flightsSlice from './slices/flights'
import excursionsSlice from './slices/excursions'
import additionalServicesSlice from './slices/additional_services'
import IncomeSlice from './slices/income';
import visaSlice from './slices/visa'
import usersSlice from './slices/users'
import usersAllSlice from './slices/users_all'
import profileSlice from './slices/profile'
import transportsSlice from './slices/transports'
import availableAirlinesCitiesSlice from './slices/available_airlines_cities'
import hotelsByCitySlice from './slices/hotels_by_city'
import notConfirmedToursSlice from './slices/not_confirmed_tours'
import TemplatesSlice from './slices/templates'
import gallerySlice from './slices/gallery'
import descriptionsSlice from './slices/descriptions';
import ToursSlice from './slices/tours';
import toursAllSlice from './slices/tours_all';
import CategorySlice from './slices/tour_category';
import resetSlice from './slices/reset';
import questionsAskedSlice from './slices/questions';
import airlines_pricesSlice from './slices/airlines__prices';
import flightsSliceId from './slices/flightsId';


export const store = configureStore({
  reducer: {
    hotels: counterReducer,
    room_category: roomCategoryReducer,
    cities: citiesReducer,
    airlines: airlinesSlice,
    airlines_prices: airlines_pricesSlice,
    flights: flightsSlice,
    flightsId: flightsSliceId,
    excursions: excursionsSlice,
    additional_services: additionalServicesSlice,
    visa: visaSlice,
    users: usersSlice,
    users_all: usersAllSlice,
    profile: profileSlice,
    transports: transportsSlice,
    available_airlines_cities: availableAirlinesCitiesSlice,
    hotels_by_city: hotelsByCitySlice,
    not_confirmed_tours: notConfirmedToursSlice,
    templates: TemplatesSlice,
    gallery: gallerySlice,
    descriptions: descriptionsSlice,
    tours: ToursSlice,
    tours_all: toursAllSlice,
    tour_categories: CategorySlice,
    incomes: IncomeSlice,
    reset: resetSlice,
    questions: questionsAskedSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch