import { Outlet, Link, useParams } from "react-router-dom";

export const Binding = () => {
  const { role } = useParams()

  return (
    <div className="binding">
      <div className="header">
        <div className="header__titles">
          <Link
            to="role/4"
            className={`title ${role === "4" ? "active" : ""}`}
          >
            Ажы-Башчы
          </Link>
          <Link
            to="role/3"
            className={`title ${role === "3" ? "active" : ""}`}
          >
            Субагенты
          </Link>
          <Link
            to="role/2"
            className={`title ${role === "2" ? "active" : ""}`}
          >
            Сотрудники
          </Link>
          <Link
            to="role/1"
            className={`title ${role === "1" ? "active" : ""}`}
          >
            Администраторы
          </Link>
          <Link
            to="role/5"
            className={`title ${role === "5" ? "active" : ""}`}
          >
            Директоры
          </Link>
        </div>
      </div>

      <Outlet />
    </div>
  );
};
