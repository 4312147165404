import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { IState } from './types'

const URL = 'tours/templates'

const initialState: IState = {
  loading: false,
  error: false,
  results: [],
}

export const TemplatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.results.push(payload);
    },
    editItem: (state, { payload }) => {
      state.results = state.results.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            payload
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.results = state.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = TemplatesSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const TemplatesSelector = (state: { items: any }) => state.items;

// export the default reducer
export default TemplatesSlice.reducer;


/** 
 * Метод для получения данных из API
 * */
function fetch() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?use_pagination=false`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для получения по id
 * */
 function get(id: number) {
  return async (dispatch: Dispatch) => {
    api.get(`${URL}/${id}/`)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления тура
 * */
function create(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/create/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
        window.location.href = `/admin/templates/`
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для редактирования тура
 * */
function edit(data: any) {
  console.log('data', data);
  
  return async (dispatch: Dispatch) => {
    api.put(`${URL}/${data.id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для частичного редактирования тура
 * */
 function patch(id: number, data = {}) {
  console.log('data', data, id);
  
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/${id}/update/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        console.log(response.data);
        window.location.href = '/admin/templates/'
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления нового тура 
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`${URL}/${id}/delete/`,)
      .then((response) => {
        dispatch(deleteItem(id));
        window.location.href = '/admin/templates/'
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const TemplatesCRUD = {
  fetch,
  get,
  create,
  edit,
  patch,
  remove
}