import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchUsers } from "redux/slices/users";
import { Card } from "./Card";

export const Agents = () => {
  const { role } = useParams()
  const dispatch = useAppDispatch()
  const { users, profile } = useAppSelector(state => state);

  useEffect(() => {
    dispatch(fetchUsers())
  }, [role]);

  return (
    <div className="content">
      <div className="content__blocks">
        {role && users.data.results.map((item, i) => (
          (item.role === +role && item.is_active === true) && profile.data.id !== item.id ? (
            <Card key={i} item={item} />
          ) : null
        ))}
      </div>
    </div>
  );
};
