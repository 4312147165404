import { useEffect } from 'react'
import './ajybashy-man.css'
import AjyBashyBox from '../AjyBashyBox/AjyBashyBox'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchUsers } from 'redux/slices/users'
import { useTranslation } from 'react-i18next'


const AjyBashyMan = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const { users } = useAppSelector((state) => state)

  return (
    <div className='Ajy'>
      <div className="container">
        <div className="ajy-wrapper">
          <h1>{t('ajy-head.word2')}</h1>
          <div className="all__profile">
            {
              users.data.results.map(user => (user.role === 3 || user.role === 4) && user.is_active === true ? <AjyBashyBox item={user} key={user.id} /> : null
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AjyBashyMan
