import Uscontact from 'components/ContactUS/Uscontact'
import Footer from 'components/Footer/Footer'
import React from 'react'
import HeaderBook from '../../components/Header/Header-book/Header-book'
import './contact-to-us.css'

const ContactToUs = () => {
  return (
    <div className='contact-to-us'>
        <HeaderBook />
        <div className="filter-blur">
          </div>
        <Uscontact/>

        <Footer/>
    </div>
  )
}

export default ContactToUs
