import React, { useState, SetStateAction, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import profileIcon from "assets/svg/icons/profileIcon.svg";
import { Item } from "components/admin/Binding/Item";

import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  editProfile,
  editProfileAvatar,
  fetchProfile
} from "redux/slices/profile";
import { galleryCRUD } from "redux/slices/gallery";
import { editUser, editUserAvatar, fetchUsers } from "redux/slices/users";
import Pencil from "assets/svg/icons/Pencil.svg";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { Description } from "./Description";

import { GalleryItem } from "./GalleryItem";
import { Params, useParams } from "react-router-dom";
import { Role } from "./Role";
import loading from "assets/loading1.gif"

// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';

// const schema = yup.object().shape({
//   instagram: yup.string().url().required(),
//   facebook: yup.string().url().required(),
//   first_name: yup.string().required(),
//   last_name: yup.string().required(),
//   phone: yup.string().required(),
//   city: yup.string().required(),
//   whatsapp: yup.string().required(),
//   price: yup.number().required(),
//   experience: yup.number().required(),
// });

interface IFormInput {
  first_name: string;
  last_name: string;
  phone: string;
  email?: string;
  experience: number;
  city: string;
  instagram: string;
  whatsapp: string;
  facebook: string;
  tiktok:string;
  price: number;
}

interface Props {
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  gallerList: any[];
  profile: any;
  role: number;
  filterDescriptions: any[];
  setFetch: React.Dispatch<SetStateAction<boolean>>;
  fetch: boolean;
}

function clearFileList(input:any){
  const dt = new DataTransfer()
  input.files = dt.files 
}

export const ProfileEdit = ({
  setIsEdit,
  gallerList,
  profile,
  role,
  filterDescriptions,
  setFetch,
  fetch,
}: Props) => {
  const dispatch = useAppDispatch();

  const { id }: Readonly<Params<string>> = useParams();
  const [isLoading, setLoading] = useState(false)
  const [avatarLoading, setAvatarLoading] = useState(false)

  // const { loading, error, data } = user
  const { tours } = useAppSelector((state) => state);

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [check, setCheck] = useState(false);

  const {
    first_name,
    last_name,
    phone,
    experience,
    instagram,
    city,
    whatsapp,
    facebook,
    tiktok,
    instagram_user_id
  } = profile.data;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {

    const editableUser = () => {
      dispatch(
        editUser(profile.id, {
          ...data,
        })
      );
    };
    const editableProfile = () => {
      dispatch(
        editProfile(
          {
            ...data,
          },
          setIsEdit
        )
      );
    };

    id ? editableUser() : editableProfile();

    dispatch(fetchUsers());
    setIsEdit(false);
  };

  const addImage = async(e: any) => {
    setLoading(true)
    await dispatch(
      galleryCRUD.create({
        image: e.target.files[0],
        user: id == undefined ? profile.data.id : id,
      },setLoading)
    );
    clearFileList(e.target)
  };

  const deleteImg = (id: any) => {
    dispatch(galleryCRUD.remove(id));
  };

  // const [load, setLoad] = useState(loading);

  useEffect(() => {
    setValue("first_name", first_name);
    setValue("last_name", last_name);
    setValue("experience", experience);
    setValue("city", city);
    setValue("whatsapp", whatsapp);
    setValue("phone", phone);
    setValue("instagram", instagram);
  }, []);

  const changeAvatar = (img: any) => {
    setAvatarLoading(prev => !prev)
    dispatch(
      editProfileAvatar(
        {
          avatar: img.target.files[0],
        },
        ""
      )
    );
    dispatch(fetchProfile(setAvatarLoading));
    setFetch(!fetch);
    setActive(false)
  };
  const sendDescription = () => {
    dispatch(
      descriptionsCRUD.create({
        title: text1,
        description: text2,
        my_order: 0,
        user: profile.id,
      })
    );

    setText1("");
    setText2("");
    setCheck(false);
  };

  const addItem = () => {
    text1 && text2.length !== 0 ? sendDescription() : setCheck(true);
    dispatch(descriptionsCRUD.fetch());
  };

  const filterTours = tours.data.results.filter(
    (item: any) => item.user.id === id
  );

  const [active, setActive] = useState(false);

  const [modalActive, setModalActive] = useState(false);
  return (
    <>
      <form className="main__profile" onSubmit={handleSubmit(onSubmit)}>
        <div className="profileEdits">
          <div className="profile__title">Профиль</div>

          <div className="profile-change">
            <div className="profile-change__img">
              <div className="editAvatar">
                <label htmlFor="avtarimg" className="">
                  <div className="editAvatar-block">
                  {
                      !avatarLoading ? (
                    <div
                      className="editModal"
                      onMouseOver={() => setActive(true)}
                      onMouseOut={() => setActive(false)}
                    >
                      <div className="editModal_content">
                        <img src={active ? Pencil : ""} alt="" />
                      </div>
                    </div>
                      ) : null
                  }
                    {
                      avatarLoading ? (
                        <img
                        className="editAvatar-Icon"
                        style={{objectFit:'cover'}}
                        src={loading}
                        alt=""
                      />
                      ) : (
                        <img
                        className="editAvatar-Icon"
                        src={
                          profile.data.avatar
                            ? profile.data.avatar
                            : profileIcon
                        }
                        alt=""
                      />
                      )
                    }

                 
                    <label className="media_avatar" htmlFor="avtarimg">
                      <div>Редактировать</div> <div>фото</div>
                    </label>
                  </div>
                  <input
                    type="file"
                    onChange={(e) => changeAvatar(e)}
                    name="avtarimg"
                    id="avtarimg"
                    hidden
                  />
                </label>
              </div>

              {
                id ? <div>
                  {profile.data.role == 1 || profile.data.role == 2 ? (
                    <div
                      className="profileAji__info"
                      onClick={() => setModalActive(!modalActive)}
                    >
                      <div
                        className="profileAji__add--btn"
                        style={{ width: "fit-content", height: "fit-content" }}
                      >
                        <div className="radio">
                          <div style={{ width: "200px" }} className="radio_item">
                            <div className="radio_number">
                              {/* {inf.id !== null ? inf.id : null} */}
                            </div>
                            <div className="radio_twice">
                              <div className="radio_text">
                                Изменить роль этого пользователя
                              </div>
                              <div className="radio_price">
                                {/* {inf.price !== null ? inf.price : null}$ */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div> : null
              }
            </div>
            <div className="profile-change__form">
              <div
                style={{ paddingTop: "28px" }}
                className="profile-change__form__small-inputs"
              >
                <div
                  className={`form--item ${errors?.first_name && "error"}`}
                >
                  <label>Имя</label>
                  <input
                    defaultValue={
                      first_name
                    }
                    type="text"
                    {...register("first_name", { required: true })}
                  />
                  {errors?.first_name && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.last_name && "error"}`}>
                  <label>фамилия</label>
                  <input
                    defaultValue={
                      last_name
                    }
                    type="text"
                    {...register("last_name", { required: true })}
                  />
                  {errors?.last_name && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.city && "error"}`}>
                  <label>Город проживание</label>
                  <input
                    defaultValue={city}
                    type="text"
                    {...register("city")}
                  />
                  {errors?.city && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.phone && "error"}`}>
                  <label>Номер телефона</label>
                  <input
                    defaultValue={phone}
                    type="text"
                    {...register("phone")}
                  />
                  {errors?.phone && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.whatsapp && "error"}`}>
                  <label>Номер ватсапа</label>
                  <input
                    defaultValue={whatsapp}
                    type="text"
                    {...register("whatsapp")}
                  />
                  {errors?.whatsapp && (
                    <div className="error_text">заполните поле </div>
                  )}
                </div>

                <div className={`form--item ${errors?.instagram && "error"}`}>
                  <label>Ссылка на Instagram</label>
                  <input
                    defaultValue={instagram}
                    type="url"
                    {...register("instagram")}
                  />
                  {errors?.instagram && (
                    <div className="error_text">Здесь должен быть действительный URL</div>
                  )}
                </div>
                <div className={`form--item ${errors?.facebook && "error"}`}>
                  <label>Ссылка на Facebook</label>
                  <input
                    defaultValue={facebook}
                    type="url"
                    {...register("facebook")}
                  />
                  {errors?.facebook && (
                    <div className="error_text">Здесь должен быть действительный URL</div>
                  )}
                </div>
                <div className={`form--item ${errors?.facebook && "error"}`}>
                  <label>Ссылка на TikTok</label>
                  <input
                    defaultValue={tiktok}
                    type="url"
                    {...register("tiktok")}
                  />
                  {errors?.facebook && (
                    <div className="error_text">Здесь должен быть действительный URL</div>
                  )}
                </div>

                {role === 3 || role === 4 ? (
                  <div
                    className={`form--item ${errors?.experience && "error"}`}
                  >
                    <label>Год опыта</label>
                    <input
                      defaultValue={
                        experience
                      }
                      type="number"
                      {...register("experience")}
                    />
                    {errors?.experience && (
                      <div className="error_text">заполните поле </div>
                    )}
                  </div>
                ) : null}
              </div>

              {role === 4 || role === 3 ? (
                <div className="add_describe_form">
                  <div className="add_describe_form_block">
                    <div>
                      <input
                        className={
                          check
                            ? "add_describe_form_error"
                            : "add_describe_form_input"
                        }
                        value={text1}
                        placeholder="Заголовок"
                        type="text"
                        onChange={(e) => setText1(e.target.value)}
                      />
                    </div>
                    <div>
                      <textarea
                        className={
                          check
                            ? "add_describe_form_error"
                            : "add_describe_form_input"
                        }
                        value={text2}
                        placeholder="Описание"
                        style={{ width: "100%" }}
                        onChange={(e) => setText2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{ width: "100px", background: "#125d98" }}
                    className="description__block_delete"
                    onClick={() => addItem()}
                  >
                    Добавить
                  </div>
                </div>
              ) : null}

              <div className="profile-change__form__big-inputs">
                {role === 4 || role === 3 ? (
                  <div>
                    {filterDescriptions.map((item: any) => (
                      <Description
                        key={item.id}
                        setIsEdit={setIsEdit}
                        id={id}
                        item={{ ...item }}
                        profile={profile}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>

          </div>
        </div>
        {role === 4 || role === 3 ? (
          <div className="ProfileAjiEdit">
            <div className="profileAji-info1">
              <div className="profileAji__twice--blok">
                <div className="profileAjiEdit-inputs">
                  <div className=""></div>
                </div>

                <div className="profile-info__right" style={{flexWrap:'wrap',gap :'50px'}}>
                  <div
                    style={{ display: "flex", gap: "20px", alignItems: "end" }}
                    className={`profile-info__instagram ${errors?.instagram && "error"
                      }`}
                  >
                    <div className="form--item">
                      <label>Инстаграм соединение</label>
                      {instagram_user_id ? <p>{instagram_user_id}</p> : <button onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `https://api.instagram.com/oauth/authorize?client_id=931957458189483&redirect_uri=https://zamzam.kg/insta-auth/&scope=user_profile,user_media&response_type=code`;
                      }}>Соединить</button>}
                    </div>
                  </div>

                  <div className="profile__raises">
                    <div className="title">Рейсы</div>
                    <table>
                      <thead>
                        <tr>
                          <td>№</td>
                          <td>Дата вылета</td>
                          <td>Дата прилета</td>
                          <td>Маршрут</td>
                          <td>Пакет</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filterTours.length !== 0
                          ? filterTours.map((item: any) => (
                            <Item isPublic={false} key={item.id} item={item} />
                          ))
                          : <div className="profileAji__warning">
                            <div>Вы не создали рейс.</div>
                          </div>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="gallery" style={{ marginTop: "50px" }}>
                <div className="title">Галерея </div>

                <div className="gallery__photos">
                  <div>
                    <label htmlFor="image2" className="profileAji__add--btn">
                      Добавить фотографию
                      <input
                        type="file"
                        onChange={(e) => addImage(e)}
                        name="image2"
                        id="image2"
                        hidden
                      />
                    </label>
                  </div>
                  {gallerList.map((item: any) => (
                    <GalleryItem
                      key={item.id}
                      deleteImg={deleteImg}
                      item={item}
                    />
                  ))}
                   {isLoading ? 
                     <div className={"gallery_modal"}>
                     <img
                     style={{width: '239px', height: '239px', objectFit:'cover', border:'2px dashed #9a9a9a5c'}}
                     src={loading} alt="" />
                   </div>
                   :null
                  }
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="profile__save">
          <button onClick={() => setIsEdit(false)}>Отменить</button>
          <button type="submit">Cохранить</button>
        </div>
      </form>

      <Role
        profile={profile}
        setModalActive={setModalActive}
        modalActive={modalActive}
      />
    </>
  );
};
