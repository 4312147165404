import React, {useState, useEffect} from "react";
import { useAppSelector } from 'redux/hooks'
import { ApplicationItems } from "pages/admin/Home/pages/ApplicationItems";

export const All = () => {
  const { tours_all } = useAppSelector(state => state);

  return (
      <ApplicationItems loading={tours_all.loading} items={tours_all.results} title=''/>
  )
}