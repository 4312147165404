import React, { SetStateAction } from 'react';
import close_x from 'assets/svg/icons/x_close.svg';

interface Props {
  active: boolean;
  setActive: React.Dispatch<SetStateAction<boolean>>;
  element: JSX.Element;
  title?: string;
}

export const Modal = ({ active, setActive, element, title }: Props) => {
  return (
    <div className={`modal ${active ? 'active' : ''}`} onClick={() => setActive(false)}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <div className="title">{title ? title :  ''}</div>
          <img onClick={() => setActive(false)} src={close_x} alt="" />
        </div>
        {element}
      </div>
    </div>
  );
};
