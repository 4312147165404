import React, { useEffect } from 'react'
import './answer.css'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { questionsAskedCRUD } from 'redux/slices/questions'
import { AnswerItem } from './AnswersItem'


const Answer = () => {



    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(questionsAskedCRUD.fetch())
    }, [])

    const { data } = useAppSelector(state => state.questions)

    return (
        <div className='answer'>
            <div className="container">
                <div className="answer-wrapper">
                    <div className="answer-wrapper__inside">
                        {
                            data.results.map((question: any, index:any) => <AnswerItem key={question.id} question={question} index={index}/>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Answer
