import React, { useState } from "react";
import "./main.css";

import { InstaFeeds } from 'components/InstaFeeds'
import InMecca from "assets/imgs/inMecca.png";
import LeftArab from "assets/imgs/arab-png 1.png";
import commentPost from "assets/imgs/commentt.svg";
import saved from "assets/imgs/saved.svg";
import likeinst from 'assets/site-svgs/likeinst.svg'
import instgot from 'assets/site-svgs/lorem.svg'
import vots from 'assets/site-svgs/vots.svg'
import tg from 'assets/site-svgs/telega.svg'
import { useTranslation } from "react-i18next";

const Main = () => {
  const {t} = useTranslation()
  return (
    <div className="main">
      <div className="container">
        <div className="main-wrapper">
          <div className="main-wrapper__left">
            <div className="main-wrapper__zam-zam">
              <div className="zamzam-inside">
                <InstaFeeds token="IGQVJVRU1TX2libkJWcFhKdkFqTi1GemZA0WGdqOTlxS2RkVzNJQ2R5RF9SZA0RUNnRkWWlKRnlEN0ZAubDY0RkpyY3ItY1ZA0ZAmRKRXVUcHV0US1rWGVGcEkwNmN1S1ZA4M0NGUmJfMzln" limit={1} />
                
              </div>
            </div>
          </div>

          <div className="main-wrapper__arab">
            <img src={LeftArab} alt="" />
          </div>
          <div className="main-wrapper__right">
            <div className="main-wrapper__right-inside">
              <div className="right-inside__turist-reality">
                  {t('home.text14')}
              </div>
              <div className="right-inside__hotel-place">

              </div>
              <div className="right-inside__bars">
                <div className="right-inside__left-bar">
                  <div className="left-bar__list">
                    {" "}
                    <b>1</b> {t('book.word6')}{" "}
                  </div>
                  <div className="left-bar__list">
                    {" "}
                    <b>2</b> {t('home.text15')} {" "}
                  </div>
                  <div className="left-bar__list">
                    {" "}
                    <b>3</b> Виза{" "}
                  </div>
                </div>
                <div className="right-inside__right-bar">
                  <div className="right-bar__list">
                    <b>4</b>{t('home.text16')} 
                  </div>
                  <div className="right-bar__list">
                    <b>5</b>{t('home.text17')} 
                  </div>
                  <div className="right-bar__list">
                    <b>6</b>{t('home.text18')} 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
