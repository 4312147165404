const TO = 1
const FROM = 2
export const ROUTE_TYPES = [
    { value: TO, label: "Туда" },
    { value: FROM, label: "Обратно" }
]

export const STATUS_OPTIONS = [
        { value: 0, label: "Открыта" },
        { value: 1, label: "Закрыта" },
        { value: 2, label: "Пожилание ажи башы" },
        { value: 3, label: "Пустой" },
        { value: 4, label: "Забронирован у компании" },
        { value: 5, label: "Не распределен" },
        { value: 6, label: "Отмена" },
        { value: 7, label: "Сам сабирает" }
]

const ADMIN = 1
const EMPLOYEE = 2
const SUBAGENT = 3
const HAJJHEAD = 4
const HEAD = 5
const USER = 6

export enum Role {
    ADMIN = 1,
    EMPLOYEE = 2,
    SUBAGENT = 3,
    HAJJHEAD = 4,
    HEAD = 5,
    USER = 6
}

export const ROLE_CHOICES = [
    { value: ADMIN, label: "Администратор" },
    { value: EMPLOYEE, label: "Сотрудник " },
    { value: SUBAGENT, label: "Суб. агент" },
    { value: HAJJHEAD, label: "Ажы башы" },
    { value: HEAD, label: "Директор" },
    { value: USER, label: "Пользователь" },
]

export const SCOPES = {
    canCreate: "create",
    canEdit: "edit",
    canDelete: "delete",
    canView: "view"
};

export const PLASES = {
    'not_confirmed': {
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.SUBAGENT]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.HAJJHEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.USER]: [SCOPES.canView],
    },
    'tour': {
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.SUBAGENT]: [SCOPES.canView],
        [Role.HAJJHEAD]: [SCOPES.canView],
        [Role.USER]: [SCOPES.canView],
    },
    'templates': {
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.SUBAGENT]: [SCOPES.canView],
        [Role.HAJJHEAD]: [SCOPES.canView],
        [Role.USER]: [SCOPES.canView],
    },
    'control-panel': {
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.SUBAGENT]: [SCOPES.canView],
        [Role.HAJJHEAD]: [SCOPES.canView],
        [Role.USER]: [SCOPES.canView],
    },
    'users': {
        [Role.USER]: [],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [],
        [Role.SUBAGENT]: [],
        [Role.HAJJHEAD]: [],
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
    },
    'agents': {
        [Role.USER]: [],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [],
        [Role.SUBAGENT]: [],
        [Role.HAJJHEAD]: [],
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
    },
    'directory': {
        [Role.USER]: [],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [],
        [Role.SUBAGENT]: [],
        [Role.HAJJHEAD]: [],
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
    },
    'create_tour': {
        [Role.USER]: [],
        [Role.ADMIN]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.EMPLOYEE]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.SUBAGENT]: [],
        [Role.HAJJHEAD]: [],
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
    },
    'create_nct': {
        [Role.USER]: [],
        [Role.ADMIN]: [],
        [Role.EMPLOYEE]: [],
        [Role.SUBAGENT]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.HAJJHEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
        [Role.HEAD]: [SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canView],
    },
};

export const ROUTES = {
    '/admin': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/not_confirmed': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/tour': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/users': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/agents': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/directory': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/directory/hotel': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/directory/flights': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/directory/excursion': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/directory/visa': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
    '/admin/tour/create': [Role.HEAD, Role.ADMIN, Role.EMPLOYEE, Role.SUBAGENT, Role.HAJJHEAD, Role.USER],
};