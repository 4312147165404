import { Link, useLocation } from "react-router-dom";
import React from 'react'

interface ILink {
    to: string;
    children?: React.ReactNode;
}

export const Item = ({ to, children}: ILink) => {
    const location = useLocation();
    const path = location.pathname.split('/');
    
    return (
        <Link className={`title ${to === path[3] ? 'active' : ''}`} to={to} >{children}</Link>
    )
};