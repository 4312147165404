import {
  Outlet,
} from "react-router-dom";

import { Header } from "../../../components/admin/directory/Header"

export const Directory = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};