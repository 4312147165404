import React, { SetStateAction, useEffect, useState } from "react";
import close_x from "../../../assets/svg/icons/x_close.svg";
import { editUser } from "redux/slices/users";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {fetchIncomes} from "redux/slices/income";

interface Props {
  modalIncomes: boolean;
  setModalIncomes: React.Dispatch<SetStateAction<boolean>>;
  user: any;
}

export const Income = ({
  modalIncomes,
  setModalIncomes,
  user,
}: Props) => {
    const { incomes } = useAppSelector((state) => state)    

    useEffect(() => {
        dispatch(fetchIncomes());
    }, []);

    const dispatch = useAppDispatch();
    const editIncome = (id: number) => {
        dispatch(
        editUser(user.id, {
            income: id,
        })
        );
    };


  

  const navigate = useNavigate();

  return (
    <div
      className={modalIncomes ? "profileAji__modal" : "profileAji__modal--none"}
      onClick={() => setModalIncomes(false)}
    >
      <div
        className="profileAji__modal__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal__header">
          <div className="modal__header_text">Категория наценок</div>
          <img onClick={() => setModalIncomes(false)} src={close_x} alt="" />
        </div>

        <div className="profileAji__categories">
          <div className="profileAji__categories__item">
            <form className="radio">
              <div className="radio_items">
                {
                    incomes.data.results.map((item: any, index: number)=>(
                        <label
                            key={item.id}
                        onClick={() => {
                          editIncome(item.id);
                          setModalIncomes(false);
                          navigate(`/admin/agents/`);
                        }}
                        htmlFor="gender3"
                        className={
                          user.income === item.id
                            ? "radio_item borderBlack"
                            : "radio_item"
                        }
                      >
                        <div className="radio_twice">
                          {user.income === item.id  ? (
                            <div style={{ color: "#125d98" }} className="radio_text">
                              Наценка пользователя
                            </div>
                          ) : null}
                          <div className="radio_text">{item.title} - <span style={{color:"#3498db"}}>{item.price}$</span></div>
                        </div>
                      </label>
                    ))
                }
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
