import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL + '/api/v1';

export const token = localStorage.getItem('token')

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
    "Authorization": `Token ${token ? token : ''}`
  }
});

export const apiWithoutToken = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const apiGalley = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Authorization": `Token ${token ? token : ''}`
  }
});