import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import FixLogo from 'assets/imgs/Vector-5.svg'
import WrapperLogo from 'assets/svg/zam zam new logo 1.svg'
import './block-menu.css'
import { getToken } from 'utils/auth'
import modal from 'assets/site-svgs/fucked.svg'
import burger from 'assets/site-svgs/burger.svg'
import logout from 'assets/site-svgs/logout.svg'
import { useTranslation } from 'react-i18next'


const BlockMenu = ({ active, setActive }) => {
  const {t, i18n} = useTranslation()
  const [fix, setFix] = useState(false)
  function setFixed() {
    if (window.scrollY >= 400) {
      setFix(true)
    } else {
      setFix(false)
    }
  }  

  window.addEventListener('scroll', setFixed)


  return (
    <header>
      <div className={ active || fix ? 'header-menued__fixed': 'header-menued' }>
        <div className="container">

        <div className='wrapper-home'>
          <NavLink end to='/' className="wrapper-home__logo2">
            <img src={fix || active?(FixLogo): (WrapperLogo)}alt="" />
          </NavLink>
         

          <img className='hide_to-pixelfuck' src={fix?(FixLogo): null}alt="" />

        <div className={fix || active ? 'navs-fixed normal' :  "inside__to-block-menu"}>
          <nav className="wrapper-home__nav-barss">
            <ul>
              <NavLink end to='/' >{t('navbar.word1')}</NavLink>
              <NavLink end to='/about'>{t('navbar.word2')}<li></li> </NavLink>
              <NavLink end to='/ajy-bashy'> {t('navbar.word5')} <li></li></NavLink>
              <NavLink end to='/contact'> {t('navbar.word3')}<li></li></NavLink>

            </ul>
          </nav>
          <div style={{width:'75px'}} className="toggle-button-cover">
            <div className="button-cover">
              <div className="button r" id="button-3">
                <input 
                checked={i18n.resolvedLanguage === 'ru' ? false : true }
                onChange={(e) => e.target.checked ?  i18n.changeLanguage('ky') : i18n.changeLanguage('ru')} 
                type="checkbox" className="checkbox"
                 />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
          <div className="wrapper-home__come-ins">
            <NavLink end to='/admin'>
              {getToken() ? 'Админ панель' : t('navbar.word4')}
              <img src={logout} alt="" />
            </NavLink>

          </div>
          <div className="hidden__menued" onClick={() => setActive(!active)}>
          {  active?   <img src={modal} alt="" /> :  <img src={burger} alt="" />}
          </div>
          </div>

        </div>
      </div>
      </div>

    </header>
  )
}

export default BlockMenu
