import React, { useState } from "react";
import { useForm } from "react-hook-form";
import bin from "assets/svg/icons/bin.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";
import change_white_svg from "assets/svg/icons/change_white_svg.svg";
import { useAppDispatch } from "redux/hooks";
import { fetchIncomes } from "redux/slices/income";
interface Props {
  item: any;
  remove: any;
  edit: any;
  index: number
}

export const List = ({ item, remove, edit, index }: Props) => {
  const dispatch = useAppDispatch();
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    edit(item.id, data);
    setEditable(!editable);
    dispatch(fetchIncomes());
  };
  document.addEventListener("keyup", (e) => {
    if(e.key == 'Escape'){
      setEditable(false)
    }
});
  return editable ? (
    <tr className={editable ? "active-bc" : ""}>
      <td>0{index + 1}</td>
      <td className="actions">
        {appear ? (
          <div className="actions__flex">
            <span className="editing_icon">
              ...
              <img src={change_blue_svg} alt="" />
            </span>
            <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
              ok
            </span>
          </div>
        ) : null}
      </td>
      <td>
        <div>
          <form>
            <input
              type="text"
              defaultValue={item.title}
              {...register("title")}
            />
          </form>
        </div>
      </td>
      <td>
        <div>
          <form>
            <input type="text" defaultValue={item.price} {...register("price")} />
          </form>
        </div>
      </td>
    </tr>
  ) : (
    <tr onMouseOver={() => setAppear(true)} onMouseOut={() => setAppear(false)}>
      <td>0{index + 1}</td>
      <td className="actions">
        {appear ? (
          <div className="actions__flex">
           <div>
           <img
              className="edit_icon"
              src={change_white_svg}
              alt=""
              onClick={() => setEditable(!editable)}
            />
           </div>
            <img
              className="edit_icon"
              src={bin}
              alt=""
              onClick={() => remove(item.id)}
            />
          </div>
        ) : null}
      </td>
      <td>{item.title}</td>
      <td>{item.price}$</td>
    </tr>
  );
};
