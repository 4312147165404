import React, { SetStateAction } from "react";

import profileNameIcon from "assets/svg/icons/profileNameIcon.svg";
import profileCountryIcon from "assets/svg/icons/profileCountryIcon.svg";
import profileNumberIcon from "assets/svg/icons/profileNumberIcon.svg";
import profileWhatsappNumberIcon from "assets/svg/icons/profileWhatsappNumberIcon.svg";
import profileIcon from "assets/svg/icons/profileIcon.svg";
import facebook_icon from "assets/svg/facebook_icon.svg"
import instagram from "assets/svg/instagram.svg"
import tiktok from "assets/svg/tiktok.svg"


import { Aji } from "./Aji";
import { useParams } from "react-router-dom";


interface Props {
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  gallerList: any[];
  filterDescriptions: any[];
  user: any;
}


export const ProfileShow = ({
  setIsEdit,
  gallerList,
  user,
  filterDescriptions,
}: Props) => {

  const { id } = useParams()

  return (
    <>
      {id && (user.role === 3 || user.role === 4) ? (
        <div>
          <Aji
            profile={user}
            isAdmin={true}
            isPublic={false}
          />
        </div>
      ) : null}

      {
        id && (user.role === 2 || user.role === 1 || user.role === 5) ? (
          <div className="profileShow">
            <div className="profile__title">Профиль</div>

            <div className="profile-show">
              <img
                className="profile_avatar"
                src={user.avatar ? user.avatar : profileIcon}
                alt=""
              />

              <div className="profile-show__blocks">
                <div className="profile-show__block">
                  <div className="profile__inform">
                    <img src={profileNameIcon} alt="" />
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                  </div>

                {user.city ? 
                 <div className="profile__inform">
                    <img src={profileCountryIcon} alt="" />
                    <div>{user.city}</div>
                  </div>
                :null}
                 
                </div>

              
                <div className="profile-show__block">
                {user.phone ?  
                  <div className="profile__inform">
                    <img src={profileNumberIcon} alt="" />
                    <a className="black_link" target="_blank" href={`tel: ${user.phone}`}>{user.phone}</a>
                  </div>
                   : null}
                {user.whatsapp ?  
                  <div className="profile__inform">
                    <img src={profileWhatsappNumberIcon} alt="" />
                    <a className="black_link" target="_blank" href={`https://wa.me/${user.whatsapp}`}>{user.whatsapp}</a>
                  </div>
                : null}
                {user.facebook ?  
                  <div className="profile__inform">
                    <img src={facebook_icon} alt="" />
                    <a style={{ textDecoration: 'underline', maxWidth: '170px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="black_link" target="_blank" href={user.facebook}>{user.facebook}</a>
                  </div>
                : null}
                {user.instagram ?  
                  <div className="profile__inform">
                    <img style={{width:'24px', height:'24px'}} src={instagram} alt="" />
                    <a style={{ textDecoration: 'underline', maxWidth: '170px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="black_link" target="_blank" href={user.instagram}>{user.instagram}</a>
                  </div>
                : null}
                
                {user.tiktok ?  
                  <div className="profile__inform">
                    <img style={{width:'24px', height:'24px'}} src={tiktok} alt="" />
                    <a style={{ textDecoration: 'underline', maxWidth: '170px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="black_link" target="_blank" href={user.tiktok}>{user.tiktok}</a>
                  </div>
                : null}
                </div>
              </div>
            </div>
          </div>
        ) : null
      }

      <div className="profile__button">
        <div></div>
        <button onClick={() => setIsEdit(true)}>Редактировать</button>
      </div>
    </>
  );
};
