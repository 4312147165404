import React from 'react';
import airplane from '../../../assets/svg/icons/airplane.svg';
import location from '../../../assets/img/location.svg';
import dobavka from '../../../assets/img/dobavka.svg';
import flying__airplane from '../../../assets/svg/icons/flying_airplain.svg';
import list1 from '../../../assets/svg/icons/list1.svg';
import list2 from '../../../assets/svg/icons/list2.svg';
import list3 from '../../../assets/svg/icons/list3.svg';
import list4 from '../../../assets/svg/icons/list4.svg';
import line_days from '../../../assets/svg/icons/line_days.svg';
import { useAppSelector } from "redux/hooks";
import { Link } from 'react-router-dom';
export const Template = ({ template }: any) => {
  const { airline, city, amount_of_people, date, desired_hotels,desired_excursions,desired_services, category, calculation, public_price} = template;
  const { profile } = useAppSelector((state) => state);


  console.log(desired_excursions)

  return (
    <div className="addtour__item">
      <div className="addtour__item-block">
        <div className="addtour__item__title">{template.title}</div>

        <div className="addtour__Item__raises">
          <div className="line__items">

            <div className="line__title">Рейсы:</div>
            <div className="line__items__item">
              <img src={airplane} alt="" />

              <div className="line__items__text">{airline.title}</div>
            </div>
            <div className="line__items__item">
              <img src={flying__airplane} alt="" />
              <div className="line__items__text">{city.title}</div>
            </div>

            <div className="line__items__item">
              <img src={line_days} alt="" />
              <div className="line__items__text">{date}</div>
            </div>

            <div className="line__items__item">
              <div className="line__items__textblue">{amount_of_people} человека</div>
            </div>
          </div>
        </div>

        {desired_hotels?.map((hotel: any, index: number) => {
          return (
            <div key={hotel.id} className="addtour__Item__raises">

              <div className="line__items">
                <div className="line__title">Отели:</div>
                <div className="line__items__item">
                  <img src={list1} alt="" />
                  <div className="line__items__text">{hotel.hotel.city_title}</div>
                </div>
                <div className="line__items__item">
                  <div className="icon_category-room">
                    <img src={hotel.hotel.icon ? hotel.hotel.icon : list2} alt="" />

                  </div>

                  <div className="line__items__text">{hotel.hotel.title}</div>
                </div>

                <div className="line__items__item">
                  <img src={list3} alt="" />
                  <div className="line__items__text">{hotel.hotel.room_category_title}</div>
                </div>

                <div className="line__items__item">
                  <img src={list4} alt="" />
                  <div className="line__items__text">{hotel.hotel.places} местный номер</div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="addtour__Item__raises">
          <div className="line__items">

            <div className="line__title">Экскурсия:</div>
            {desired_excursions ? 
              desired_excursions.map((item:any) => (
                  <div key={item?.id} className="line__items__item">
                     <img src={location} alt="" />
                     <div className="line__items__text">{item?.excursion.place_title}</div>
                  </div>
              ))
            : null}
          
          </div>
        </div>

        <div className="addtour__Item__raises">
          <div className="line__items">

            <div className="line__title">Дополнительные услуги:</div>
            {desired_services ? 
              desired_services.map((item:any) => (
                <div key={item?.id} className="line__items__item">
                  <img src={dobavka} alt="" />
                  <div className="line__items__text">{item?.additional_services.title}</div>
                </div>
              ))
            : null }
          
          </div>
        </div>

        <div className="addtour__Item__raises">
          <div className="line__items__item">

            <div className="line__title">Статус тура:</div>

            {category ? (
              <>
                <div
                  className="icon_category-room"
                  dangerouslySetInnerHTML={{ __html: category.svg_icon }}></div>
                <div className="line__items__text">{category.title}</div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="line-price">
            <div className="line-price__text1">Сумма на одного человека</div>
            <div className="line-price__text2">{public_price} USD</div>
          </div>

          <div className='addtour__Item__raises' style={{gap:'20px'}}>
            <Link to={`/admin/templates/${template.id}`}>
              <button>Посмотреть</button>
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};
