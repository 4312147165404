import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { api, apiGalley, apiWithoutToken } from "services";

import { IPaginationState } from 'redux/types/RootState'

const URL = "accounts/users";

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
}

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.data.results.push(payload);
    },
    editItem: (state, { payload }) => {
      state.data.results = state.data.results.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      });
    },
    deleteItem: (state, { payload }) => {
      state.data.results = state.data.results.filter((item) => item.id !== payload);
    },
  },
});

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } =
  usersSlice.actions;

// // export the selector (".data.results" being same as in slices/index.js's "data.results: something")
// export const usersSelector = (state: { data.results: any }) => state.data.results;

// export the default reducer
export default usersSlice.reducer;

// fetch all users
export function fetchUsers() {
  setLoading();
  return async (dispatch: Dispatch) => {
    apiWithoutToken
      .get(`/${URL}/?use_pagination=false`)
      .then((response) => {        
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// fetch user
export function fetchUser(id: number, handleData: any, handleError: any) {
  return async () => {
    apiWithoutToken
      .get(`/${URL}/${id}/`)
      .then((response) => {
        handleData(response.data)
      })
      .catch((er) => {
        handleError(er)
      });
  };
}

// add new User
export function saveUser(data = {}) {
  return async (dispatch: Dispatch) => {
    api
      .post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// edit User
export function editUser(id: number, data = {},setUser?: any, state : any = null) {
  return async (dispatch: Dispatch) => {
    api
      .patch(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        setUser(response.data);
        if(state != null){
          state(response.data)
        }
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function editUserAvatar(id: number, data = {}) {
  return async (dispatch: Dispatch) => {
    apiGalley
      .patch(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для добавления гостиницы
 * */
export function deleteUser(id: number) {
  return async (dispatch: Dispatch) => {
    api
      .delete(`${URL}/${id}/`)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}
