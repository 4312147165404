import React from "react";
import "./Footer.css";
import FooterIcon from "../../assets/imgs/Vector-4.svg";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="all__footer">
            <div className="top-side__footer">
              <div className="left-side__footer">
                <div className="left__footer">
                  <Link to='/' className="footer__icon" id="1">
                    <img src={FooterIcon} alt="" />
                  </Link>
                  <div className="footer__meriden">
                    <div className="top__text">Курманжан датка 413,</div>
                    <div className="top__text">г. Ош</div>
                    <div className="bottom__footer">
                      <div className="left-bottom__footer">
                        <div className="number">
                          <a href="tel:+996554072525"> +996 (554) 072 525</a>
                        </div>
                        <div className="gmail-contact">
                          <a href="mailto:info@zamzam.kg"> info@zamzam.kg</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right__footer">
                  <div className="footer__nav-bar">
                    <div className="bar__about-us">
                      <ul>
                        <NavLink to='/'><li>{t('navbar.word1')}</li></NavLink>
                        <NavLink to='/about'><li>{t('navbar.word2')}</li></NavLink>
                        <NavLink to='/ajy-bashy'><li>Ажы-Баши</li></NavLink>
                        <NavLink to='/contact'> <li>{t('navbar.word3')}</li></NavLink>
                      </ul>
                    </div>
                    <div className="bar__online-contacts">
                      <ul>
                        <a href="https://www.facebook.com/zamzam.travel.osh">  <li>Facebook</li></a>
                        <a href="https://instagram.com/zamzam_travel.osh"> <li>Instagram</li></a>
                        <a href="https://t.me/+996554072525"><li>Telegram</li></a>
                        <a href="https://wa.me/996554072525">  <li>Whatsapp</li></a>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-bottom__footer">
              <div className="maket-name">
                <a className="prolabhref" href="http://prolab.kg">{t('footer.word2')}</a>
                <div className="zamzamerinhis">
                  © ZamZam -
                </div>
                <div className="prolabhref">
                  {new Date().getFullYear()}
                </div>
                <span>{t('footer.word1')}.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
