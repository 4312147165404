import React, { SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import close_x from "../../../assets/svg/icons/x_close.svg";
import { editUser } from "redux/slices/users";
import { useNavigate } from "react-router-dom";

interface Props {
  modalActive: boolean;
  setModalActive: React.Dispatch<SetStateAction<boolean>>;
  profile: any;
}

export const Role = ({
  modalActive,
  setModalActive,
  profile,
}: Props) => {

  const dispatch = useAppDispatch();
  const editRole = (id: number) => {
    dispatch(
      editUser(profile.id, {
        role: id,
      })
    );
  };

  const navigate = useNavigate();

  return (
    <div
      className={modalActive ? "profileAji__modal" : "profileAji__modal--none"}
      onClick={() => setModalActive(false)}
    >
      <div
        className="profileAji__modal__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal__header">
          <div className="modal__header_text">Роли</div>
          <img onClick={() => setModalActive(false)} src={close_x} alt="" />
        </div>

        <div className="profileAji__categories">
          <div className="profileAji__categories__item">
            <form className="radio">
              <div className="radio_items">
                <label
                  onClick={() => {
                    editRole(4);
                    setModalActive(false);
                    navigate(`/admin/agents/`);
                  }}
                  htmlFor="gender3"
                  className={
                    profile.role === 4
                      ? "radio_item borderBlack"
                      : "radio_item"
                  }
                >
                  <div className="radio_twice">
                    {profile.role === 4 ? (
                      <div style={{ color: "#125d98" }} className="radio_text">
                        Роль пользователя
                      </div>
                    ) : null}
                    <div className="radio_text">Ажы-Башы</div>
                  </div>
                </label>
                <label
                  onClick={() => {
                    editRole(3);
                    setModalActive(false);
                    navigate(`/admin/agents/`);
                  }}
                  htmlFor="gender3"
                  className={
                    profile.role === 3
                      ? "radio_item borderBlack"
                      : "radio_item"
                  }
                >
                  <div className="radio_twice">
                    {profile.role === 3 ? (
                      <div style={{ color: "#125d98" }} className="radio_text">
                        Роль пользователя
                      </div>
                    ) : null}
                    <div className="radio_text">Субагент</div>
                  </div>
                </label>
                <label
                  onClick={() => {
                    editRole(2);
                    setModalActive(false);
                    navigate(`/admin/agents/`);
                  }}
                  htmlFor="gender3"
                  className={
                    profile.role === 2
                      ? "radio_item borderBlack"
                      : "radio_item"
                  }
                >
                  <div className="radio_twice">
                    {profile.role === 2 ? (
                      <div style={{ color: "#125d98" }} className="radio_text">
                        Роль пользователя
                      </div>
                    ) : null}
                    <div className="radio_text">Сотрудник</div>
                  </div>
                </label>
                <label
                  onClick={() => {
                    editRole(1);
                    setModalActive(false);
                    navigate(`/admin/agents/`);
                  }}
                  htmlFor="gender3"
                  className={
                    profile.role === 1
                      ? "radio_item borderBlack"
                      : "radio_item"
                  }
                >
                  <div className="radio_twice">
                    {profile.role === 1 ? (
                      <div style={{ color: "#125d98" }} className="radio_text">
                        Роль пользователя
                      </div>
                    ) : null}
                    <div className="radio_text">Администратор</div>
                  </div>
                </label>
                <label
                  onClick={() => {
                    editRole(5);
                    setModalActive(false);
                    navigate(`/admin/agents/`);
                  }}
                  htmlFor="gender3"
                  className={
                    profile.role === 5
                      ? "radio_item borderBlack"
                      : "radio_item"
                  }
                >
                  <div className="radio_twice">
                    {profile.role === 5 ? (
                      <div style={{ color: "#125d98" }} className="radio_text">
                        Роль пользователя
                      </div>
                    ) : null}
                    <div className="radio_text">Директор</div>
                  </div>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
