import React, { useState } from "react";
import iks from 'assets/site-svgs/iks.svg'
import plus from 'assets/site-svgs/plus.svg'
import {useTranslation } from 'react-i18next';

interface Props {
    question: any;
    index: any
}

export const AnswerItem = ({question, index}: Props) => {
    const {t ,i18n} = useTranslation() 
    console.log(i18n);
    
    const [hiddenText, setHiddenText] = useState<any>(false)    

    const hideText = (index: any) => {
        setHiddenText(index)
    }

    return (
        <div className="answer-inside__first-box">
            <div className="first-box__inside">
                <div className="wrapper-box__xz">
                    <div className="top__box">

                        <div className="trip__and-icon">
                            <div className='dolgo__reshal'>
                                <div className="h1-class__title">
                                    {question.my_order}
                                </div>
                                <div className="trip" onClick={() => hideText(!hiddenText)}>
                                    {i18n.language === 'ru' ? question.title : question.title_kg}
                                </div>
                            </div>
                            <div className={hiddenText  ? 'add__icon active' : 'add__icon'} onClick={() => hideText(!hiddenText)}>
                                {
                                    hiddenText ? <img src={plus} alt="" /> : <img src={iks} alt="" />
                                }
                            </div>
                        </div>

                    </div>
                </div>

                { hiddenText ? 
                    <div className="inside-bottom__box">
                    {i18n.language === 'ru' ? question.description : question.description_kg}
                        
                    </div>
                    :

                    null
                    }
            </div>
        </div>
    )
}