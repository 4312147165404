import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'
import Mecca from "../../assets/imgs/image 13.png";
import admin from 'assets/site-svgs/adminlogin.svg'
import subagent from 'assets/site-svgs/subagent.svg'
import user from 'assets/site-svgs/ajiicon.svg'
import "./log-in.css";
import axios from "axios";
import Modal from "components/Modal/Modal";
import HeaderMenu from "components/Header/header-menu/header-menu";
import { Closed, Eye } from "assets/svgs";
import PhoneInput from 'react-phone-number-input'
import { API_URL } from "services"


const LogIn = () => {
  const [roleState, setRole] = useState()
  const [type, setType] = useState('password')
  const [type1, setType1] = useState('password')
  const [icon, setIcon] = useState(<Closed/>)
  const [icon1, setIcon1] = useState(<Closed/>)

  const {
    register,
    watch,
    getValues,
    formState: { errors, temat, isValid, isDirty },
    handleSubmit,
  } = useForm();

  const register_user = (data) => {
    console.log('data', data);
    axios.post(`${API_URL}/accounts/register/`, data)
      .then(response => {
        sessionStorage.setItem('user', JSON.stringify(response.data))
        navigates('/treatment')
      }).catch(error_res => {
        setError({ ...error_res.response.data })
        console.log(data);
      })
  }

  const defaultState = {
    name: '',
    phone: '+996',
    message: '',
  }
  const [state, setState] = useState(defaultState)

  const onSubmit = data => {
      register_user({...data, phone: state.phone})
  }

  const [error, setError] = useState(false)

  const [activeBtn, setActiveBtn] = useState()
  let navigates = useNavigate()

  const handleClick = (index) => {
    setActiveBtn(index)
  }

  const ChangeIcon = () => {
    if (type === 'password') {
      setIcon(<Eye/>)
      setType('text')
    } else {
      setIcon(<Closed/>)
      setType('password')
    }

  }

  const ChangeIcon1 = () => {
    if (type1 === 'password') {
      setIcon1(<Eye/>)
      setType1('text')
    } else {
      setIcon1(<Closed/>)
      setType1('password')
    }
  }

  const [disabled, setDisables] = useState(true)

  const [active, setActive] = useState(false)

  const handleDisable = (e) => {
    if (e.target.value.length !== 0 && activeBtn === 1 || activeBtn === 2 || activeBtn === 3) {
      setDisables(false)

    }
  }

  return (
    <div className="log-in">
      <HeaderMenu active={active} setActive={setActive} />
      <Modal active={active} setActive={setActive} />
      <div className="container">

        <div className="log-in__wrapper">
          <div className="wrapper-log__inside">
            <div className="mecca__image-enter">
              <img src={Mecca} alt="" />
            </div>
            <div className="log-in__how">Зарегистрироваться как</div>
            <form
              onSubmit={handleSubmit(onSubmit)}
            >

              <div className="log-in__users" id="users">
                <div className={activeBtn === 1 ? 'log-user active' : 'log-user'} onClick={() => { handleClick(1); setRole(4); }}>
                  <div className="user-name__text" >
                    Ажы-Башы
                  </div>
                  <div className="user_icon">
                    <img src={user} alt="" />
                  </div>
                </div>

                <div className={activeBtn === 2 ? 'log-user active' : 'log-user'} onClick={() => { handleClick(2); setRole(3); }}>
                  <div className="user-name__text" >
                    Cубагент
                  </div>
                  <div className="user_icon">
                  <img src={subagent} alt="" />

                  </div>
                </div>
                <div className={activeBtn === 3 ? 'log-user active' : 'log-user'} onClick={() => { handleClick(3); setRole(2); }}>
                  <div className="user-name__text" >
                    Сотрудник
                  </div>
                  <div className="user_icon">
                  <img src={admin} alt="" />

                  </div>
                </div>
              </div>
              {activeBtn === 1 || activeBtn === 2 || activeBtn === 3 ?
                <>
                  <div className="register__inputs">
                    <div className={`form--item ${errors?.first_name && "error"}`}>
                      <label>Имя</label>
                      <input
                        type="text"
                        {...register("first_name", { required: false, })}
                      />
                      {error?.first_name !== undefined &&
                        error.first_name.map((item, index) => <div style={{ color: 'red' }} key={item.id}>{item}</div>)}

                    </div>

                    <div className={`form--item ${errors?.last_name && "error"}`}>
                      <label>Фамилия</label>
                      <input
                        type="text"
                        {...register("last_name", { required: true, })}
                      />
                      {error?.last_name !== undefined &&
                        error.last_name.map((item, index) => <div style={{ color: 'red' }} key={item.id}>{item}</div>)}

                    </div>


                    <div className={`form--item`}>
                      <label>Номер Телефона</label>
                        <PhoneInput
                        country="KG"
                        {...register('phone',{required: true})}
                        value={state.phone}
                        onChange={(value) => setState({ ...state, phone: value })}
                        />
                      {error?.phone !== undefined &&
                        error.phone.map((item, index) => <div style={{ color: 'red', fontSize: '14px', maxWidth: '300px' }} key={item.id}>{item}</div>)}
                    </div>
                    {/* <div className={`form--item ${errors?.whatsapp && "error"}`}>
                      <label>Номер WhatsApp</label>
                      <input
                        type="number"
                        {...register("whatsapp", {
                          required: true,
                          minLength: {
                            value: 9,
                            message: 'Введите больше 9 символов'
                          },
                          maxLength:{
                              value: 22,
                              message: 'Номер не должен состоять больше 22 символов'

                          }

                         })}
                      />
                      {
                        errors?.whatsapp &&
                        <span>{errors?.whatsapp?.message || ''}</span>

                      }
                      {error?.whatsapp !== undefined &&
                        error.whatsapp.map((item, index) => <div style={{ color: 'red', fontSize: '14px' }} key={index}>{item}</div>)}

                    </div> */}


                    <div className={`form--item ${errors?.password && "error"}`}>
                      <label>Установите пароль</label>
                      <div className="password__eyes">

                        <input
                          type={type}
                          {...register("password", {
                            required: true, minLength: {
                              value: 8,
                              message: 'пароль должен состоять из 8 символов'
                            }
                          })}
                        />
                        <span onClick={ChangeIcon}>{icon}</span>
                      </div>
                      {
                        errors.password &&
                        <span>{errors?.password?.message || ''}</span>
                      }

                      {error?.password !== undefined &&
                        error.password.map((item, index) => <div style={{ color: 'red', fontSize: '12px' }} key={item.id}>{item}</div>)}

                    </div>

                    <div className={`form--item ${errors?.password_confirm && "error"}`}>
                      <label>Повторите пароль</label>
                      <div className="password__eyes">

                        <input
                          type={type1}
                          {...register("password_confirm", { required: true, minLength: 8, })}
                        onChange={handleDisable}
                          
                        />
                        <span onClick={ChangeIcon1}>{icon1}</span>
                      </div>
                      {watch("password_confirm") !== watch("password") &&
                        getValues("password_confirm") ? (
                        <span style={{ color: "red", fontSize: '12px' }}>Пароли не совпадают</span>
                      ) : null}
                    </div>

                    <input type="hidden"
                      {...register("role", { required: true })}
                      value={roleState}
                    />
                   <div className="sendButton">
                    <button type="submit" disabled={disabled}
                    >Отправить</button>
                  </div>
                  </div>
              
                </>
                : ''
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
