import React from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';
import Logo from '../assets/svg/logo.svg';
import Hamburger from '../assets/svg/icons/hamburger.svg';
import Close from '../assets/svg/icons/close.svg';
import MenuResponse from './MenuResponse';

const HeaderResponse = () => {
  const { profile } = useAppSelector((state) => state);
  const [active, setActive] = React.useState(false);

  return (
    <>
      <div className="HeaderResponse">
        <div className="header__boxRes">
          <Link className="profile" to="profile">
            <img className="profile__photo" src={profile.data.avatar} alt="" />
          </Link>
          <Link to="/">
            <div className="header__boxRes__text">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div>
            {active ? (
              <img className="close" src={Close} alt="" onClick={() => setActive(!active)} />
            ) : (
              <img
                onClick={() => setActive(!active)}
                className="header__boxRes__hamburger"
                src={Hamburger}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <div className="HeaderResponse_modal">
        {active ? <MenuResponse active={active} setActive={setActive} /> : null}
      </div>
    </>
  );
};

export default HeaderResponse;
