import { useEffect, useState } from 'react';
import prev_navigation_btn from 'assets/svg/icons/prev_pagination_btn.svg'
import next_navigation_btn from 'assets/svg/icons/next_pagination_btn.svg'


export interface PaginatedState {
    count: number;
    next: string;
    previous: string;
    results: any[];
}

interface IPagination {
    data: PaginatedState;
    handle: any;
    len?: number;
}


export const Pagination = ({ data, handle, len = 12 }: IPagination) => {
    const { count } = data;
    const MAX_LENGTH = len



    const [position, setPosition] = useState(+(localStorage.getItem('paginationPosition') || 1))
    const [length, setLength] = useState([1])


    console.log('position', position)


    const handlePaginate = (pos: number) => {
        if (pos <= length.length && pos > 0) {
            setPosition(pos)
        }
        handle(pos)
        localStorage.setItem('paginationPosition', pos.toString());
    }

    useEffect(() => {
        let arr: number[] = []
        for (let index = 1; index <= count; index += MAX_LENGTH) {
            arr.push(Math.ceil(index / MAX_LENGTH))
        }
        setLength(arr)
    }, [count])



    if (count === 0) {
        return <div>Нет ни одного тура по вашему запросу</div>
    }

    return (
        <div className="pagination_main">
            <div className="pagination_children">
                <button type="button" className={`arrow ${position > 1 ? '' : 'disable'}`}> <img src={prev_navigation_btn} alt="" onClick={(e) => handlePaginate(position - 1)}/></button>

                {length.map((number) => {
                    return (<div className={`pagination_number ${position === number ? 'checked' : ''}`} key={number} onClick={(e) => handlePaginate(number)}>
                        <div >{number}</div>
                    </div>)
                })}

                <button type="button" className={`arrow ${length.length > position ? '' : 'disable'}`}> <img src={next_navigation_btn} alt="" onClick={(e) => handlePaginate(position + 1)} /></button>
            </div>
        </div>
    )
}