import React, { SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "redux/hooks";
import { editAirline } from "redux/slices/airlines";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";

import bin from "../../../../assets/svg/icons/bin.svg";
import change_white_svg from "../../../../assets/svg/icons/change_white_svg.svg";
import { deleteAirline_prices, editAirline_prices } from "redux/slices/airlines__prices";
import CreatableSelect from "react-select/creatable";
import { saveCity } from "redux/slices/cities";

import DatePicker from "react-datepicker";

interface Props {
  item: any;
  setModal: React.Dispatch<SetStateAction<boolean>>;
  cities: any;
  id: number;
  deleteAirline_price: Function;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  airlineId: number

  //   remove: Function;
}

export const PriceList = ({
  item,
  setModal,
  cities,
  id,
  deleteAirline_price,
  isLoading,
  setIsLoading,
  airlineId
  //   remove
}: // remove
  Props) => {
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  const dispatch = useAppDispatch();
  console.log(id);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "250px",
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [dateRange, setDateRange] = useState([item.data_from, item.data_to]);

  const [startDate, endDate] = dateRange;

  const dataStart = new Date(`${startDate}`)
  const dataEnd = new Date(`${endDate}`)

  const onSubmit = (data: any) => {

    console.log(data);

    dispatch(
      editAirline_prices(id, {
        price: data.price,
        date_from: startDate !== null
          ? dataStart.toISOString().slice(0, 10)
          : item?.date_from,
        date_to: startDate !== null
          ? dataStart.toISOString().slice(0, 10)
          : item?.date_from,
        airline: airlineId,
        city: data.city.id
      })
    );
    setIsLoading(!isLoading);
    setEditable(!editable);
  };


  const handleCityChange = (newValue: any) => {
    setValue("city", newValue);
  };

  const city = cities.items.find((air: any) => air.id === item.city)


  useEffect(() => {
    setValue("city", {
      id: city?.city,
      title: city?.title,
      value: city?.city,
      label: city?.title,
    });
  }, []);





  return (
    <>
      {editable ? (
        <tr
          onMouseOver={() => setAppear(true)}
          onMouseOut={() => setAppear(false)}
        >
          <td className="actions">
            {appear ? (
              <div className="actions__flex">
                <span className="editing_icon">
                  ...
                  <img src={change_blue_svg} alt="" />
                </span>
                <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
                  ok
                </span>
              </div>
            ) : null}
          </td>
          <td >
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <div className={`form--item ${errors?.city && "error"}`}>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      {...field}
                      isDisabled={cities.loading}
                      isLoading={cities.loading}
                      options={cities.items}
                      styles={customStyles}
                      onChange={handleCityChange}
                    />
                  )}
                />
                {errors?.city && <div className="error_text">Выберите город</div>}
              </div>
            </div>
          </td>

          <td>
            <div>
              <form>
                <input
                  type="text"
                  defaultValue={item.price}
                  {...register("price", { required: true })}
                />
              </form>
              {errors.price && <div className="error_text">Выберите цену </div>}
            </div>
          </td>

          <td>
            <div style={{ display: "block" }} className={`form--item ${errors?.date && "error"}`}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    locale="ru"
                    dateFormat="dd.MM.yyyy"
                    {...field}
                    className="DateInput_input"
                    placeholderText={
                      item?.date_from + " - " + item?.date_to
                    }
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                )}
              />
              {errors.date && <div className="error_text">Выберите дату </div>}
            </div>
          </td>


        </tr>
      ) : (
        <tr
          onMouseOver={() => setAppear(true)}
          onMouseOut={() => setAppear(false)}
        >
          <td className="actions">
            {appear ? (
              <div className="actions__flex">
                <div>
                  <img
                    className="edit_icon"
                    src={change_white_svg}
                    alt=""
                    onClick={() => setEditable(!editable)}
                  />
                </div>
                <div>
                  <img className="edit_icon" src={bin} alt="" onClick={() => deleteAirline_price(item.id)} />
                </div>
              </div>
            ) : null}
          </td>
          <td>{city?.title}</td>

          <td>{item.price}</td>

          <td>{item.date_from} - {item.date_to}</td>
        </tr>
      )}
    </>
  );
};
