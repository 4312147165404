import React, { SetStateAction, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import Select from 'react-select'

import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';

interface Props {
  routes: any[];
  HanleAddRoute: any;
  HanleDeleteRoute: any;
  HanleChangeRoute: any;
  // icon: any;
  title: string;
  type: any;
  control: any;
  errors: any;
  item: any;
  setValue: any
}

export const ModelBlock = ({
  routes,
  HanleAddRoute,
  HanleDeleteRoute,
  HanleChangeRoute,
  // icon,
  title,
  type,
  control,
  errors,
  item,
  setValue
}: Props) => {

  
  // fetch data from our store
  const { cities } = useAppSelector((state) => state);

  // set up dispatch
  const dispatch = useAppDispatch(); 


  return (
    <div className="block1">
      <div className="arrow_next">
        <div>{title ? title : ''} </div>
        {/* <img src={icon} alt="" /> */}
      </div>

      <div className="huy">
        {routes?.map((route, index) => {
        
          return (
            <div className="punkt" key={route.id}>
              <div className="flights__item error">
                <label className="flights_label">{index + 1} пункт</label> <br />
                {/* <input className="flights_input" name="city" /> */}
                <Controller
                  name={`city${route.id}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      // isClearable
                      isDisabled={cities.loading}
                      isLoading={cities.loading}
                      options={cities.items}
                      placeholder={<div>Выберите...</div>}
                      //   onCreateOption={option => onCityCreate(option)}
                      onChange={(newValue) => HanleChangeRoute(route.id, newValue)}
                    />
                  )}
                />
                {errors?.[String('city' + route.id)] && (
                  <div className="error_text">Выберите город</div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="buttons_popup">
        <div className="buttons_inner_popup">
          <button className="add_popup" onClick={(e) => {e.preventDefault(); HanleAddRoute(type)}}>
            Добавить{' '}
          </button>
          <button className="remove_popup" onClick={(e) => {e.preventDefault(); HanleDeleteRoute(type)}}>
            Удалить
          </button>
        </div>
      </div>
    </div>
  );
};
