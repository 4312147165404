import React, { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import bin from "../../../../assets/svg/icons/bin.svg";
import change_white_svg from "../../../../assets/svg/icons/change_white_svg.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";

import { Modal } from "./Modal";
import { useAppDispatch } from "redux/hooks";
import { editAirline, fetchAirline, postAirline } from "redux/slices/airlines";

interface Props {
  item: any;
  removeAirline: Function
  airlines: any
  cities: any
  airlines_prices: any;
  isLoading: boolean;
  setIsLoading:  React.Dispatch<SetStateAction<boolean>>;
  index: number;
  // setModal: React.Dispatch<SetStateAction<boolean>>
  // modal: boolean
  //   remove: Function;
}

export const List = ({
  index,
  item,
  removeAirline,
  cities,
  airlines,
  airlines_prices,
  isLoading,
  setIsLoading
}: // remove
Props) => {
  const [editable, setEditable] = useState(false);
  const [appear, setAppear] = useState(false);

  const dispatch = useAppDispatch()

  const [modal, setModal] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  

  const onSubmit = (data: any,) => {


    dispatch(editAirline(item.id,
      {
        ...data,
      }
    ))
    setEditable(!editable);
    setIsLoading(!isLoading)
  };

  

  return (
    <>
      {editable ? (
        <>
          <tr
            onMouseOver={() => setAppear(true)}
            onMouseOut={() => setAppear(false)}
          >
            <td>0{index + 1}</td>
            <td className="actions">
            {appear ? (
              <div className="actions__flex">
                <span className="editing_icon">
                  ...
                  <img src={change_blue_svg} alt="" />
                </span>
                <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
                  ok
                </span>
              </div>
            ) : null}
            </td>
            <td>
              <div>
                <form>
                  <input
                    type="text"
                    defaultValue={item.title}
                    {...register("title")}
                  />
                </form>
              </div>
            </td>
            <td>
              <a style={{cursor: "pointer"}} onClick={() => setModal(true)}>Цена авиакомпани</a>
            </td>
            
            <td><div>
              <form>
                <input
                  type="text"
                  defaultValue={item.default_price}
                  {...register("default_price")}
                />
              </form>
            </div></td>
          </tr>

          <Modal isLoading={isLoading} setIsLoading={setIsLoading} id={item.id} airlines_prices={airlines_prices} cities={cities} airlines={airlines} setModal={setModal} modal={modal} item={item.prices} />
        </>
      ) : (
        <>
          <tr
            onMouseOver={() => setAppear(true)}
            onMouseOut={() => setAppear(false)}
          >
            <td>0{index + 1}</td>
            <td className="actions">
             
                <div className={appear ? "actions__block" : "actions__none"}>
                  <div>
                    <img
                      className="edit_icon"
                      src={change_white_svg}
                      alt=""
                      onClick={() => setEditable(!editable)}
                    />
                  </div>
                  <div>
                    <img className="edit_icon" onClick={() => removeAirline(item.id)} src={bin} alt="" />
                  </div>
                </div>
            </td>
            <td>{item.title}</td>
            <td>
              <a style={{cursor: "pointer"}} onClick={() => setModal(true)}>Цена авиакомпани</a>
            </td>
            <td>{item.default_price}$</td>
          </tr>

          <Modal isLoading={isLoading} setIsLoading={setIsLoading} airlines_prices={airlines_prices} cities={cities} airlines={airlines} setModal={setModal} modal={modal} item={item.prices} id={item.id} />
        </>
      )}
    </>
  );
};
