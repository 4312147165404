import { deleteFlight, fetchFlights } from "redux/slices/flights";
import { List } from "./List";

import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import nextArrowSvg from "../../../../assets/svg/icons/next-arrow.svg";
import { deleteAirline, fetchAirline, postAirline } from "redux/slices/airlines";
import { fetchCities } from "redux/slices/cities";
import { fetchAirline_prices } from "redux/slices/airlines__prices";

interface IFormInput {
  title: string;
  default_price: number;
}

export const Airlines = () => {
  const dispatch = useAppDispatch();
  const { airlines, cities, airlines_prices } = useAppSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);  

  useEffect(() => {
    dispatch(fetchAirline());
    dispatch(fetchCities())
    dispatch(fetchAirline_prices());
  }, []);

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      postAirline({
        ...data,
      })
    );
    setIsLoading(!isLoading)
    reset();
  };

  const removeAirline = (id: number) => {
    dispatch(deleteAirline(id));
    reset();
  };

  


  return (
    <>
      <div className="airlines">
        <div className="directory__title">Авиакомпании</div>
        <form className="airlines_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form_price">
            <div className={`form--item ${errors?.title && "error"}`}>
              <label>Наименование</label>
              <input type="text" {...register("title", { required: true })} />
              {errors?.title && (
                <div className="error_text">заполните поле </div>
              )}
            </div>

            <div className={`form--item ${errors?.default_price && "error"}`}>
              <label>Цена для калкульятора($)</label>
              <input
                type="number"
                {...register("default_price", { required: true })}
              />
              {errors?.default_price && (
                <div className="error_text">заполните поле </div>
              )}
            </div>

            <div className="form--item submit">
              <button type="submit" className="btn">
                Добавить
                <img src={nextArrowSvg} alt="" />
              </button>
            </div>
          </div>
        </form>

        <table style={{marginTop: '40px'}}>
          <thead>
            <tr>
              <td>№</td>
              <td></td>
              <td>Авиакомпания</td>
              <td>Цены</td>
              <td>Цена ($)</td>
            </tr>
          </thead>
          <tbody>
            {airlines.items.map((item: any, index: number) => (
              <List
              index={index}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
                removeAirline={removeAirline}
                key={item.id}
                //  remove={handleDeleteHotel}
                item={item}
                cities={cities}
                airlines={airlines}
                airlines_prices={airlines_prices}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
