import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
// import {useHistory} from "react-router"
import { Link } from 'react-router-dom';

import change__img from "assets/svg/icons/change_svg.svg";
import bigdown_choose_btn from "assets/svg/icons/bigdown_choose_btn.svg";
import airplane_icon from "assets/svg/icons/airplane.svg";
import flying_airplain from "assets/svg/icons/flying_airplain.svg";
import item__5 from "assets/svg/icons/item__5.svg";
import top_blue_arrow from "assets/svg/icons/top_blue_arrow.svg";

import { Checkbox } from "components/admin/CRUDTour";
import { fetchAvailableAirlinesCities } from "redux/slices/available_airlines_cities";
import { fetchExcursions } from "redux/slices/excursions";
import { fetchHotelsByCity } from "redux/slices/hotels_by_city";
import { fetchHotels } from "redux/slices/hotels";
import { fetchAdditionalServices } from "redux/slices/additional_services";
import { NCToursCRUD } from "redux/slices/not_confirmed_tours";
import { ToursCRUD } from "redux/slices/tours";
import { TemplatesCRUD } from "redux/slices/templates";
import { fetchAirline } from "redux/slices/airlines";
import { fetchTourCategory } from "redux/slices/tour_category";
import { fetchVisas } from "redux/slices/visa";
import { fetchFlights } from "redux/slices/flights";
import { UsersAllCRUD } from "redux/slices/users_all";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { HotelBlock } from "./HotelBlock";
import { Modal } from "components/admin";
import { Info } from "components/admin/directory/Flights/Info";
import { Agent } from 'components/admin/Agent'
// import axios from "axios";
// import { API_URL } from "services"
// import { List } from "reselect/es/types";
import income, { fetchIncomes } from "redux/slices/income";
import { calculate } from "redux/slices/calculation";
import ReactQuill from 'react-quill';

interface IFormInput {
  id?: number;
  title?: string;
  date?: Date;
  city?: any;
  user?: any;
  airline?: any;
  flight?: any;
  visa?: number;
  isHotOffers?: boolean,
  calculation?: number;
  total_price?: number | undefined;
  public_price?: number;
  category?: number | null;
  is_published?: boolean;
  amount_of_people?: number;
  registered_people?: number;
  amount_of_days?: number;
  hajhead_duty?: number;
  hajhead_registered?: number;
  firm_duty?: number;
  firm_registered?: number;
  status?: number;
  desired_excursions: any[];
  desired_hotels: any[];
  desired_services: any[];
  description?: string;
}

export enum EMode {
  CREATE = "create",
  EDIT = "edit",
  READ = "read",
  SUBMIT = "submit",
}

export enum EType {
  TOUR = "tour",
  NOT_CONFIRMED = "not_confirmed",
  TEMPLATE = "template",
}

export const defaultState: IFormInput = {
  title: "",
  date: undefined,
  city: undefined,
  user: undefined,
  airline: undefined,
  calculation: 0,
  amount_of_people: 0,
  amount_of_days: 0,
  isHotOffers: false,
  desired_excursions: [],
  desired_hotels: [],
  desired_services: [],
  category : null
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: 300,
    border: "1px solid #125d98",
    borderRadius: "6px",
    background: "rgba(70, 163, 236, 0.1)",
    display: "flex",
    justifyContent: "center",
  }),
};

const getRoutesFromAndTo = (routes: any) => {
  const routesFrom = routes
    .filter((route: any) => route.type === 1)
    .sort((a: any, b: any) => b.id - a.id)[0].city_title;
  const routesTo = routes
    .filter((route: any) => route.type === 2)
    .sort((a: any, b: any) => a.id - b.id)[0].city_title;
  return `${routesFrom} - ${routesTo}`;
};

function convertToEdit(state: any) {
  const {
    id,
    category,
    title,
    date,
    city,
    user,
    airline,
    flight,
    amount_of_people,
    firm_registered,
    hajhead_duty,
    hajhead_registered,
    amount_of_days,
    calculation,
    desired_excursions,
    desired_hotels,
    desired_services,
    total_price,
    public_price,
    visa,
    description,
    isHotOffers,
  } = state;


  return {
    id,
    title,
    date: new Date(date),
    city: {
      label: (
        <div className="choose_clicked">
          <img src={flying_airplain} alt="" />
          <div>{city?.title}</div>
        </div>
      ),
      value: city?.id,
    },
    user: user ? {
      value: user,
      ...user,
      label: (
        <div style={{ color: "black" }}>{user.first_name}  {user.last_name}</div>
      ),
    } : null,
    airline: airline
      ? {
        label: (
          <div className="choose_clicked">
            <img src={airplane_icon} alt="" />
            <div>{airline.title}</div>
          </div>
        ),
        value: airline.id,
        price: airline.default_price,
      }
      : {
        label: (
          <div className="choose_clicked">
            <img src={airplane_icon} alt="" />
            <div>{flight?.airline_detail.title}</div>
          </div>
        ),
        value: flight?.airline_detail.id,
      },
    flight,
    amount_of_people,
    registered_people: 0,
    hajhead_duty: hajhead_duty | 0,
    hajhead_registered: hajhead_registered | 0,
    firm_duty: 0,
    firm_registered: firm_registered | 0,
    amount_of_days,
    is_published: true,
    total_price: total_price,
    public_price: public_price,
    category: category ? category.id : null,
    not_confirmed_tour_id: id,
    visa: visa,
    calculation: calculation,
    desired_excursions: desired_excursions?.map((item: any) => {
      return { ...item.excursion, isChecked: true }
    }),
    desired_hotels: desired_hotels,
    desired_services: desired_services?.map((item: any) => {
        return { ...item.additional_services, isChecked: true }
      }
    ),
    description: description,
    isHotOffers : isHotOffers
  };
}

function convertToSave(formState: any, profile: any | null = null) {

  return {
    ...formState,
    date: new Date(formState.date.setDate(formState.date.getDate()))?.toISOString().split("T")[0],
    user: formState.user === undefined || formState.user === null ? profile?.data?.id : formState?.user?.id,
    city: formState.city?.value,
    desired_excursions: formState.desired_excursions.filter(
      (item: any) => item.isChecked
    ).map(
      (item: any) => item.id
    ),
    desired_services: formState.desired_services?.filter(
      (item: any) => item.isChecked
    ).map((item: any) => item.id),
    airline: formState.airline?.value,
    flight: formState.flight?.id,
    desired_hotels: formState.desired_hotels?.map((item: any) => {
      return {
        ...item,
        hotel: item.hotel.id,
      };
    }),
    description: formState.description
  };
}




function convertToUpdate(formState: any, oldHotels?: any, oldExcursions?: any, oldServises?: any, profile: any | null = null) {

  let oldHotelsID = oldHotels.map((item: any) => item.id)
  let currentHotelsID = formState.desired_hotels.map((item:any) => item.id)

  console.log('oldHotelsID', oldHotelsID)
  console.log('currentHotelsID', currentHotelsID)


  return {
    ...formState,
    date: new Date(formState.date.setDate(formState.date.getDate()))?.toISOString().split("T")[0],
    user: formState.user === undefined || formState.user === null ? profile?.id : formState.user.id,
    city: formState.city?.value,
    desired_excursions: formState.desired_excursions.filter(
      (item: any) => item.isChecked && !oldExcursions.some((oldexc: any) => oldexc.excursion.id === item.id)
    ).map(
      (item: any) => item.id
    ),
    desired_services: formState.desired_services?.filter(
      (item: any) => item.isChecked && !oldServises.some((oldexc: any) => oldexc.additional_services.id === item.id)
    ).map(
      (item: any) => item.id
    ),
    airline: formState.airline?.value,
    flight: formState.flight?.id,
    desired_hotels: formState.desired_hotels?.flatMap((item: any) => !item.id ? {
      hotel: item.hotel.id,
      amount_of_days: item.amount_of_days
    }:[]),
    removed_desired_excursions: oldExcursions.filter(
      (oldexc: any) => formState.desired_excursions.some((exc: any) => exc.id === oldexc.excursion.id && !exc.isChecked)
    ).map(
      (item: any) => item.id
    ),
    removed_desired_services: oldServises.filter(
      (oldexc: any) => formState.desired_services?.some((exc: any) => exc.id === oldexc.additional_services.id && !exc.isChecked)
    ).map(
      (item: any) => item.id
    ),
    removed_desired_hotels: oldHotelsID.filter((item: any) => !currentHotelsID.includes(item)),
    updated_desired_hotels: formState.desired_hotels?.flatMap((item: any) =>item.id ? (
        {
          id: item.id,
          hotel_id: item.hotel.id,
          amount_of_days: item.amount_of_days
        }
        ) : []
    ),
    description: formState.description
  };
}



function convertToCalculate(formState: any, profile: any | null = null) {
  // console.log('formState', formState);

  return {
    ...formState,
    date: formState.date?.toISOString().split("T")[0],
    user: profile ? profile?.id : null,
    city: formState.city?.value,
    excursions: formState.desired_excursions.filter(
      (item: any) => item.isChecked
    ).map(
      (item: any) => item.id
    ),
    additional_services: formState.desired_services?.filter(
      (item: any) => item.isChecked
    ).map((item: any) => item.id),
    airline: formState.airline?.value,
    flight: formState.flight?.id,
    desired_hotels: formState.desired_hotels?.map((item: any) => {
      return {
        ...item,
        hotel: item.hotel.id,
      };
    }),
  };
}

export const CRUD = ({
  state = defaultState,
  mode = EMode.CREATE,
  type = EType.NOT_CONFIRMED,
}) => {
  const { desired_services: oldServises, desired_hotels: oldHotels, desired_excursions: oldExcursions } = state

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const history = useHistory();


  useEffect(() => {
    dispatch(fetchAvailableAirlinesCities());
    dispatch(fetchHotels());  
    dispatch(fetchExcursions());
    dispatch(fetchHotelsByCity());
    dispatch(fetchAdditionalServices());
    dispatch(fetchAirline());
    dispatch(fetchVisas());
    dispatch(fetchTourCategory());
    dispatch(fetchFlights(1,true));
    dispatch(UsersAllCRUD.fetchAll());
    dispatch(fetchIncomes());
  }, [dispatch]);

  const {
    available_airlines_cities,
    hotels_by_city,
    excursions,
    additional_services,
    profile,
    flights,
    tour_categories,
    airlines,
    incomes,
    users_all
  } = useAppSelector((state) => state);

  const [formState, setFormState] = useState<IFormInput>(
    mode === EMode.CREATE ? state : convertToEdit(state)
  );


  console.log(users_all)


  useEffect(() => {
    if (formState.desired_services.length < additional_services.data.results.length) {
      dispatch(fetchAdditionalServices())
    }
    if (formState.desired_excursions.length < excursions.items.length) {
      dispatch(fetchExcursions())
    }
  }, [formState.desired_services, formState.desired_excursions])

  useEffect(() => {
    console.log((formState.user ? incomes?.data.results.find((item:any) => item.id === formState?.user?.income)?.price : 0));
    
    console.log("formState", formState);
  }, [formState])


  const [select, setSelect] = useState(1);
  const [user, setUser] = useState(false);

  const [showPersonBlock, setShowPersonBlock] = useState(mode === EMode.READ || mode === EMode.EDIT || (mode === EMode.CREATE && type === EType.TOUR));
  const [showDaysBlock, setShowDaysBlock] = useState(mode === EMode.READ || mode === EMode.EDIT || (mode === EMode.CREATE && type === EType.TOUR));

  const [cityOptions, setCityOptions] = useState([]);

  const [flightModal, setFlightModal] = useState(false);
  const [peopleModal, setPeopleModal] = useState(false);

  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    if (formState.category !== undefined && null) {
      setCategories(
        tour_categories.items.filter(
          (item: any) => item.id === formState.category
        )
      );
    } else {
      setCategories(tour_categories.items);
    }
  }, [tour_categories.items]);



  const handleSetCategory = (category: number) => {
    if (formState.category === category) {
      setValue("category", 0);
      setCategories(tour_categories.items);
    } else {
      setActive(false)
      setValue("category", category);
      setCategories(
        tour_categories.items.filter((item: any) => item.id === category)
      );
    }
  };

  const [get, setGet] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (available_airlines_cities.data) {
      const { cities } = available_airlines_cities.data;

      const flightObj = cities.map((item: any) => ({
        isDepartureCity : item.isDepartureCity,
        value: item.id,
        label: (
          <div className="choose_clicked">
            <img src={flying_airplain} alt="" />
            <div>{item.title}</div>
          </div>
        ),
      }));

      setCityOptions(flightObj);
    }
  }, [available_airlines_cities]);


  const setValue = (name: string, value: any) => {    
    setFormState({
      ...formState,
      [name]: value,
    });

    if (name === "flight") {
      const startDate = new Date(value.date_to);
      const endDate = new Date(value.date_from);
      const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

      const diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));
      setFormState({
        ...formState,
        [name]: value,
        airline: airlines.items.find((item: any) => item.id === value.airline),
        city: cityOptions.find((item: any) => item.value === value.city.id),
        // amount_of_people: Math.abs(value.places - value.reserved_places),
        amount_of_days: diffDays,
        date: new Date(value.date_from),
        is_published: true
      });
    }

    setErrors({
      ...errors,
      [name]: undefined,
    });
  };


  const [priceObj, setPriceObj] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);

  const handleSelect = (id: number) => {
    setSelect(id);
  };

  const new_errors: any = {};

  const handleErrors = (formState: any) => {
    if (!formState.city) {
      new_errors.city = "City is required";
    }

    if (!formState.date) {
      new_errors.date = "Date is required";
    }

    if (!formState.airline) {
      new_errors.airline = "Airline is required";
    }

    if (!formState.amount_of_people) {
      new_errors.amount_of_people = "Amount of people is required";
    }

    if (type !== EType.TEMPLATE) {
      if (!formState.amount_of_days) {
        new_errors.amount_of_days = "Amount of days is required";
      }
    }

    if (formState.desired_hotels?.length === 0) {
      new_errors.desired_hotels = "Desired hotels is required";
    }

    if (mode === EMode.CREATE && type === EType.TEMPLATE) {
      if (!formState.title) {
        new_errors.title = "Title is required";
      }
    }

    if (mode === EMode.CREATE && type === EType.TOUR && formState.category === null) {
      new_errors.category = "Category is required";
    }  
  };

  const handleSubmit = (e: any, for_create_template: boolean = false) => {
    e.preventDefault();

    handleErrors(formState);

    if (Object.keys(new_errors).length) {
      console.log("errors", new_errors);
      setErrors(new_errors);
    } else {
      if (mode === EMode.CREATE && type === EType.NOT_CONFIRMED) {
        dispatch(
          NCToursCRUD.create({
            category: null,
            ...convertToSave(formState, profile),
          })
        );
      } else if (mode === EMode.EDIT && type === EType.NOT_CONFIRMED) {
        dispatch(NCToursCRUD.patch(Number(formState.id), convertToUpdate(formState, oldHotels, oldExcursions, oldServises)));
      } else if (mode === EMode.SUBMIT && type === EType.NOT_CONFIRMED) {
        dispatch(ToursCRUD.create(convertToSave(formState)));
      } else if (mode === EMode.CREATE && type === EType.TEMPLATE) {
        dispatch(TemplatesCRUD.create(convertToSave(formState, profile)));
      } else if (mode === EMode.EDIT && type === EType.TEMPLATE && for_create_template === false) {
        dispatch(TemplatesCRUD.patch(Number(formState.id), convertToUpdate(formState, oldHotels, oldExcursions, oldServises)));
      } else if (mode === EMode.EDIT && type === EType.TEMPLATE && for_create_template === true) {
        dispatch(
          NCToursCRUD.create({
            category: null,
            ...convertToSave(formState, profile),
          }, true)
        );
      } else if (mode === EMode.EDIT && type === EType.TOUR) {
        dispatch(ToursCRUD.edit(convertToUpdate(formState, oldHotels, oldExcursions, oldServises)));
      } else if (type === EType.TOUR && mode === EMode.CREATE) {
        console.log("create", convertToSave(formState, profile));
        dispatch(ToursCRUD.create(convertToSave(formState, profile)));
      }
    }
  };


  // console.log('incomes',incomes);
  const getHightIncome = useMemo(() => {
    let topPrice = 0
    if(incomes){
      incomes?.data.results.forEach((item:any) =>{
        topPrice = +item.price > topPrice ? +item.price : topPrice
      })
    }
    return topPrice
  },[incomes.data.results.length])
  
  
  const rejectAction = (e: any) => {
    e.preventDefault()
    dispatch(NCToursCRUD.remove(Number(formState.id)));
  }

  const deleteTemplate = (e: any) => {
    e.preventDefault()
      dispatch(TemplatesCRUD.remove(Number(formState.id)));
      
    }    
       
  const handleSetTour = (obj: any) => {
    setPriceObj(obj.price);
    setValue("flight", obj);
    setFlightModal(false);
    setPeopleModal(true);
  };

  const checkError = (names: string[]) => {
    return names.some((name) => errors[name]);
  };

  const RowHeader = ({ index, title, text }: any) => {
    return (
      <div onClick={() => handleSelect(index)} className="hostels__block">
        <div className="hostels__block__img__text">
          <div className="hostels_text">{title}</div>
          {select === index ? (
            <img src={top_blue_arrow} alt="" />
          ) : (
            <img src={bigdown_choose_btn} alt="" />
          )}
        </div>
        {text ? <div className="hostel__count">{text}</div> : null}
      </div>
    );
  };

  const setCalculate = (summ: any) => {
    setValue('calculation', summ?.total_price)
  }

  const [calculationError, setCalculationError] = useState(0)

  const handleCalculate = () => {
    // calculatePrice(formState, setValue, visa, incomes.data.results);
    if (type === EType.TEMPLATE) {
      if(profile.data.role === 1 || profile.data.role === 2){
        dispatch(calculate('template', convertToCalculate(formState), setCalculate, setCalculationError))
      }else{
        dispatch(calculate('not_confirmed', convertToCalculate(formState,profile.data), setCalculate, setCalculationError))
      }
    } else if (type === EType.NOT_CONFIRMED && mode !== EMode.SUBMIT) {
      dispatch(calculate('not_confirmed', convertToCalculate(formState, profile.data), setCalculate, setCalculationError))
    } else if (type === EType.TOUR || (type === EType.NOT_CONFIRMED && mode === EMode.SUBMIT)) {
      dispatch(calculate('tour', convertToCalculate(formState, formState.user), setCalculate, setCalculationError))
    }
    setActive(true)
  }

  useEffect(() => {
    setActive(false);
  }, [
    formState.amount_of_people,
    formState.amount_of_days,
    formState.airline,
    formState.desired_hotels,
    formState.desired_excursions,
    formState.desired_services,
    formState.date,
  ]);

  const CheckPeople = (e: any) => {
    e.preventDefault();
    if (formState.amount_of_people !== undefined) {
      setShowPersonBlock(true);
    }
  };
  const CheckDay = (e: any) => {
    e.preventDefault();
    if (formState.amount_of_days !== undefined) {
      setShowDaysBlock(true);
    }
  };

  // const handleSetUser = (newUser: any) => {
  //   setUser(!user)
  //   setValue('user', newUser)
  // }

  useEffect(() => {
    let services = additional_services.data.results.map((item) => {
      for (let i = 0; i < state.desired_services.length; i++) {
        let service = state.desired_services[i];

        if (item.id === service.additional_services.id) {
          return { ...service.additional_services, isChecked: true }
        }
      }
      return { ...item, isChecked: mode === EMode.CREATE ? item.isSelected : false }
    })

    setValue('desired_services', services)
  }, [additional_services.data.results]);


  useEffect(() => {
    let list = excursions.items.map((item) => {
      for (let i = 0; i < state.desired_excursions.length; i++) {
        let ex = state.desired_excursions[i];

        if (item.id === ex.excursion.id) {
          return { ...ex.excursion, isChecked: true }
        }
      }
      return { ...item, isChecked: mode === EMode.CREATE ? item.isSelected : false }
    })

    setValue('desired_excursions', list)
  }, [excursions.items]);

  
  const onEditorStateChange = (editorState: any) => {
    setValue("description", editorState);
  };

  return (
    <form>
      <div className="create_home">
        <div className="hostels">
          {type === EType.TEMPLATE ? (
            <input
              disabled={mode === EMode.READ}
              type="text"
              name="title"
              className={checkError(["title"]) ? `error` : ""}
              value={formState.title}
              onChange={(e) => setValue("title", e.target.value)}
              placeholder="Название пакета"
            />
          ) : null}
          <div
            className={`hostels__row 
          ${formState?.airline &&
                formState.city &&
                formState.date &&
                formState.amount_of_days &&
                formState.amount_of_people
                ? "completed"
                : ""
              } 
          ${checkError([
                "airline",
                "city",
                "date",
                "amount_of_days",
                "amount_of_people",
              ])
                ? "error"
                : ""
              }`}
          >
            <RowHeader index={1} title="Рейс" />

            <div className={select === 1 ? "hostels__select" : "none"}>
              {/* start */}
              {formState.flight || !(mode === EMode.CREATE && type === EType.TOUR) ? (
                <div className="choose">
                  <div className={`form--item ${errors?.airline && "error"}`}>
                    <Select
                      isDisabled={mode === EMode.READ || (mode === EMode.CREATE && type === EType.TOUR)}
                      isLoading={available_airlines_cities.loading}
                      options={airlines.items.map((item: any) => ({
                        value: item.id,
                        price: item.default_price,
                        prices: item.prices,
                        label: (
                          <div className="choose_block">
                            <div className="choose_clicked">
                              <img src={airplane_icon} alt="" />
                              <div style={{ color: "black" }}>{item.title}</div>
                            </div>
                          </div>
                        ),
                      }))}
                      styles={customStyles}
                      defaultValue={formState.airline}
                      value={formState.airline}
                      placeholder="Выберите авиакомпанию"
                      onChange={(item: any) => setValue("airline", item)}
                    />
                    {errors?.airline && (
                      <div className="error_text">Выберите категорию номера</div>
                    )}
                  </div>

                  <div className={`form--item ${errors?.city && "error"}`}>
                    <Select
                      isDisabled={mode === EMode.READ || (mode === EMode.CREATE && type === EType.TOUR)}
                      isLoading={available_airlines_cities.loading}
                      options={cityOptions.filter((item:any) => item.isDepartureCity === true)}
                      styles={customStyles}
                      defaultValue={formState.city}
                      value={formState.city}
                      placeholder="Выберите город вылета"
                      onChange={(item: any) => setValue("city", item)}
                    />
                    {errors?.city && (
                      <div className="error_text">Выберите город</div>
                    )}
                  </div>

                  <div className="main__option">
                    <div className={`form--item ${errors?.date && "error"}`}>
                      <DatePicker
                        locale="ru"
                        disabled={mode === EMode.READ || (type === EType.TOUR && mode === EMode.CREATE)}
                        selected={formState.date}
                        isClearable={!((type === EType.TOUR || type === EType.TEMPLATE) && (mode === EMode.CREATE || mode === EMode.READ))}
                        onChange={(date: any) => setValue("date", date)}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Выберите желаемую дату вылета"
                        minDate={new Date()}
                      />
                      {errors?.date && (
                        <div className="error_text">Выберите дату </div>
                      )}
                    </div>
                  </div>

                  {formState.flight && mode === EMode.EDIT ? (
                    <div className="tour">
                      Рейс: {formState.flight?.airline_category_title}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="choose">
                {formState.flight || !(mode === EMode.CREATE && type === EType.TOUR) ? (
                  <div className="days-input">
                    {showPersonBlock ? (
                      <div className="days-input-count">
                        <div className="days-input-count__title">
                          {formState.amount_of_people === 1
                            ? "1 человек"
                            : `${formState?.amount_of_people} человека`}
                        </div>
                        <input type="hidden" />
                        {mode !== EMode.READ ?
                          <img
                            src={change__img}
                            alt=""
                            onClick={() => setShowPersonBlock(false)}
                          />
                          : null
                        }
                      </div>
                    ) : (
                      <div className="form">
                        <div
                          className={`form--item ${errors?.amount_of_people && "error"
                            }`}
                        >
                          <input
                            type="number"
                            min="1"
                            placeholder="Места"
                            value={formState.amount_of_people || ""}
                            onChange={(e) =>
                              setValue("amount_of_people", +e.target.value)
                            }
                            onBlur={CheckPeople}
                            required
                          />
                          {errors?.amount_of_people && (
                            <div className="error_text">
                              Введите количество людей
                            </div>
                          )}
                        </div>
                        <div>
                          <button
                          disabled={formState.flight?.places -
                          formState.flight?.reserved_places < (formState.amount_of_people || 0)} 
                          type="button" 
                          onClick={(e) => CheckPeople(e)}
                          >
                            Сохранить
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                {formState.flight || !(mode === EMode.CREATE && type === EType.TOUR) ? (
                  <div className="days-input">
                    {showDaysBlock ? (
                      <div className="days-input-count">
                        <div className="days-input-count__title">
                          {formState.amount_of_days === 1
                            ? "1 день"
                            : `${formState.amount_of_days} дня`}
                        </div>
                        {mode !== EMode.READ ?
                          (formState.flight ? 
                          null :
                          <img
                            src={change__img}
                            alt=""
                            onClick={() => setShowDaysBlock(false)}
                          />) : null
                        }

                      </div>
                    ) : (
                      <div className="form">
                        <div className="form--item">
                          <input
                            placeholder="Количество ночей"
                            type="number"
                            min="1"
                            value={formState.amount_of_days || ""}
                            onChange={(e) =>
                              setValue("amount_of_days", +e.target.value)
                            }
                            onBlur={CheckDay}
                            required
                          />
                          {errors?.amount_of_days && (
                            <div className="error_text">
                              Введите количество ночей
                            </div>
                          )}
                        </div>
                        <div>
                          <button type="button" onClick={(e) => CheckDay(e)}>
                            Сохранить
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}


                {((mode === EMode.CREATE && type === EType.TOUR) || (mode === EMode.EDIT && type === EType.TOUR) || mode === EMode.SUBMIT) && (profile?.data?.role !== 4 && profile?.data?.role !== 3) ? (
                  <button type="button" onClick={() => setFlightModal(true)}>
                    {formState.flight ? "Обновить рейс" : "Выбрать рейс"}
                  </button>
                ) : null}

                {formState.flight ? (
                  <div className="tour">
                    Рейс: {getRoutesFromAndTo(formState.flight?.routes)}
                  </div>
                ) : null}
              </div>


              {/* end */}
            </div>
          </div>

          <div
            className={`hostels__row ${formState.desired_hotels?.map(i => i.amount_of_days).reduce((a, b) => a + b, 0) === formState.amount_of_days ? "completed" : ""
              ? "completed"
              : "error"
              } ${checkError(["desired_hotels"]) ? "error" : ""}`}
          >
            <RowHeader
              index={1}
              title="Гостиницы"
              text={`${hotels_by_city.items.length} отеля`}
            />
            <div className={select === 1 ? "hostels__select" : "none"}>
              {hotels_by_city.items.map((city: any, i: number) => (
                <HotelBlock
                  mode={mode}
                  setValue={setValue}
                  formState={formState}
                  key={city.id}
                  city={city}
                  errors={errors}
                />
              ))}
            </div>
          </div>

          <div className="blocks">
            <div className="block">
              <div className="block__title">Дополнительные экскурсии</div>
              <div className="block__items">
                {additional_services.data?.results && formState.desired_excursions?.filter(i => i.isActive).map((item: any, i: number) => (
                  <Checkbox
                    isDisabled={mode === EMode.READ}
                    type={type}
                    mode={mode}
                    key={i}
                    item={item}
                    icon={item__5}
                    formState={formState}
                    setFormState={setFormState}
                    errors={errors}
                    state="desired_excursions"
                  />
                ))}
              </div>
            </div>
            <div className="block">
              <div className="block__title">Дополнительные услуги</div>
              <div className="block__items">
                {excursions?.items && formState.desired_services?.filter(i => i.isActive).map((item: any, i: number) => (
                  <Checkbox
                    isDisabled={mode === EMode.READ}
                    type={type}
                    mode={mode}
                    key={i}
                    item={item}
                    icon={item__5}
                    formState={formState}
                    setFormState={setFormState}
                    errors={errors}
                    state="desired_services"
                  />
                ))}
              </div>
            </div>
            {(type === EType.TOUR || type === EType.NOT_CONFIRMED) && (profile?.data?.role !== 4 && profile?.data?.role !== 3) ?
              <div className="block">
                <div className="block__title">Агент:</div>
                <div className="block__items">
                  <Select
                    isLoading={users_all.loading}
                    options={users_all.results.filter((item: any) => item.role === 3 || item.role === 4).map((item: any) => ({
                      value: item.first_name + item.last_name,
                      ...item,
                      label: (
                        <div style={{ color: "black" }}>{item.first_name}  {item.last_name}</div>
                      ),
                    }))}
                    isSearchable={true}
                    styles={customStyles}
                    defaultValue={formState.user}
                    value={formState.user}
                    placeholder="Выберите агента"
                    onChange={(item: any) => setValue("user", item)}
                  />
                </div>
              </div> : null}
          </div>

          <div className="total">
            <div className="calculator">
              <div className="calculator__title">Калькулятор</div>
              <div className="calculator-block">
                <div className="calculator__text">Сумма на одного человека</div>
                <input
                  disabled
                  type="number"
                  className="calculator__price"
                  value={
                    formState?.calculation?.toFixed(2)
                  }
                />{" "}
                USD
              </div>
            </div>



            {((((type === EType.TOUR || type === EType.TEMPLATE ) && mode === EMode.EDIT) || (type === EType.NOT_CONFIRMED && mode === EMode.SUBMIT)) &&
              (profile?.data?.role < 3 || profile?.data?.role === 5)) || ((type === EType.TOUR || type === EType.TEMPLATE) && mode === EMode.CREATE) ? (
              <>
                <div className="calculator">
                  <div className="calculator__title">Сумма для агента</div>
                  <div className="calculator-block">
                    <div className="calculator__text">
                      Сумма на одного человека
                    </div>
                    <input
                      type="number"
                      min="0"
                      className="calculator__price"
                      name="calculator__price"
                      value={formState?.total_price || ""}
                      onChange={(e) => setValue("total_price", +e.target.value)}
                    />
                  </div>
                </div>
                <div className="calculator">
                  <div className="calculator__title">Сумма публичная</div>
                  <div className="calculator-block">
                    <div className="calculator__text">
                      Сумма на одного человека
                    </div>
                    <input
                      type="number"
                      min={formState.public_price}
                      className="calculator__price"
                      name="calculator__price"
                      value={formState.public_price || ""}
                      onChange={(e) =>
                        setValue("public_price", +e.target.value)
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {((((type === EType.TOUR || type === EType.TEMPLATE) && (profile?.data?.role < 3 || profile?.data?.role === 5)) && (mode === EMode.CREATE || mode === EMode.EDIT)) || (type === EType.NOT_CONFIRMED && mode === EMode.SUBMIT)) ? (
            <div className="total">
              <div className="calculator">
                <div className="calculator__title">Прибыль</div>
                <div className="calculator-block">
                  <div className="calculator__text">Сумма на одного человека</div>
                  <input
                    disabled
                    type="number"
                    className="calculator__price"
                    value={
                        (((type === EType.TEMPLATE ? getHightIncome : 0)+(formState?.total_price || 0) - ( formState?.calculation || 0)) + (formState.user && formState.user.income? +incomes?.data.results.find((item:any) => item.id === formState?.user?.income)?.price : 0)).toFixed(2)
                    }
                  />{" "}
                  USD
                </div>
              </div>
            </div>
          ) : null}

          {((type === EType.TOUR && mode === EMode.CREATE) || (type === EType.TOUR && mode === EMode.EDIT)) && (profile?.data?.role !== 4 && profile?.data?.role !== 3) ? (
            <div className="description__mains">
              <div className="the__main-title">
                <div style={{ width: '100%', maxWidth: '600px' }}>
                  <div className="label__mains">Описание</div>
                  <form>
                    <ReactQuill theme="snow" value={formState.description} onChange={onEditorStateChange} />
                  </form>
                </div>
              </div>
            </div>
          ) : null}

          {((type === EType.NOT_CONFIRMED && mode === EMode.SUBMIT) || (type === EType.TOUR && mode === EMode.CREATE)) &&
           (profile?.data?.role < 3 || profile?.data?.role === 5) ? (
            
            <div className="status">
              <div className="calculator__title">Категория тура</div>
              <div className="block">
                {categories && categories.map((item: any, i: number) => (
                  <div
                    key={i}
                    className="calculator"
                    onClick={() => handleSetCategory(item.id)}
                  >
                    <div className="calculator--block status">
                      <div className="calculator__text">{item.title}</div>
                    </div>
                  </div>
                ))}
              </div>
              {errors?.category && (
              <div className="error_text">
                Выберите категорию
              </div>
            )}
            </div>
           
          ) : null}
        </div>
      </div>
      <div style={{ paddingBottom: "20px" }}>
        <div className="confirmation_btn">
          {type === EType.TOUR ? <label id="hot_deal" className="hot_deals" >
            <input type="checkbox" name="hot_deal" defaultChecked={formState.isHotOffers} onClick={(e)=> setFormState(prev=>({...prev,isHotOffers : !prev.isHotOffers}))} />
            Отметить как 'Горячие предложения'
          </label> : <div>
            {mode === EMode.SUBMIT || (mode === EMode.EDIT && ((type === EType.TEMPLATE && (profile.data.role === 1 || profile.data.role === 2 || profile.data.role === 5))  || type === EType.NOT_CONFIRMED)) ? (
              <button
                type="submit"
                className="exit"
                onClick={(e) => {
                  if (mode === EMode.EDIT && type === EType.TEMPLATE) {
                    deleteTemplate(e)
                  } else {
                    rejectAction(e)
                  }
                }
                }
              >
                {mode === EMode.EDIT && (type === EType.TEMPLATE || type === EType.NOT_CONFIRMED) ? 'Удалить' : 'Отклонить'}

              </button>
            ) : null}
          </div>}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div>
              {mode !== EMode.READ ? (
                (active ? (
                  <div className="send_read">
                    Сумма:{" "}
                    {formState?.calculation?.toFixed(2)}
                    $
                  </div>
                ) : (
                  <button className="send_button" onClick={() => handleCalculate()} disabled={!(formState.desired_hotels?.map(i => i.amount_of_days).reduce((a, b) => a + b, 0) === formState.amount_of_days)}>
                    Посчитать сумму
                  </button>
                ))
              ) : null}

            </div>
            { (mode === EMode.EDIT &&  type === EType.NOT_CONFIRMED) || (mode === EMode.EDIT &&  type === EType.TEMPLATE && (profile.data.role === 1 || profile.data.role === 2 || profile.data.role === 5)) ? (
              (active ? (
              <button type="submit" onClick={(e) => handleSubmit(e)}>
                Сохранить
              </button>
              ):null)
            ) : null}

            {(type === EType.TEMPLATE && mode === EMode.EDIT) && (profile.data.role === 3 || profile.data.role === 4) ?
              (active ? (
                <button type="submit" onClick={(e) => handleSubmit(e, true)}>
                  На потверждение
                </button>
              ):null)
              : null
            }

            {(type === EType.TEMPLATE && mode === EMode.READ) && (profile.data.role !== 3 && profile.data.role !== 4) ?
              <Link to={`/admin/templates/${formState.id}/edit`}>
                <button>Редактировать</button>
              </Link>
              : null
            }

            {active ? (
              <>
                {type === EType.NOT_CONFIRMED && mode === EMode.CREATE ? (
                  <button type="submit" onClick={(e) => handleSubmit(e)}>
                    На потверждение
                  </button>
                ) : null}
              </>
            ) : null}
            {mode === EMode.SUBMIT && (profile.data.role !== 3 && profile.data.role !== 4) ? (
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                disabled={!formState.flight}
              >
                Потвердить
              </button>
            ) : null}


            {type === EType.TOUR || type === EType.TEMPLATE && mode === EMode.CREATE ? (
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                disabled={!active}
              >
                Создать
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        active={flightModal}
        setActive={setFlightModal}
        title="Выберите рейс"
        element={
          <Info
            setGet={setGet}
            get={get}
            click={handleSetTour}
            data={flights.data}
            list={flights.data.results.filter(flight => flight.reserved_places !== flight.places)}
          />
        }
      />

      <Modal
        title="Выберите количество людей"
        active={peopleModal}
        setActive={setPeopleModal}
        element={
          formState.flight && (
            <div className="form--item">
              <label>
                <span>Текущий рейс:</span>
                <p>всего мест: {formState.flight?.places}</p>
                <p>
                  забранировано: {formState.flight?.reserved_places}
                </p>
                <p>
                  свободно:{" "}
                  {formState?.flight.places -
                    formState.flight?.reserved_places}
                </p>
                <br />
                <p>Количество людей</p>{" "}
              </label>
              <input
                style={{marginBottom : '10px'}}
                type="number"
                min="1"
                max={
                  formState.flight?.places -
                  formState.flight?.reserved_places
                }
                name="people"
                value={formState.amount_of_people || ""}
                onChange={(e) =>
                      setValue("amount_of_people", +e.target.value)
                     }
                required
              />
              <button
                disabled={formState.flight?.places -
                  formState.flight?.reserved_places < (formState.amount_of_people || 0) }
                type="button"
                onClick={() => setPeopleModal(false)}
              >
                Сохранить
              </button>
            </div>
          )
        }
      />
    </form>
  );
};
