import { useState, useEffect } from "react";
import { galleryCRUD } from "redux/slices/gallery";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ProfileEdit } from "./Edit";
import { ProfileShow } from "./Show";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { Params, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "services"

export const Agent = () => {
  const { id }: Readonly<Params<string>> = useParams();

  const dispatch = useAppDispatch();
  const [fetch, setFetch] = useState(false);


  const [user, setUser] = useState<any>([]);

  useEffect(() => {
    if (id) {
      axios.get(`${API_URL}/accounts/users/${id}/`)
        .then((res) => {
          setUser(res.data);
        });
    }
    dispatch(galleryCRUD.fetch())
    dispatch(descriptionsCRUD.fetch())
  }, []);

  const { gallery, descriptions, users } = useAppSelector(
    (state) => state
  );

  const [gallerList, setGalleryList] = useState(
    gallery.data.results.filter((i:any) =>
      i.user === id
    )
  );


  useEffect(() => {
    setGalleryList(
      gallery.data.results.filter((i:any) =>
        i.user === user.id
      )
    );
  }, [users, user, gallery.data.results, fetch]);

  const [isEdit, setIsEdit] = useState(false);

  const filterDescriptions = descriptions.data.results.filter((item: any) =>
    item.user === (user.length !== 0 ? user.id : null)
  );

  return (
    <div className="profile">
      {isEdit ? (
        <ProfileEdit
          setFetch={setFetch}
          fetch={fetch}
          profile={user}
          setUser={setUser}
          gallerList={gallerList}
          setIsEdit={setIsEdit}
          filterDescriptions={filterDescriptions}
        />
      ) : (
        <ProfileShow
          filterDescriptions={filterDescriptions}
          user={user}
          gallerList={gallerList}
          setIsEdit={setIsEdit}
        />
      )}
    </div>
  );
};





