import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import LogIn from "../../components/log-in/log-in";
import "./enter-page.css";
import arrow from 'assets/site-svgs/aroow_top.svg'


export const EnterPage = () => {
  const [activeIcon, setActiveIcon] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 350) {
        setActiveIcon(true);
      } else {
        setActiveIcon(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="enter-page">
        <LogIn />
        <Footer />

        {activeIcon ? (
          <div className="icon-arow" onClick={scrollUp}>
            <img src={arrow} alt="" />
          </div>
        ) : null}
      </div>
    </>
  );
};
