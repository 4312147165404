import React, { SetStateAction } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import X_close from '../../../../assets/svg/icons/x_close.svg';
import nextArrow from '../../../../assets/svg/icons/next-arrow-blue.svg';
import prevArrow from '../../../../assets/svg/icons/prev-arrow-blue.svg';
import { ROUTE } from '../../../../redux/types/RootType';
import { getNewID } from '../../../../utils/functions';
import { ModelBlock } from './ModelBlock';
import { EditModelBlock } from './EditModelBlock';

interface RouteProps {
  id: number;
  type: number;
  city: number;
}

interface Props {
  active: boolean;
  yet: boolean;
  setActive: React.Dispatch<SetStateAction<boolean>>;
  setYet: React.Dispatch<SetStateAction<boolean>>;
  routes: any[];
  setRoutes: any;
  readOnly: boolean;
  handleRoutesChange: any;
  item: any;
}

export const EditRoutesModal = ({
  routes,
  setRoutes,
  active,
  yet,
  setActive,
  setYet,
  readOnly,
  handleRoutesChange,
  item,
}: Props) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    unregister,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const work = () => {
    setYet(true);
    setActive(false);
  };

  // useEffect(() => {
  //     console.log('routes', routes);
  // }, [routes])

  const HanleAddRoute = (type: ROUTE) => {
    setRoutes([
      ...routes,
      {
        id: getNewID(routes),
        type,
        city: undefined,
      },
    ]);
  };

  const HanleDeleteRoute = (type: ROUTE) => {
    const routes_by_type = routes.filter((route) => route.type === type);
    const id = routes_by_type[routes_by_type.length - 1].id;
    setRoutes([...routes.filter((route) => route.id !== id)]);
    unregister(`city${id}`)    
  };

  const HanleChangeRoute = (id: number, city: any) => {

    console.log(id);

    setValue(`city${id}`, city);

    setRoutes([
      ...routes.map((route) => {

        if (route.id === id) {
          return {
            ...route,
            city_title : city.title,
            city: city.id,
          };
        }
        return route;
      }),
    ]);
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    work();
    handleRoutesChange(Object.entries(data).map((i : any) => i[1]));
  };


  return (
    <div className={`modal ${active ? 'active' : ''}`} onClick={() => setActive(false)}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
        <div className="popup_header">
          <div className="popup_header_text">Маршрут</div>
          <img onClick={() => setActive(false)} src={X_close} alt="" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal_blocks">
            <div className="modal_blocks-from">
              Туда
              <img src={nextArrow} alt="" />
            </div>
            <div className="modal_blocks-from">
              Обратно
              <img src={prevArrow} alt="" />
            </div>
          </div>
          <div className="modal_blocks">
            <EditModelBlock
              item={{ ...item }}
              routes={routes?.filter((route) => route.type === 1)}
              HanleAddRoute={HanleAddRoute}
              HanleDeleteRoute={HanleDeleteRoute}
              HanleChangeRoute={HanleChangeRoute}
              // icon={nextArrow}
              title=""
              type={1}
              control={control}
              errors={errors}
              setValue={setValue}
            />

            <EditModelBlock
              item={{ ...item }}
              routes={routes?.filter((route) => route.type === 2)}
              HanleAddRoute={HanleAddRoute}
              HanleDeleteRoute={HanleDeleteRoute}
              HanleChangeRoute={HanleChangeRoute}
              // icon={prevArrow}
              title=""
              type={2}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </div>

          {/* <div className="linear_modal"></div> */}

          <div className="footer_button">
            <div className="footer_button_end">
              <div className="cancel" onClick={() => setActive(false)}>Отменить</div>
              <button type="submit" className="save">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
