import './hadja.css'
import HadjaImage from '../../assets/imgs/image 23.png'
import { useTranslation } from 'react-i18next'

const Hadja = () => {
  const {t} = useTranslation()
  return (
    <div className='hadja'>
      <div className="container">
        <div className="hadja-wrapper">
          <div className="hadja__list">
            Умра (Umrah)
          </div>
          <div className="wrapper-center">
            <div className="hadja-wrapper__inside">
              <div className="hadja-inside__left">
                <img src={HadjaImage} alt="" />
              </div>
              <div className="hadja-inside__right">
                <div className="hadja-right__text">
                  {t('home.text19')}
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  )
}

export default Hadja
