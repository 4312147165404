import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { api, apiGalley, apiWithoutToken } from 'services'
import { ProfileState } from './types'

const URL = 'accounts'

const initialState: ProfileState = {
  loading: false,
  error: false,
  data: null
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItem: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setIstaData: (state, { payload }) => {
      state.data = {
        ...state.data,
        instagram: payload
      };
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
      state.data = null;
    },
    editItem: (state, { payload }) => {
      state.data = payload;
    }
  },
})

// export the actions
export const { setLoading, setItem, setIstaData, setError, editItem } = profileSlice.actions;


// // export the selector (".data" being same as in slices/index.js's "data: something")
// export const profileSelector = (state: { data: any }) => state.data;

// export the default reducer
export default profileSlice.reducer;


// fetch profile
export function fetchProfile(loadinfFn:any = undefined) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/profile/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        dispatch(setItem(response.data));
        if(loadinfFn){
          loadinfFn((prev:any) => !prev)
        }
      })
      .catch((er) => {
        dispatch(setError(er));
        if (er.response.status === 401) {
          localStorage.removeItem('token')
        }
        if(loadinfFn){
          loadinfFn((prev:any) => !prev)
        }
      });
  };
}

// edit profile
export function editProfile(data = {}, setIsEdit: any) {
  return async (dispatch: Dispatch) => {
    api.patch(`${URL}/profile/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
        setIsEdit(false)
      })
      .catch((er) => {
        dispatch(setError(er));
      });
  };
}


export function editProfileAvatar(data = {}, setError: any, loadingFn : any = "") {
  return async (dispatch: Dispatch) => {
    apiGalley.patch(`${URL}/profile/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError(er));
      });
  };
}


// login to profile
export function loginProfile(data = {}) {

  return async (dispatch: Dispatch) => {
    apiWithoutToken.post(`${URL}/login/`, data)
      .then((response) => {
        if (response.data.token) {
          dispatch(setItem(response.data));
          api.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;
          localStorage.setItem('token', response.data.token)
          window.location.reload()
        } else {
          dispatch(setError(response.data));
          console.log(response.data);
          toast.error('Нет такого пользователя или неверные данные!')
        }
      })
      .catch((er) => {
        dispatch(setError(er));
        if(er.response.data){
          toast.error(er.response.data.detail)
        }
      });
  };
}


// logout from profile
export function logoutProfile() {
  return async (dispatch: Dispatch) => {
    api.post(`${URL}/logout/`)
      .then((response) => {
        dispatch(setItem(null));
        localStorage.removeItem('token')
      })
      .catch((er) => {
        localStorage.removeItem('token')
        dispatch(setError(er));
      });
  };
}


// login to profile
export function getAccessToken(data: any) {
  return async (dispatch: Dispatch) => {
    var formData = new FormData();
    formData.append('redirect_uri', data.redirect_uri)
    formData.append('code', data.code)
    formData.append('user_id', data.user_id)
    
    api.post(`/instagram/make_access_token/`, formData)
      .then((response) => {
        dispatch(setIstaData(response.data));
        window.location.href = '/admin/profile'
      })
      .catch((er) => {
        dispatch(setError(er));
      });
  };
}


// fetch register
export function fetchRegister(data={}, setError: any) {
  setLoading()
  return async (dispatch: Dispatch) => {
    console.log(data);
    apiWithoutToken.post(`/${URL}/register/`, data)
      .then((response) => {
        dispatch(setItem(response.data));
        localStorage.setItem('profile', JSON.stringify(response.data))
      })
      .catch((name) => {
        console.log(name);
        if (name.response.status === 400){
          setError(name.response.data)
          console.log(setError, 'sdadssadsa');
          
        }
        
        dispatch(setError());
      });
  };
}