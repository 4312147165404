import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ResetCRUD } from 'redux/slices/reset';

import { editUser, fetchUsers } from 'redux/slices/users';
import { User } from './User';

export const Users = () => {
  const dispatch = useAppDispatch();

  const { users, reset } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(ResetCRUD.fetch());
  }, [reset.data.results.length]);

  const [category, setCategory] = useState(4);

  const [list, setList] = useState<any>(
    users.data.results.filter((item) => item.role === category && item.is_active === false) || [],
  );

  useEffect(() => {
    setList(users.data.results.filter((item) => item.role === category && item.is_active === false) || []);
  }, [users.data.results.length, reset.data.results.length]);

  const ConfirmUser = (id: any) => {
    dispatch(
      editUser(id, {
        is_active: true,
      }),
    );
  };

  const handleChangeTab = (tab: number) => {
    if (tab === 4 || tab === 3 || tab === 2) {
      setCategory(tab);
      setList(users.data.results.filter((item) => item.role === tab && item.is_active === false) || []);
    } else if (tab === 0) {
      setCategory(tab);
      setList(users.data.results.filter((item) => item.is_active === true) || []);
    }
  };

  return (
    <div className="Users">
      <div className="header">
        <div className="header__titles">
          <Link
          to='4'
            style={{display: 'flex'}}
            className={`title ${category === 4 ? 'active' : ''}`}
            onClick={() => handleChangeTab(4)}>
            Ажы<div>-Башы</div>
          </Link>
          <Link
          to='3'
            className={`title ${category === 3 ? 'active' : ''}`}
            onClick={() => handleChangeTab(3)}>
            Субагенты
          </Link>
          <Link
          to='2'
            className={`title ${category === 2 ? 'active' : ''}`}
            onClick={() => handleChangeTab(2)}>
            Сотрудник
          </Link>
          <Link
          to='recover'
          style={{display: 'flex', alignItems: 'center', gap: '7px'}}
            className={`title2 ${category === 0 ? 'active' : ''}`}
            onClick={() => handleChangeTab(0)}>
            Восстановление <div>аккаунта</div>
          </Link>
        </div>
      </div>

      <Outlet/>
    </div>
  );
};
