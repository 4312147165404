import React, { useEffect } from 'react';
import { Applications } from './pages/Applications';
import { Start } from './pages/Start';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { fetchUsers } from "../../../redux/slices/users";

export const Home = () => {
  const { users } = useAppSelector(state => state);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Applications />
  );
};