import React, { useState, useEffect } from "react";
import { useAppSelector } from 'redux/hooks'
import { ApplicationItems } from "pages/admin/Home/pages/ApplicationItems";

export const Hidden = () => {
  const { tours_all } = useAppSelector(state => state);
  const [filtered, setFiltered] = useState<any[]>([]);

  useEffect(() => {
    setFiltered(tours_all.results.filter((item: any) => item.is_archived === false && item.is_published === false && item.amount_of_people > item.registered_people).sort((a:any, b:any) => {
      return new Date(b.flight.date_from).getTime() - new Date(a.flight.date_from).getTime()
    }))
  }, [tours_all])

  return (
    <ApplicationItems loading={tours_all.loading} items={filtered} title="" />
  )
}