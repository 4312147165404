import { useState, useEffect } from 'react'
import { Params, useParams } from 'react-router-dom'

import arrow from 'assets/site-svgs/aroow_top.svg'

import HeaderMenu from 'components/Header/white-menu/header-menu'
import Modal from 'components/Modal/Modal'
import { ToursCRUD } from 'redux/slices/tours'
import Footer from 'components/Footer/Footer'
import './PrifleAjy.css'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { fetchUser } from 'redux/slices/users'
import { descriptionsCRUD } from 'redux/slices/descriptions'
import { galleryCRUD } from 'redux/slices/gallery'
// import { api, apiGalley, apiWithoutToken } from "services";
import { Aji } from "pages/admin/Agent/Aji";

export const AjiBashiProfile = () => {

  const [active, setActive] = useState(false)

  const dispatch = useAppDispatch()
  const { id }: any = useParams()

  const [user, setUser] = useState({
    first_name: '',
    experience: 0,
    phone: '',
    city: '',
    whatsapp: '',
    avatar: '',
    instagram_long_token: '',
    role: 0,
    id: 0
  })

  const [error, setError] = useState()

  useEffect(() => {
    dispatch(fetchUser(id, setUser, setError))
    dispatch(galleryCRUD.fetch())
    dispatch(descriptionsCRUD.fetch())
  }, [])

  const { gallery, descriptions } = useAppSelector(state => state)

  const [activeIcon, setActiveIcon] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        setActiveIcon(true)
      } else {
        setActiveIcon(false)
      }
    })
  }, [dispatch])

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  const filterDescriptions = descriptions.data.results.filter((i) =>
    i.user === id
  )

  const gallerList = gallery.data.results.filter((i) =>
    i.user === id
  )

  return (
    <div className="profileAji">
      <div className='PrifleAjy'>
        <div className="profile-ajy__header">
          <HeaderMenu active={active} setActive={setActive} />
          <Modal active={active} setActive={setActive} />
        </div>
        <div className="container">
          <Aji
            profile={user}
            isPublic={true}
          />
        </div>
        <Footer />
      </div>
      {
        activeIcon && <div className='icon-arow' onClick={scrollUp} >
          <img src={arrow} alt="" />
        </div>
      }
    </div>
  )
}
