import React, { useState } from "react";
import close_x from "assets/svg/icons/x_close.svg";
import screen from "assets/site-svgs/ajy_screen.svg";
import iks from "assets/site-svgs/fucked.svg";
import deleter from 'assets/delete-button.svg'

interface Props {
  item: any;
  deleteImg: Function;
}

export const GalleryItem = ({ item, deleteImg }: Props) => {
  const [modalActive, setModalActive] = useState(false);

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc: any) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <div className="gallery_modal">
      <div className={model ? "model open" : "model"}>
        <div className="appears">
          <img src={tempImgSrc} alt="" />
        </div>
        <div className="closed__gallery" onClick={() => setModel(false)}>
          <img src={iks} alt="" />
        </div>
      </div>
      <div className="pics" key={item.id} >
        <div>
          <img className="item__image__gallery" src={item.image} alt="" />
          <div className="delete-hover__image">
            <button onClick={(e:any) =>{e.preventDefault();deleteImg(item.id)}}>Удалить</button>
          </div>
        </div>

        <div className="overlay">
          <div className="hover" onClick={() => getImg(item.image)}>
            <h4>
              <img src={screen} alt="" /> на весь экран
            </h4>
          </div>
          <div style={{ padding: "10px" }} className="gallery__close">
            <img
              className="gallery__block"
              src={deleter}
              alt=""
              onClick={() => deleteImg(item.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
