import React, { SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { editProfile, fetchProfile } from "redux/slices/profile";
import { editUser, fetchUsers } from "redux/slices/users";
import { isUndefined } from "util";
import close_x from "../../../assets/svg/icons/x_close.svg";

interface Props {
  modalActive: boolean;
  setModalActive: React.Dispatch<SetStateAction<boolean>>;
  profile: any;
  renderUsers: any;
  setNumber: any;
  number: any;
  SendIncome: any;
  incomes: any;
}

export const ProfileAjiModal = ({
  modalActive,
  setModalActive,
  SendIncome,
  incomes
}: Props) => {
  
  return (
    <div
      className={modalActive ? "profileAji__modal" : "profileAji__modal--none"}
      onClick={() => setModalActive(false)}
    >
      <div
        className="profileAji__modal__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal__header">
          <div className="modal__header_text">Категория наценок</div>
          <img onClick={() => setModalActive(false)} src={close_x} alt="" />
        </div>

        <div className="profileAji__categories">
          <div className="profileAji__categories__item">
            <form className="radio">
              <div className="radio_items">
                {
                  incomes.map((item: any) =>  <label
                  key={item.id}
                  htmlFor="gender3"
                  className="radio_item"
                  onClick={() => SendIncome(item)}
                >
                  <div className="radio_number">{item.id}</div>
                 <div className="radio_twice">
                 <div className="radio_text">Категория наценок</div>
                  <div className="radio_price">{item.price}$</div>
                 </div>
                </label>)
                }       
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
