import React, {useState, useEffect} from "react";
import { useAppSelector } from 'redux/hooks'

import { ApplicationItems } from "pages/admin/Home/pages/ApplicationItems";

export const ComfirmedFlights = () => {
  const { tours_all } = useAppSelector(state => state);

  const [filtered, setFiltered] = useState<any[]>([]);

  useEffect(() => {
    setFiltered(tours_all.results.filter((item: any) => item.is_published === true && item.flight !== null && item.amount_of_people > item.registered_people))
  }, [tours_all])

  console.log(filtered[0]);
  
  
  return (
    <div className="ComfirmedFlights">
      <ApplicationItems loading={tours_all.loading} items={filtered} title="" />
    </div>
  );
};
