import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { NCToursCRUD } from "redux/slices/not_confirmed_tours";

interface Props {
  item: any;
  index: number;
}

export const RequestItem = ({ item, index }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    profile
  } = useAppSelector((state) => state);

  const {
    id,
    user,
    date,
    amount_of_people,
    hajhead_duty,
    hajhead_registered,
    firm_duty,
    firm_registered,
    registered_people,
    extra_registered,
    free_places,
    amount_of_days,
    category,
    desired_hotels,
    calculation,
    flight,
    airline,
  } = item;

  const [place, setPlace] = useState({
    amount_of_people,
    hajhead_duty,
    hajhead_registered,
    firm_duty,
    firm_registered,
    registered_people,
    extra_registered,
    free_places,
  });

  useEffect(() => {
    const registered_people =
      +place.hajhead_registered + +place.firm_registered;
    const free_places =
      +place.amount_of_people -
      (+place.hajhead_registered + +place.firm_registered);
    const extra_registered = +place.amount_of_people - registered_people;
    setPlace({
      ...place,
      registered_people: registered_people,
      free_places: free_places < 0 ? 0 : free_places,
      extra_registered: Math.abs(extra_registered),
    });
  }, [place.hajhead_duty, place.hajhead_registered, place.firm_registered]);

  const editAction = {
    value: "1",
    label: "Редактировать",
    onclick: () => {
      navigate(`/admin/nct/${id}/edit`);
    },
  };

  const submitAction = {
    value: "1",
    label: "Потвердить",
    onclick: () => {
      navigate(`/admin/nct/${id}`);
    },
  };
  
  const rejectAction = {
    value: "2",
    label: "Отклонить",
    onclick: () => {
      dispatch(NCToursCRUD.remove(id));
    },
  };

  const navigateToProfile = (e:any) =>{
    e.preventDefault()
    if(profile.data.id === user.id){
      navigate(`/admin/profile/`)
    }else{
      navigate(`/admin/agents/${user.id}`)
    }
  }


  const actions = [
    editAction,
    !(profile.data.role === 4 || profile.data.role === 3) ? submitAction : null,
    rejectAction].filter(Boolean);

  return (
    <tr>
      <td className="info_id">
        <div >{index + 1} / {id}</div>
      </td>
      <td>
        <a onClick={(e) =>{navigateToProfile(e)}}>{user.first_name}<br/> {user.last_name}</a>
        <div className="info_describe-text__flex">
          <label>Kоличество дней: </label>
          <div>{amount_of_days}</div>
        </div>
      </td>
      <td className="info_describe-text__none">
        <div >
          <label className="info_describe-text__flex">Дней: </label>
          <div>{amount_of_days}</div>
        </div>
      </td>
      <td>
        <label className="info_describe-text">Желаемая дата: </label>
        <div>{moment(date).format('L')}</div>

      </td>
      <td>
        <label className="info_describe-text">Авиакомпания: </label>
        <div>{airline ? airline.title : flight?.airline_category_title}</div>
      </td>
      <td>
        <div>
          {desired_hotels.map((hotel: any, i: number) => (
            <div key={hotel.id}>
              <div>
                <span className="blacktext">{hotel.hotel.city_title}:</span>{" "}
                {hotel.hotel.title}
                <span className="blacktext"> - {hotel.amount_of_days}</span>
              </div>
            </div>
          ))}
        </div>
      </td>
      <td>
        <label className="info_describe-text">Сумма: </label>
        <div>{+calculation?.toFixed(2)}$</div>
      </td>
      <td>
          <label className="info_describe-text">Места: </label>
          <div>{amount_of_people}</div>
      </td>
      <td>
        <div className="SelectCenter">
          <Select
            placeholder="Действие"
            options={actions}
            value={null}
            styles={{
              singleValue: (provided) => ({
                ...provided,
                color: '#fff'
              }),

              control: (provided, state) => ({
                ...provided,
                width: "130px",
                backgroundColor: "#125D98",
                border: "none",
                color: "#fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                },
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#fff",
              }),
            }}
            onChange={(e: any) => e.onclick()}
          />
        </div>
      </td>
    </tr>
  );
};
