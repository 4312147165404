import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { Items } from "pages/admin/ControlPanelPages/Items";
import { fetchFlights } from "../../../../redux/slices/flights";
import { NCToursCRUD } from 'redux/slices/not_confirmed_tours';
import { ToursAllCRUD } from 'redux/slices/tours_all';
import { EMode, EType } from 'pages/admin/Tour/CRUD'
import { UsersAllCRUD } from "redux/slices/users_all";


export const Applications = () => {
  // set up dispatch
  const dispatch = useAppDispatch();
  // hook to fetch items


  // fetch data from our store
  const { flights, users_all, not_confirmed_tours, profile, tours_all } = useAppSelector(
    (state) => state
  );
  // console.log(tours_all);

  useEffect(() => {
    dispatch(fetchFlights());
    dispatch(NCToursCRUD.fetch());
    dispatch(ToursAllCRUD.fetch())
    dispatch(UsersAllCRUD.fetchAll());
  }, [dispatch]);

  // const closed = tours_all.results.filter((item: any) => item.amount_of_people <= item.registered_people).length;
  // console.log('users_all', users_all.results.map(u => u.role));

  return (
    <div className="application">
      <div className="application__form">
        {(profile?.data?.role !== 4 && profile?.data?.role !== 3) && <Items items={not_confirmed_tours.results} title="Заявки" />}
        {(profile?.data?.role === 4 || profile?.data?.role === 3) && <Items
          items={not_confirmed_tours.results.filter(
            (item) => item.user.id === profile.data.id
          )}
          title="Мои заявки"
        />}
        {(profile?.data?.role !== 4 && profile?.data?.role !== 3) && <div className="info">
          <div className="info_users">
            <div className="info_block1">
              <div className="title">Панель управление</div>
              <table>
                <thead>
                  <tr>
                    <td>Заявки</td>
                    <td>Рейсы</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="info_number">
                      <label className="info_describe-text">Заявки: </label>
                      {not_confirmed_tours.results.length}
                    </td>
                    <td className="info_number">
                      <label className="info_describe-text">Рейсы: </label>
                      {flights.data.results.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="info_block2">
              <div className="title">Все сотрудники</div>
              <table>
                <thead>
                  <tr>
                    <td>Ажы-Баши</td>
                    <td>Суб Агент</td>
                    <td>Сотрудник</td>
                    <td>Админы</td>
                    <td>Директоры</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="info_number">
                      <label className="info_describe-text">Ажы-Баши: </label>
                      {users_all.results.filter((item) => +item.role === 4).length}
                    </td>
                    <td className="info_number">
                      <label className="info_describe-text">СубАгент: </label>
                      {users_all.results.filter((item) => +item.role === 3).length}
                    </td>
                    <td className="info_number">
                      <label className="info_describe-text">Сотрудникы: </label>
                      {users_all.results.filter((item) => +item.role === 2).length}
                    </td>
                    <td className="info_number">
                      <label className="info_describe-text">Админы: </label>
                      {users_all.results.filter((item) => +item.role === 1).length}
                    </td>
                    <td className="info_number">
                      <label className="info_describe-text">Директоры: </label>
                      {users_all.results.filter((item) => +item.role === 5).length}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button className="">
              <Link to="/admin/control-panel">Панель управление</Link>
            </button>
          </div>
        </div>}
      </div>
    </div>
  );
};
