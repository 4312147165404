import { useState, useEffect } from "react";
import { galleryCRUD } from "redux/slices/gallery";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ProfileEdit } from "./Edit";
import { ProfileShow } from "./Show";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { fetchProfile } from "redux/slices/profile";


export const Profile = () => {
  const dispatch = useAppDispatch();
  const [fetch, setFetch] = useState(false);

  useEffect(() => {
    dispatch(galleryCRUD.fetch(1, false))
    dispatch(descriptionsCRUD.fetch())
    dispatch(fetchProfile())
  }, []);

  const { profile, gallery, descriptions, users } = useAppSelector(
    (state) => state
  );

  const [galleryList, setGalleryList] = useState(
    gallery.data.results.filter((i) =>
      i.user === profile.data.id
    )
  );


  useEffect(() => {
    setGalleryList(
      gallery.data.results.filter((i) =>
        i.user === profile.data.id
      )
    );
  }, [users, profile.data.id, gallery.data.results, fetch]);

  const [isEdit, setIsEdit] = useState(false);

  const filterDescriptions = descriptions.data.results.filter((item: any) =>
    item.user === profile.data.id
  );


  return (
    <div className="profile">
      {isEdit ? (
        <ProfileEdit
          setFetch={setFetch}
          fetch={fetch}
          role={profile.data.role}
          profile={profile}
          gallerList={galleryList}
          setIsEdit={setIsEdit}
          filterDescriptions={filterDescriptions}
        />
      ) : (
        <ProfileShow
          filterDescriptions={filterDescriptions}
          role={profile.data.role}
          profile={profile}
          gallerList={galleryList}
          setIsEdit={setIsEdit}
        />
      )}
    </div>
  );
};





