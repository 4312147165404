import react, { useEffect } from 'react'
import lightFrame from 'assets/imgs/Frame 5421.png'
import flighter from 'assets/site-svgs/flight_to.svg'
import flight_of from 'assets/site-svgs/flight_of.svg'
import { useAppDispatch } from 'redux/hooks'
import { useParams } from 'react-router-dom'
// import { fetchHotels } from 'redux/slices/hotels'
// import { ToursCRUD } from 'redux/slices/tours'
// import { fetchFlights } from 'redux/slices/flights'

interface Props {
    flight: any
}

export const FlightTime = ({ flight }: Props) => {
  
   


    
    const toflight = flight?.routes.filter((route: any) =>
        route.type === 1) ?? []

    const fromFlight = flight?.routes.filter((route: any) => route.type === 2) ?? []

    

    return (
        <div className="flight-times__left">
            <div className="first-flight">
                <div className='city'>{toflight[0]  ? toflight[0].city_title : null}</div>
                {toflight[1] ?
                    <>
                        <img src={flighter} alt="" />
                        <img className='flight_line' src={lightFrame} alt="" />
                        <img src={flight_of} alt="" />
                        <div className='city'>{toflight[1] ? toflight[1].city_title : null}</div>
                    </>

                    : null
                }
                {toflight[2] ?
                    <div className='without__div'>
                        <img src={flighter} alt="" />
                        <img className='flight_line' src={lightFrame} alt="" />
                        <img src={flight_of} alt="" />

                        <div className='city'>{toflight[2] ? toflight[2].city_title : null}</div>
                    </div> : null
                }


            </div>

            <div className="first-flight">
                <div className='city'>{fromFlight[0]  ? fromFlight[0].city_title : null}</div>
                {fromFlight[1] ?
                    <div className='without__div'>
                        <img src={flighter} alt="" />
                        <img className='flight_line' src={lightFrame} alt="" />
                        <img src={flight_of} alt="" />
                        <div className='city'>{fromFlight[1] ? fromFlight[1].city_title : null}</div>
                        <img src={flighter} alt="" />
                    </div> : null
                }
                {fromFlight[2] ?
                    <div className='without__div'>
                        <img className='flight_line' src={lightFrame} alt="" />
                        <img src={flight_of} alt="" />
                        <div className='city'>{fromFlight[2] ? fromFlight[2].city_title : null}</div>
                    </div> : null
                }

            </div>

        </div>
    )

}