import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { VisasState } from './types'

const URL = 'visa'

const initialState: VisasState = {
  loading: false,
  error: false,
  price: 0,
}

export const visaSlice = createSlice({
  name: 'visa',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.price = payload.price;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.price = payload.price;
    },
    editItem: (state, {payload }) => {
      state.price = payload.price;
    },
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem } = visaSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const visaSelector = (state: { items: any }) => state.items;

// export the default reducer
export default visaSlice.reducer;


// fetch all visa
export function fetchVisas() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Visa
export function saveVisa(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
        toast.error(er)
      });
  };
}

// add new Visa
export function editVisa(id: number, data={}) {
  return async (dispatch: Dispatch) => {
    api.put(`${URL}/${id}`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}