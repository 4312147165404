import React, { useState} from 'react'

import Mecca from "assets/imgs/image 13.png";
import  Modal  from "components/Modal/Modal";
import Footer from "components/Footer/Footer";
import HeaderMenu from "components/Header/header-menu/header-menu";



export const RegisterConfirm =  () =>{


  const [active, setActive] = useState(false)


    return(
      <div className="enter-page">
         <HeaderMenu active={active} setActive={setActive}/>
         <Modal active={active} setActive={setActive}/>
        <div className="log-in">
        <div className="container">
          <div className="log-in__wrapper">
            <div className="wrapper-log__inside">
              <div className="mecca__image-enter">
                    <img src={Mecca} alt="" />
                  </div>
                  <div className="main__confirmed">
                    <div className="main__to-flex">

                  <div className="confirmed">
                  Ваш запрос принят, в ближаещее время наши сотрудники просмотрят и активируют ваш аккаунт
                  </div>

                  <div className="travel_zam-zam">
                  С уважением <b> ZamZam travrel</b>
                  </div>
                  </div>
          
                  </div>
              
                  </div>
          </div>
        </div>
      </div>
      <Footer />
      </div>

    )
}