import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api, apiWithoutToken } from 'services'

import { IPaginationState } from 'redux/types/RootState'

const URL = 'accounts/gallery'

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
}

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setItemsWithNoPag : (state, {payload}) => {
      state.loading = false;
      state.error = false;
      state.data.count = 0
      state.data.next = ''
      state.data.previous = ''
      state.data.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload}) => {
      state.data.results.push(payload);
      
    },
    deleteItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, deleteItem,setItemsWithNoPag } = gallerySlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const gallerySelector = (state: { items: any }) => state.items;

// export the default reducer
export default gallerySlice.reducer;


/** 
 * Метод для получения данных из API
 * */
function fetch(page=1, use_pagination=false) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/?page=${page}&use_pagination=${use_pagination}`)
      .then((response) => {
        if(use_pagination){ 
          dispatch(setItems(response.data));
        }else{
          dispatch(setItemsWithNoPag(response.data));
        }
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления тура
 * */
function create(data: any, setLoading:any = undefined) {
  return async (dispatch: Dispatch) => {
    let formData = new FormData()
    formData.append('image', data.image)
    formData.append('user', data.user)
    api.post(`/${URL}/`, formData)
      .then((response) => {
        dispatch(addItem(response.data));
        if(setLoading){
          setLoading(false)
        }
      })
      .catch((er) => {
        dispatch(setError());
        if(setLoading){
          setLoading(false)
        }
      });
  };
}

/** 
 * Метод для добавления нового тура 
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`${URL}/${id}/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const galleryCRUD = {
  fetch,
  create,
  remove
}