import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { editHotel, fetchHotels } from "redux/slices/hotels";
import Select from 'react-select'
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchCities } from "redux/slices/cities";
import { fetchRoomCategory } from "redux/slices/room_category";

import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { API_URL } from "services"



export const EditHotel = () => {
    let navigate = useNavigate()
    const dispatch = useAppDispatch();

    useEffect(() => {
        axios.get(`${API_URL}/hotels/${id}/`).then((res) => {
            setHotel(res.data);
        }).catch(error => {
            if(error.response && error.response.status === 404){
                navigate('*')
              }
        })
        dispatch(fetchCities())
        dispatch(fetchRoomCategory())
    }, []);
    const { id } = useParams();

    const [hotel, setHotel] = useState<any>([]);


    const { room_category, cities } = useAppSelector((state) => state);

    const [valued, setValued] = useState('');
    const {
        reset,
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            width: 200,
            border: "none",
            borderBottom: "1px solid #474665",
            borderRadius: 0,
            boxShadow: "none",
        }),
    };

    // const [editable, setEditable] = useState(true);
    const [appear, setAppear] = useState(true);



    const onSubmit = (data: any,) => {
        dispatch(editHotel(hotel.id,{
            city : data.city.id,
            places : data.places,
            price: data.price,
            link: data.link,
            room_category: data.room_category.id,
            title: data.title,
            description: data.emailContent
        }))
        navigate('/admin/directory/hotel/')
    };


    const onEditorStateChange = (editorState: any) => {

        setValue("emailContent", editorState);

    };
    const editorContent = watch("emailContent");

    const handleCityChange = (newValue: any) => {
        setValue("city", newValue);
    };

    const handleRoomChange = (newValue: any) => {
        setValue("room_category", newValue);
    };


    useEffect(() => {
        setValue("city", { id: hotel?.city, title: hotel?.city_title, value: hotel?.city, label: hotel?.city_title });
        setValue("price", hotel?.price);
        setValue("places", hotel?.places);
        setValue("title", hotel?.title);
        setValue("link", hotel?.link);
        setValue("description", hotel?.description);
        setValue("room_category", { id: hotel?.room_category, title: hotel?.room_category_title, value: hotel?.room_category, label: hotel?.room_category_title });
    }, [hotel]);    

    return (
        <div className="active-bc edit_hotel">
            <Link to='/admin/directory/hotel' className="back">
                Назад
            </Link>

            <div className="first__trhree-mains">
                <div className="the__main-title">
                    <div>
                        <div className="label__mains">Название гостиницы</div>
                        <form>
                            <input
                                type="text"
                                {...register("title")}
                            />
                        </form>
                    </div>
                </div>
                <div className="the__main-title">
                    <div className="label__mains">Город</div>
                    <div className={`form--item ${errors?.city && "error"}`}>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isDisabled={cities.loading}
                                    isLoading={cities.loading}
                                    options={cities.items.filter(i => i.isHasHotel)}
                                    styles={customStyles}
                                    onChange={handleCityChange}
                                />
                            )}
                        />
                        {errors?.city && <div className="error_text">Выберите город</div>}
                    </div>

                </div>
                <div className="the__main-title">
                    <div className={`form--item ${errors?.room_category && "error"}`}>
                        <div className="labels__block">
                            <div className="label__mains"> Категория номера</div>
                            <Controller
                                name="room_category"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isDisabled={room_category.loading}
                                        isLoading={room_category.loading}
                                        options={room_category.items}
                                        styles={customStyles}
                                        onChange={handleRoomChange}

                                    />
                                )}
                            />
                            {errors?.room_category && (
                                <div className="error_text">Выберите категорию номера</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="first__two-mains">
                <div className="the__main-title">
                    <div>
                        <div className="label__mains">Мест в одном номере</div>

                        <form>
                            <input
                                type="text"
                                {...register("places")}
                            />
                        </form>
                    </div>
                </div>
                <div className="the__main-title">
                    <div>
                        <div className="label__mains">Ссылка на сайт</div>

                        <form>
                            <input
                                type="url"
                                {...register("link")}
                            />
                        </form>
                    </div>
                </div>
                <div className="the__main-title">
                    <div>
                        <div className="label__mains">Цена ($)</div>
                        <form>
                            <input
                                type="text"
                                {...register("price")}
                            />
                        </form>
                    </div>
                </div>
            </div>

            <div className="description__mains">

                <div className="the__main-title">
                    <div style={{ width: '55%' }}>
                        <div className="label__mains">Описание</div>
                        <form>
                            <ReactQuill theme="snow" value={editorContent} onChange={onEditorStateChange} />
                        </form>
                    </div>

                </div>
            </div>
            <div className="actions__save-btn">
                    <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <button type="submit">Сохранить</button>
                    </form>

                    </div>
            </div>
        </div>
    )
}