import React from 'react'

const Feed = (props) => {
    const { id, caption, media_type, media_url, thumbnail_url, permalink } = props.feed
    let post;

    console.log('props', props);

    switch (media_type) {
        // case "VIDEO":
        //     post = (
        //         <video
        //             width='100%'
        //             height='auto' 
        //             src={media_url} 
        //             type="video/mp4" 
        //             controls playsinline>
        //         </video>
        //     )
        //     break;
        case "VIDEO":
            post = (<a href={permalink} target='_blank'>
                <img
                    className='insta'
                    id={id}
                    src={thumbnail_url}
                    alt={caption}
                /></a>
            )
            break;
        case "IMAGE":
            post = (<a href={permalink} target='_blank'>
                <img
                    className='insta'
                    id={id}
                    src={media_url}
                    alt={caption}
                /></a>
            )
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <a href={permalink} target='_blank'>
                    <img
                        className='insta'
                        id={id}
                        src={media_url}
                        alt={caption}
                    /></a>
            );
            break;
        default:
            post = (
                <a href={permalink} target='_blank'>
                    <img
                        className='insta'
                        id={id}
                        src={thumbnail_url}
                        alt={caption}
                    /></a>
            );
    }

    return (
        <React.Fragment>
            {post}
        </React.Fragment>
    );
}

export default Feed;