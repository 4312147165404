import React from 'react'
import { NavLink } from 'react-router-dom'
import { getToken } from 'utils/auth'
import logout from 'assets/site-svgs/sgl.svg'
import about from 'assets/site-svgs/aboutus.svg'
import bacha from 'assets/site-svgs/bacha.svg'
import conc from 'assets/site-svgs/conc.svg'
import ui from 'assets/site-svgs/uium.svg'


import './modal.css'
import { useTranslation } from 'react-i18next'

interface Activity {
  active: any,
  setActive: any,
}




const Modal = ({ active, setActive }: Activity) => {
  const { t, i18n } = useTranslation()
  return (
    <div onClick={() => setActive(false)} className={active ? 'modalka' : 'modal_none'}>
      <div className='modal_content__users'>
        <div className="blur" />
        <div className="menu-content__users">
          <ul>
            <NavLink end to='/' > <img src={ui} alt="" /> <li>{t('navbar.word1')}</li>  </NavLink>
            <NavLink end to='/about'> <img src={about} alt="" /> <li>{t('navbar.word2')}</li> </NavLink>
            <NavLink end to='/ajy-bashy'><img src={bacha} alt="" /> <li>{t('navbar.word5')} </li> </NavLink>
            <NavLink end to='/contact'><img src={conc} alt="" /> <li>{t('navbar.word3')}</li> </NavLink>
          </ul>
          <div className="toggle-button-cover">
            <div className="button-cover">
              <div className="button r" id="button-3">
                <input
                  checked={i18n.resolvedLanguage === 'ru' ? false : true}
                  onChange={(e) => e.target.checked ? i18n.changeLanguage('ky') : i18n.changeLanguage('ru')}
                  type="checkbox" className="checkbox"
                />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
          <div className="modal-content__come-in">
            <NavLink end to='/admin'>
              {getToken() ? 'Админ панель' : t('navbar.word4')}
              <img src={logout} alt="" />
            </NavLink>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
