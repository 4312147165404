import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { api, apiGalley, apiWithoutToken } from "services";

import { ResultsState } from 'redux/types/RootState'

const URL = "accounts/users";

const initialState: ResultsState = {
  loading: false,
  error: false,
  results: [],
}

export const usersAllSlice = createSlice({
  name: "users_all",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.results = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.results.push(payload);
    },
    editItem: (state, { payload }) => {
      state.results = state.results.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      });
    },
    deleteItem: (state, { payload }) => {
      state.results = state.results.filter((item) => item.id !== payload);
    },
  },
});

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } =
usersAllSlice.actions;

// // export the selector (".data.results" being same as in slices/index.js's "data.results: something")
// export const usersSelector = (state: { data.results: any }) => state.data.results;

// export the default reducer
export default usersAllSlice.reducer;

// fetch all users
function fetchAll() {
  setLoading();
  return async (dispatch: Dispatch) => {
    apiWithoutToken
      .get(`/${URL}/?use_pagination=false&ordering=first_name`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// fetch user
function fetch(id: number, handleData: any, handleError: any) {
  return async () => {
    apiWithoutToken
      .get(`/${URL}/${id}/`)
      .then((response) => {
        handleData(response.data)
      })
      .catch((er) => {
        handleError(er)
      });
  };
}

// add new User
export function create(data = {}) {
  return async (dispatch: Dispatch) => {
    api
      .post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// edit User
function patch(id: number, data = {}) {
  return async (dispatch: Dispatch) => {
    api
      .patch(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/**
 * Метод для добавления гостиницы
 * */
function remove(id: number) {
  return async (dispatch: Dispatch) => {
    api
      .delete(`${URL}/${id}/`)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export const UsersAllCRUD = {
  fetchAll,
  fetch,
  create,
  patch,
  remove
}
