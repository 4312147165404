import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { IPaginationState } from 'redux/types/RootState'
import { toast } from 'react-toastify';

const URL = 'additional_services'

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
}

export const additionalServicesSlice = createSlice({
  name: 'AdditionalServices',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.data.results.push(payload);
    },
    editItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            payload
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = additionalServicesSlice.actions;


// // export the selector (".data.results" being same as in slices/index.js's "data.results: something")
// export const AdditionalServicesSelector = (state: { data.results: any }) => state.data.results;

// export the default reducer
export default additionalServicesSlice.reducer;


// fetch all AdditionalServices
export function fetchAdditionalServices(page=1) {
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?page=${page}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new AdditionalService
export function saveAdditionalService(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        console.log(er);
        
        dispatch(setError());
      });
  };
}

// add new AdditionalService
export function editAdditionalService(id: number, data={}) {
  return async (dispatch: Dispatch) => {
    api.put(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления гостиницы 
 * */
export function deleteAdditionalService(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`/${URL}/${id}/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        if(er.response.status === 423){
          toast.error('Было создано тур с помощью этой доп.услуги !')
          return;
        }
        dispatch(setError());
      });
  };
}