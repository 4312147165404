import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { AvailableAirlinesCitiesState } from './types'

const initialState: AvailableAirlinesCitiesState = {
  loading: false,
  error: false,
  data: undefined,
}

export const availableAirlinesCitiesSlice = createSlice({
  name: 'available_airlines_cities',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload
    },
    setError: (state) => {
      state.error = true;
    }
  },
})

// export the actions
export const { setLoading, setItems, setError } = availableAirlinesCitiesSlice.actions;

// export the default reducer
export default availableAirlinesCitiesSlice.reducer;


// fetch all availableAirlinesCities
export function fetchAvailableAirlinesCities() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get("/additional_urls/available_airlines_cities/")
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}