
import { useAppSelector } from "redux/hooks";

export function useIsAgent() {
    const {profile} = useAppSelector((state: any) => state);    
    return (+profile.data.role === 3 || +profile.data.role === 4 )
}

export function useIsAdmin() {
    const {profile} = useAppSelector((state: any) => state);    
    return (+profile.data.role === 1 || +profile.data.role === 5 )
}