import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import defaultImg from 'assets/imgs/user_default.svg'

import './AjyBashyBox.css'
import { useTranslation } from 'react-i18next'

interface Props {
  item: any
}


const AjyBashyBox = ({
  item
}: Props ) => {

  const {t} = useTranslation()
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/profile/${item.id}`)
  }
  
  return (
    <div className="profile2">
      <div className="profile__Image">
        {
          item.avatar?
          <img src={item.avatar} alt="" className='avatar-box' />
          :
          <img src={defaultImg} alt="" className='avatar-box' />

        }
      </div>
      <h2>{item.first_name}</h2>
      <p>Ажы-Баши</p>
      <div className="look-profile" onClick={handleClick}>
        <button>
          {t('ajy-head.word1')}
        </button>
      </div>

    </div>
  )
}

export default AjyBashyBox
