
import React, { useState } from 'react'
import hoteler from 'assets/imgs/Hotel1.png'

import { DefaultHotel } from './DefaultHotel'
import { useTranslation } from 'react-i18next'

interface Props {
    hotel:any
}



const AboutHotels = ({
    hotel
} : Props) => {
    const [isClose, setIsClose] = useState(false)
    const {t} = useTranslation()
    const toggleBtn = () => {
        setIsClose(prevState => !prevState)
    }
    

    return (

        <div className="left__hotel">
            <div className="right__hotel-img">
                {
                    hotel.hotel.image ?
                        <img src={hotel.hotel.image} alt="" />
                        : 
                        null
                }
            </div>
            <div className="hotel-lists">
                <div className="hotel__name">
                    <h1>{t('book.word6')}  "{hotel.hotel.title}"</h1>
                </div>
                <div className="hotel__description-book">
                    {
                        hotel?.hotel?.description?
                            isClose &&  <div dangerouslySetInnerHTML={{__html: hotel?.hotel?.description}} /> ?
                            <div dangerouslySetInnerHTML={{__html: hotel?.hotel?.description}} />
                              : <div dangerouslySetInnerHTML={{__html: hotel?.hotel?.description?.substr(0, 500)}} />
                        :null
                    }
                </div>
                {
                    hotel?.hotel?.description ?
                     <div className="close__btn-li">
                    {
                        hotel?.hotel?.description && hotel.hotel.description.length < 500 ? null :
                        <button onClick={toggleBtn}>
                        {
                           isClose ? 'Скрыть' : 'Подробнее'
                        }
                    </button>
                    }
                </div>
                : null
                }
               
            </div>
        </div>
    )
}

export default AboutHotels



