import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { ExcursionsState } from './types'
import { toast } from 'react-toastify';

const URL = 'excursions'

const initialState: ExcursionsState = {
  loading: false,
  error: false,
  items: [],
}

export const excursionsSlice = createSlice({
  name: 'excursions',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload.map((item: any) => ({
        ...item,
        title: item.place_title
      }));
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        ...payload,
        title: payload.place_title,
      });
    },
    editItem: (state, {payload }) => {
      state.loading = false
      state.items = state.items.filter(item => {
        if(item.id !== payload.id){
          return {
            ...item,
            payload,
            title: payload.place_title,
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.items = state.items.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = excursionsSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const excursionsSelector = (state: { items: any }) => state.items;

// export the default reducer
export default excursionsSlice.reducer;


// fetch all excursions
export function fetchExcursions() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get(`/${URL}/?use_pagination=false`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Excursion
export function saveExcursion(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Excursion
export function editExcursion(id: number, data={}) {  
  return async (dispatch: Dispatch) => {
    api.put(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        console.log(er);
        
        dispatch(setError());
      });
  };
}

/** 
 * Метод для добавления гостиницы 
 * */
export function deleteExcursion(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`/${URL}/${id}/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        console.log(er.response);
        if(er.response.status === 423){
          toast.error('Было создано тур с помощью этой экскурсии!')
          return;
        }
        dispatch(setError());
      });
  };
}