import { Sidebar, Header } from '../components/admin';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';
import { Login } from '../pages/admin';
import { fetchProfile } from 'redux/slices/profile';
import { useAppDispatch } from 'redux/hooks';
import { useEffect, useState } from 'react';
import { getToken } from 'utils/auth'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


const MainLayout = () => {
  const { profile } = useAppSelector(state => state);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getToken()) {
      dispatch(fetchProfile());
    }
  }, [dispatch]);

  if (!getToken()) {
    return <Login />;
  }

  return (profile.data &&
    <div className="wrapper">
      <Sidebar />
      <div className="main-container">
        <Header />
        <div className="page">
          <Outlet />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default MainLayout;
