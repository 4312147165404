import { useState, useEffect } from "react";
import { useAppSelector } from 'redux/hooks';
import { PLASES, ROUTES } from 'redux/constants'


export function usePermissions(place: string) {
    const profile = useAppSelector((state: any) => state.profile.data);
    const [hasAccess, setHasAccess] = useState(false);
    const [scopes, setScopes] = useState<string[]>([]);

    useEffect(() => {
        if (profile) {
            const { role } = profile;
            const roles = PLASES[place as keyof typeof PLASES]
            if (roles) {
                const roleScopes = roles[role as keyof typeof roles] || [];
                setScopes(roleScopes || []);
                setHasAccess(roleScopes.length > 0);
            } else {
                setHasAccess(false);
            }
        }
    }
        , [profile, place]);

    const can = (scope: string) => scopes.includes(scope);

    return {
        hasAccess,
        can
    };
}

export function PermissionsGate({ place, children }: any) {
    const { hasAccess } = usePermissions(place);
    return hasAccess ? children : null;
}

export function useRoutePermissions(route: string) {
    const profile = useAppSelector((state: any) => state.profile.data);
    if (profile) {
        const { role } = profile;
        const roles = ROUTES[route as keyof typeof ROUTES];
        if (roles === undefined) {
            return true;
        }
        const roleScopes = roles[role as keyof typeof roles];
        if (roleScopes === undefined) {
            window.location.href = '/admin';
        } else {
            return false;
        }
    } else {
        return false;
    }
}