import { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { fetchCities} from 'redux/slices/cities';

import bin from "../../../../assets/svg/icons/bin.svg";
import change_blue_svg from "assets/svg/icons/change_blue_svg.svg";
import change_white_svg from "../../../../assets/svg/icons/change_white_svg.svg";
import checked_svg from "assets/svg/icons/checked.svg";
import closed_svg from "assets/svg/icons/close.svg";

import { } from 'redux/slices/cities';
import { useAppDispatch } from "redux/hooks";
import { editCities } from "redux/slices/cities";


interface Props {
  index : number
  item : any
  removeCities : Function
  isLoading : boolean
  setIsLoading : Function
}
export const List = ({
    index,
    item,
    removeCities,
    isLoading,
    setIsLoading
}:Props) => {
    const dispatch = useAppDispatch()
    const [city, setCity] = useState(item)
    const [editable, setEditable] = useState(false);
    const [appear,setAppear] = useState(false);

    const {reset,register,handleSubmit } = useForm();

    
    const onSubmit = (data: any,) => {
      dispatch(editCities(item.id,
        {
          ...data,
        }
      ))
      dispatch(fetchCities())
      setEditable(!editable);
      setIsLoading(!isLoading)
    };
  
  return (
    <>
     {editable ? (
        <>
        <tr
            onMouseOver={()=>setAppear(true)}
            onMouseOut={()=>setAppear(false)}
        >
            <td>0{index+1}</td>
            <td className="actions">
                {appear ? (
                    <div className="actions_flex">
                        <span className="editing_icon">
                            ...
                            <img src={change_blue_svg} alt="" />
                        </span>
                        <span className="ok_icon" onClick={handleSubmit(onSubmit)}>
                            ok
                        </span>
                    </div>
                ) : null}
            </td>
            <td>
            <div>
                <form>
                  <input
                    type="text"
                    defaultValue={item.title}
                    {...register("title")}
                    onChange={(e) => setCity({...city,title:e.target.value })}
                  />
                </form>
              </div>
            </td>
            <td>
            <div>
                <form className="checkbox_form">
                  <label className="designation" htmlFor="isHasHotel">Имеет ли гостиницу :</label>
                  <input
                    id="isHasHotel"
                    type="checkbox"
                    defaultChecked={item.isHasHotel}
                    {...register("isHasHotel")}
                    onChange={(e) => setCity({...city, isHasHotel : e.target.checked})}
                  />
                </form>
              </div>
            </td>
            <td>
            <div>
                <form className="checkbox_form">
                  <label className="designation" htmlFor="isDepartureCity">Город вылета :</label>
                  <input
                    id="isDepartureCity"
                    type="checkbox"
                    defaultChecked={item.isDepartureCity}
                    {...register("isDepartureCity")}
                    onChange={(e) => setCity({...city, isDepartureCity   : e.target.checked})}
                  />
                </form>
              </div>
            </td>
        </tr>
        </>
     ):(
        <>
        <tr
            onMouseOver={()=>setAppear(true)}
            onMouseOut={()=>setAppear(false)}
        >
            <td>0{index + 1}</td>
            <td className="actoins">
                <div className={appear ? "actions__block" : "actions__none"}>
                  <div>
                    <img
                      className="edit_icon"
                      src={change_white_svg}
                      alt=""
                      onClick={() => setEditable(!editable)}
                    />
                  </div>
                  <div>
                    <img className="edit_icon" onClick={() => removeCities(item.id)} src={bin} alt="" />
                  </div>
                </div>
            </td>
            <td><span className="designation">Город :</span> {city.title}</td>
            <td><span className="designation">Имеет ли гостиницу :</span> {city.isHasHotel ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
            <td><span className="designation">Город вылета :</span>{city.isDepartureCity	 ? (<img className="checked" src={checked_svg} alt="" />) : (<img className="closed" src={closed_svg} alt="" />)}</td>
        </tr>
        </>
     )} 
    </>
  )
}

