import React, { useEffect, useState } from 'react'
import './Uscontact.css'
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import whatis from 'assets/site-svgs/vots.svg'
import callme from 'assets/site-svgs/callme.svg'
import mess from 'assets/site-svgs/mess.svg'
import loacli from 'assets/site-svgs/locali.svg'
import such from 'assets/site-svgs/lorem.svg'
import blich from 'assets/site-svgs/telega.svg'
import { toast } from 'react-toastify';
import PhoneInput  from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';


type Contacts = {
    name: string,
    phone: string,
    email: string,
    message: string
}


const Uscontact = () => {
    const {t} = useTranslation()

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<Contacts>();


    const onSubmit = (e: any) => {
    };

    const defaultState = {
        name: '',
        phone: '',
        email: '',
        message: '',
    }
    const [state, setState] = useState(defaultState)

    useEffect(() => {
        console.log('Uscontact', state);
    }, [state])

    function fethDeal(data: any) {
        return fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.deal.add.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "fields": {
                    "TITLE": `Запрос с email ${data.email}`,
                    "TYPE_ID": "GOODS",
                    "STAGE_ID": "PREPARATION",
                    "CONTACT_ID": data.result,
                    "UF_CRM_1671430670995": data.name,
                    "UF_CRM_1671430683659": data.email,
                    "UF_CRM_1671430658107": data.phone,
                    "COMMENTS": data.message,
                    "CURRENCY_ID": "USD",
                    "OPPORTUNITY": data.public_price,
                }
            }),
        })
    }


    const handleSend = (e: any) => {
        e.preventDefault()
        // let form = e.target
        // let email = form.mail.value;
        // let message = form.message.value;
        // let phone = form.phone.value;
        // let name = form.name.value;
        // const data = JSON.stringify({
        //     "fields": {
        //         "NAME": state.name,
        //         "PHONE": [
        //             {
        //                 "VALUE": state.phone,
        //                 "VALUE_TYPE": "MOBILE"
        //             }
        //         ],
        //         "EMAIL": email,
        //         "MESSAGE": message,
        //     }
        // })
        setState(defaultState)
        toast.success('Вашe сообщение отправлено')

        fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.contact.list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "filter": { "PHONE": state.phone },
                "select": ["ID"]
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data?.result?.length === 0) {
                    fetch('https://zamzamtravel.bitrix24.kz/rest/1/wd27vsxz8v8vkobt/crm.contact.add', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "fields": {
                              "NAME": state.name,
                              "PHONE": [
                                {
                                  "VALUE": state.phone,
                                  "VALUE_TYPE": "MOBILE"
                                }
                              ]
                            }
                          }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data)
                            if (data.result) {
                                fethDeal({...state, result: data.result })
                                    .then((res) => res.json())
                                    .then((data) => {
                                        console.log(data)
                                        if (data.result) {
                                            setState(defaultState)
                                        }
                                    })
                            }
                        })
                } else {
                    fethDeal({...state, result: data?.result[0].ID })
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data)
                            if (data.result) {
                                setState(defaultState)
                            }
                        })
                }
            }
            )
    }

    return (
        <div className='uscontact'>
            <div className="container">
                <div className="contact-wrapper">
                    <div className="left-wrapper__contact">
                        <div className="left-wrapper__inside">
                            <div className="call__us">{t('home.text21')}</div>
                            <div className="our__workers">{t('home.text22')}</div>
                            <div className="us___comunication">
                                <img src={callme} alt="" />
                                <div className="us__nom">
                                    <a href="tel:+996554072525"> +996 (554) 072 525</a>
                                </div>
                            </div>
                            <div className="us___comunication">
                                <img src={mess} alt="" />

                                <div className="us__gmail">
                                    <a href="https://info@zamzam.kg">info@zamzam.kg</a>
                                </div>
                            </div>
                            <div className="us___comunication">
                                <img src={loacli} alt="" />

                                <div className="our__locate">
                                    Курманжан датка 413, г. Ош
                                </div>
                            </div>
                            <div className="bottom__contac">
                                <div className="online__contact">
                                    <div className="to__us">
                                        <a href="https://instagram.com/zamzam_travel.osh?igshid=Yzg5MTU1MDY="> <img src={such} alt="" /></a>
                                    </div>

                                    <div className="to__us">
                                        <a href="https://t.me/AlimovTILLO">  <img src={blich} alt="" /></a>

                                    </div>
                                    <div className="to__us">
                                        <a href="https://wa.me/996554072525"> <img src={whatis} alt="" /></a>
                                    </div>
                                </div>
                                <div className="image-elipse" >
                                    <h1>ZamZam</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-wrapper__contact">
                        <div className="right-wrapper__inside">
                            <form className="form-active__inputs"
                                onSubmit={e => handleSend(e)}>
                                <div className="contact-inputs">

                                    <div className="two-inputs">
                                        <div className={`input__side ${errors?.name && 'error'}`}>
                                            <div>{t('home.text23')}</div>
                                            <input type="text" value={state.name} name="name" onChange={(e) => setState({ ...state, name: e.target.value })} />
                                        </div>

                                        <div className={`input__side ${errors?.phone && 'error'}`}>
                                            <div>Телефон</div>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                defaultValue="+996"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <PhoneInput
                                                    country="KG"
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(value) => setState({...state, phone : value || ""})}
                                                  />
                                            )}
                                            />
                                            {/* <input type="text" value={state.phone} name="phone" onChange={(e) => setState({ ...state, phone: e.target.value })} /> */}
                                        </div>
                                    </div>

                                    <div className="two-inputs">
                                        <div className={`input__side ${errors?.email && 'error'}`}>
                                            <div>E-mail</div>
                                            <input type="email" name="mail" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                                        </div>

                                        <div className={`input__side ${errors?.message && 'error'}`}>
                                            <div>Сообщение</div>
                                            <input type="text" name="message" value={state.message} onChange={(e) => setState({ ...state, message: e.target.value })} />
                                            {errors?.message && 
                                                <div className="error_text">заполните пропущенные поля</div>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="btn_to-send">
                                    <button
                                        disabled={state.name === '' || state.phone === '' || state.message === '' ? true : false}
                                    >
                                        {
                                            t('home.text24')
                                        }
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Uscontact
