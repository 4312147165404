import React from 'react'
import Footer from 'components/Footer/Footer'
import HeaderBook from 'components/Header/Header-book/Header-book'

import { useState, useEffect } from 'react'
import arrow from 'assets/site-svgs/aroow_top.svg'

import FlightsBook from 'components/HeaderFoot/Flights/Flights-book/flights-book'
import { FlightThings } from 'components/HeaderFoot/Flights/component/flight-things'

export const BookPage = () => {

  const [activeIcon, setActiveIcon] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 550) {
        setActiveIcon(true)
      } else {
        setActiveIcon(false)
      }
    })
  }, [])


  const scrollUp = () => {
    window.scrollTo(0, 0)
  }
  return (
    <div className='book-page'>
      <>
        <HeaderBook />
        <FlightsBook />
        <Footer />
      </>
      {activeIcon ?
        <div className='icon-arow' onClick={scrollUp} >
          <img src={arrow} alt="" />
        </div> : null
      }
    </div>
  )
}
