import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ToursAllCRUD } from "redux/slices/tours_all";
import { STATUS_OPTIONS } from "redux/constants";
import { Modal } from "components/admin";
import { ModalApplication } from "./ModalApplication";
import { useIsAgent, useIsAdmin } from "hooks/useIsAgent"
import loading_gif from "assets/loading_umra.gif"

interface Props {
  item: any;
  index: number;
}

export const ApplicationItem = ({ item, index }: Props) => {
  const { profile } = useAppSelector((state) => state);
  const [loading, setLoading] = useState(false)

  const { role } = profile.data;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAgent =  useIsAgent()
  const isAdmin = useIsAdmin()

  const [isOpen, setIsOpen] = useState(false);
  const [active2, setActive2] = useState(false);

  
  const {
    id,
    user,
    date,
    amount_of_people,
    hajhead_duty,
    hajhead_registered,
    firm_duty,
    firm_registered,
    registered_people,
    extra_registered,
    free_places,
    amount_of_days,
    category,
    desired_hotels,
    total_price,
    public_price,
    flight,
    is_published,
    is_archived,
    airline,
    status,
  } = item;


  const [place, setPlace] = useState({
    id: item.id,
    amount_of_people : item.amount_of_people,
    hajhead_duty : item.hajhead_duty,
    hajhead_registered: item.hajhead_registered,
    firm_duty : item.firm_duty,
    firm_registered : item.firm_registered,
    registered_people : item.registered_people,
    extra_registered : item.extra_registered,
    free_places : item.free_places
  });


  useEffect(() => {
    setPlace({
      id: item.id,
      amount_of_people : item.amount_of_people,
      hajhead_duty : item.hajhead_duty,
      hajhead_registered: item.hajhead_registered,
      firm_duty : item.firm_duty,
      firm_registered : item.firm_registered,
      registered_people : item.registered_people,
      extra_registered : item.extra_registered,
      free_places : item.free_places
    })
  }, [id])



  useEffect(() => {
    const registered_people =
      +place.hajhead_registered + +place.firm_registered;
    const free_places =
      +place.amount_of_people -
      (+place.hajhead_registered + +place.firm_registered);
    const extra_registered = place.amount_of_people - place.registered_people;
    const firm_duty =
      +place.amount_of_people -
      Math.max(+place.hajhead_duty, +place.hajhead_registered);
    setPlace({
      ...place,
      registered_people: registered_people,
      free_places: free_places < 0 ? 0 : free_places,
      extra_registered: free_places < 0 ? Math.abs(extra_registered) : 0,
      firm_duty: firm_duty,
    });
  }, [place.hajhead_duty, place.hajhead_registered, place.firm_registered]);

  const editTourAction = {
    value: "1",
    label: "Редактировать",
    onclick: () => {
      navigate(`/admin/tour/${id}/edit`);
    },
  };

  const hideAction = {
    value: "3",
    label: "Скрыть",
    onclick: () => {
      dispatch(ToursAllCRUD.patch(id, { is_published: false }));
    },
  };

  const openAction = {
    value: "4",
    label: "Показать",
    onclick: () => {
      dispatch(ToursAllCRUD.patch(id, { is_published: true, is_archived: false }));
    },
  };

  const archiveAction = {
    value: "5",
    label: "Архивировать",
    onclick: () => {
      dispatch(ToursAllCRUD.patch(id, { is_archived: true }));
    },
  };

  const deleteAction = {
    value: "1",
    label: "Удалить",
    onclick: () => {
      dispatch(ToursAllCRUD.remove(id));
    },
  };

  const handleStatusChange = (selectedOption: any) => {
    dispatch(ToursAllCRUD.patch(id, { status: selectedOption.value }));
  };


  const handlePlaceSave = (e: any) => {
    e.preventDefault();
    setLoading(prev => !prev)
    dispatch(ToursAllCRUD.patch(id, place, setLoading));
    setIsOpen(false);
  };

  const handlePlaceChange = (e: any) => {
    if (
      e.target.name === "hajhead_duty" &&
      e.target.value > place.amount_of_people
    ) {
      return;
    } else {
      setPlace({ ...place, [e.target.name]: e.target.value ? e.target.value : 0 });
    }
  };


  const actions = [
    is_published === false || is_archived === true ? openAction : null,
    !is_published && is_archived === false && !isAgent ? editTourAction : null,
    flight && is_published === true && is_archived === false && isAdmin? hideAction : null,
    flight && is_published === false && is_archived === false && isAdmin ? archiveAction : null,
    flight && is_published === false && is_archived === true && isAdmin?  deleteAction : null,
  ].filter(Boolean);

  if (flight?.routes.length === 0) return null;

  const routesFrom = flight?.routes
    .filter((route: any) => route.type === 1)
    .sort((a: any, b: any) => b.id - a.id)[0].city_title;
  const routesTo = flight?.routes
    .filter((route: any) => route.type === 2)
    .sort((a: any, b: any) => a.id - b.id)[0].city_title;

    const routesFrom2 = flight?.routes.filter((route: any) => route.type === 1)
    const routesTo2 = flight?.routes.filter((route: any) => route.type === 2)


  const navigateToProfile = (e:any) =>{
    e.preventDefault()
    if(profile.data.id === user.id){
      navigate(`/admin/profile/`)
    }else{
      navigate(`/admin/agents/${user.id}`)
    }
  }




  return (
    <tr>
      <td>
        <div className="desc">ID:</div>
        {index + 1} / {id}
      </td>
      <td>
        <div className="desc">Заявка:</div>
        <a onClick={(e) => {navigateToProfile(e)}} >{user.first_name}<br/>{user.last_name}</a>
      </td>
      <td>
        <div className="desc">Дни ,Пакет:</div>
        {amount_of_days}
        <br />
        {category?.title}
      </td>
      <td>
        <div className="desc">Дата, Город вылета:</div>
        {moment(flight.date_from).format('L')}
        <br />
        {flight?.city.title}
      </td>
      <td>
        <div className="desc">Авиакомпания, Маршрут:</div>
        <div>{airline ? airline.title : flight?.airline_category_title}</div>
        <div className="info__link">
          <div onClick={() => setActive2(true)}>
            <a href="#">
              {routesFrom} - {routesTo}
            </a>
          </div>
        </div>
      </td>
      <td>
        <div className="desc">Гостиницы:</div>
        <div>
        {desired_hotels.map((hotel: any, i: number) => (
          <div key={i}>
            <div>
              <span className="blacktext">{hotel.hotel.city_title}:</span>{" "}
              {hotel.hotel.title}
              <span className="blacktext"> - {hotel.amount_of_days}</span>
            </div>
          </div>
        ))}
        </div>
      </td>

      <td>
        <div className="desc">Сумма:</div>
        {total_price}$<br />
        {public_price}$
      </td>
      <td>
        <div className="desc">Места :</div>
        {registered_people >= 0 ? (
          loading ? (
            <img width={15} height={15} src={loading_gif}/>
          ) : (
          <div className="places" onClick={() => setIsOpen(!isOpen)}>
            <div className="places__count">
              {registered_people ? (
                <div
                  className="reserved"
                  style={{
                    width: `${registered_people * (100 / amount_of_people)}%`,
                  }}
                >
                  {registered_people}
                </div>
              ) : null}
              {amount_of_people - registered_people ? (
                <div
                  className="unreserved"
                  style={{
                    width: `${
                      (amount_of_people - registered_people) *
                      (100 / amount_of_people)
                    }%`,
                  }}
                >
                  {amount_of_people - registered_people}
                </div>
              ) : null}
            </div>
            <div className="passendjer__total--count">{amount_of_people}</div>
          </div>
        )) : (
          <div className="passendjer__total--count">{amount_of_people}</div>
        )}
      </td>

      <td>
        <div className="SelectCenter">
        <Select
          placeholder="Действие"
          options={actions}
          styles={{
            control: (provided, state) => ({
              ...provided,
              width: "130px",
              backgroundColor: "#125D98",
              border: "none",
              color: "#fff",
              borderRadius: "5px",
              boxShadow: "none",
              "&:hover": {
                border: "none",
                color: "#fff",
                boxShadow: "none",
              },
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: "#fff",
            }),
          }}
          onChange={(e: any) => e.onclick()}
        />
        </div>
        <Modal
          active={isOpen}
          setActive={setIsOpen}
          element={
            <form className="modal__body tour_places">
              <div>ID :{place.id}, ID2 : {item.id}</div>
              <div className="tour_places__counts">
                <div className="reserved">
                  <label>Запись общий</label>
                  <span>{place.amount_of_people}</span>
                </div>
                <div className="reserved">
                  <label>Фирма обязуется</label>
                  <span>{place.firm_duty}</span>
                </div>
                <div className="reserved">
                  <label>Записанные</label>
                  <span>{place.registered_people}</span>
                </div>
                <div className="reserved">
                  <label>Дополнительные</label>
                  <span>{place.extra_registered}</span>
                </div>
                <div className="reserved">
                  <label>Свободные</label>
                  <span>{place.free_places}</span>
                </div>
              </div>
              <div className="tour_places__counts">
                <div className="tour_places__count">
                  <label>Ажы башы обязуется</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.hajhead_duty}
                    name="hajhead_duty"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
                <div className="tour_places__count">
                  <label>Записанные у ажи башы</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.hajhead_registered}
                    name="hajhead_registered"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
                <div className="tour_places__count">
                  <label>Записанные у фирмы</label>
                  <input
                    disabled={role !== 1 && role !== 2 && role !== 5}
                    type="number"
                    className="tour_places__count--input"
                    defaultValue={place.firm_registered}
                    name="firm_registered"
                    onChange={(e) => handlePlaceChange(e)}
                    min="0"
                    max={place.amount_of_people}
                  />
                </div>
              </div>
              <div className="modal__actions">
                <input
                  type="submit"
                  value="Сохранить"
                  onClick={(e) => handlePlaceSave(e)}
                />
              </div>
            </form>
          }
        />


        <ModalApplication routesTo2={routesTo2} routesFrom2={routesFrom2} active2={active2} setActive2={setActive2}/>
      </td>
    </tr>
  );
};
