import React, { useState } from 'react'
import HeaderMenu from '../header-menu/header-menu'
import Modal from '../../Modal/Modal'
import './Header-book.css'

const HeaderBook = () => {
  const [active, setActive] = useState(false)
  return (
    <div>
      <HeaderMenu active={active} setActive={setActive} />
      <Modal active={active} setActive={setActive} />
    </div>
  )
}

export default HeaderBook
