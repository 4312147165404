import { useState, useEffect, useRef } from 'react';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import Select from 'react-select';
import { useForm, useController, Controller, SubmitHandler, useWatch } from "react-hook-form";
import DatePicker from "react-datepicker";

import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { fetchFlights, saveFlight, editFlight, deleteFlight } from "redux/slices/flights";
import { fetchAirline, saveAirline } from "redux/slices/airlines";
import { fetchCities, saveCity } from "redux/slices/cities";

import "react-datepicker/dist/react-datepicker.css";
import nextArrowSvg from 'assets/svg/icons/next-arrow.svg';
import nextArrowSvgRed from 'assets/svg/icons/next-arrow-blue.svg';
import checked_popup from 'assets/svg/icons/checked_popup.svg'
import { RoutesModal } from "./RoutesModal";
import { Info } from "./Info";

interface IFormInput {
  date: string
  airline: any
  places: number
  price: number
  city: any
  routes: any[]
}

export const Flights = () => {
  const [get, setGet] = useState(false);

  const [modalActive, setModalActive] = useState(false)
  const [yet, setYet] = useState(false)

  const [routes, setRoutes] = useState([])

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 300,
      border: 'none',
      borderBottom: '1px solid #474665',
      borderRadius: 0,
      boxShadow: 'none',
    }),
  }

  // set up dispatch
  const dispatch = useAppDispatch();

  const localPageString = localStorage.getItem('paginationPosition');
  const localPage = localPageString ? +localPageString : 1;

  console.log(localPage)

  // hook to fetch items
  useEffect(() => {
    dispatch(fetchAirline());
    dispatch(fetchFlights(localPage));
    dispatch(fetchCities());
  }, [get]);

  // fetch data from our store
  const { airlines, cities, flights } = useAppSelector(state => state);


  const { reset, register, control, handleSubmit, setValue, formState: { errors } } = useForm<IFormInput>();

  const dataStart = new Date(`${startDate}`)
  const dataEnd = new Date(`${endDate}`)

  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(saveFlight({
      ...data,
      airline: data.airline.value,
      date_from: new Date(dataStart.setDate(dataStart.getDate() + 1)).toISOString().slice(0, 10),
      date_to: new Date(dataEnd.setDate(dataEnd.getDate() + 1)).toISOString().slice(0, 10),
      routes
    }))

    reset()
    handleCityChange('')
    handleAirlineChange('')
    setDateRange([null, null])
    setRoutes([])
    setYet(false)
  };

  const handleRoutesChange = (newValue: any) => {
    setValue('routes', newValue)
  }

  const handleCityChange = (newValue: any) => {
    setValue('city', newValue)
  }

  const handleAirlineChange = (newValue: any) => {
    setValue('airline', newValue)
  }

  const onAirlineCreate = (inputValue: string) => {
    dispatch(saveAirline({
      "title": inputValue
    }, handleAirlineChange))
  }

  const onCityCreate = (inputValue: string) => {
    dispatch(saveCity({
      "title": inputValue
    }, handleCityChange))
  }

  return (
    <div className="">
      <div className="flights">
        <div className="directory__title">Рейсы</div>
        <form className='form flights_form' onSubmit={handleSubmit(onSubmit)}>
          <div className={`form--item ${errors?.airline && 'error'}`}>
            <label>Название авиакомпании</label>
            <Controller
              name="airline"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <CreatableSelect
                {...field}
                isClearable
                isDisabled={airlines.loading}
                isLoading={airlines.loading}
                options={airlines.items}
                styles={customStyles}
                placeholder={<div>Выберите...</div>}
                formatCreateLabel={userInput => `Создать: ${userInput}`}
                onCreateOption={option => onAirlineCreate(option)}
                onChange={handleAirlineChange}
              />}
            />
            {errors?.airline && <div className="error_text">Выберите категорию номера</div>}
          </div>
          <div className="form--item ">
            <label>Маршрут</label>
            <input type="hidden" {...register("routes", { required: true })} />
            <button className={`btn ${yet ? 'added' : 'add'}`} onClick={(e) =>{ e.preventDefault();setModalActive(true)}}>
              {yet ? <div>Маршрут добавлен  </div> : <div>Добавить маршрут</div>}
              <img src={yet ? checked_popup : nextArrowSvgRed} alt="" />
            </button>
            {errors?.routes && <div className="error_text">Выберите Маршрут</div>}
          </div>

          {/* <div className={`form--item ${errors?.city && 'error'}`}>
            <label>Город вылета</label>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Select
                {...field}
                isClearable
                isDisabled={true}
                isLoading={cities.loading}
                options={cities.items}
                styles={customStyles}
                placeholder={<div>Выберите...</div>}
                onChange={handleCityChange}
              />
              }
            />
            {errors?.city && <div className="error_text">Выберите город</div>}
          </div> */}

          <div className={`form--item ${errors?.date && 'error'}`}>
            <label >Дата</label>

            <Controller
              name="date"
              control={control}
              render={({ field }) => <DatePicker
                locale="ru"
                dateFormat="dd.MM.yyyy"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                onChange={(update: any) => {
                  setDateRange(update);
                }}
                isClearable={true}
              />}
            />
            {errors?.date && <div className="error_text">Выберите дату </div>}
          </div>
          <div className={`form--item ${errors?.price && 'error'}`}>
            <label>Цена ($)</label>
            <input type="number" {...register("price", { required: true })} />
            {errors?.price && <div className="error_text">заполните поле </div>}
          </div>
          <div className={`form--item ${errors?.places && 'error'}`}>
            <label>Места</label>
            <input className="input_100" type="number" {...register("places", { min: 1, max: 299, required: true })} />
            {errors?.places && <div className="error_text">заполните поле </div>}
          </div>
          <div className="form--item submit">
            <button className="btn" type="submit">
              Добавить
              <img src={nextArrowSvg} alt="" />
            </button>
          </div>
        </form>
      </div>

      {flights.data.results.length > 0 && <Info setGet={setGet} get={get} data={flights.data} list={flights.data.results} />}

      <RoutesModal
        item={''}
        routes={routes}
        setRoutes={setRoutes}
        yet={yet}
        setYet={setYet}
        active={modalActive}
        setActive={setModalActive}
        readOnly={false}
        handleRoutesChange={handleRoutesChange} />
    </div>
  );
};