import React, { useEffect, useState } from 'react';
import { EMode, EType } from 'pages/admin/Tour/CRUD'

interface Props {
    isDisabled?: boolean;
    type?: string,
    mode?: string,
    icon: string;
    item: any;
    formState: any;
    setFormState: any;
    errors: any;
    state: string;
}

export const Checkbox = ({ type, mode, item, icon, formState, setFormState, state, isDisabled = false }: Props) => {
    const handleChange = (value: any) => {
        const new_state = formState[state].map((item: any) => {
            if (item.id === value.id) {
                return {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                return item
            }
        });
        setFormState({ ...formState, [state]: new_state });
    };

    return (
        <div className="thirdSelect" onClick={() => {
            if (item.isSpecified === false) {
                handleChange(item)
            }
        }}>
            <div className="secondSelect__title">
                <img src={icon} alt="" />
                <div className="secondSelect__title--text">{item?.place_title ? item?.place_title : item?.title}</div>
            </div>

            <input
                className="secondSelect__checkbox"
                type="checkbox"
                name={`checkbox_${item?.id}`}
                value={item}
                onChange={() => isDisabled === false ? handleChange(item) : null}
                checked={item?.isChecked}
                disabled={item?.isSpecified}
            />
        </div>
    )
}