import React, {useState, useEffect} from "react";
import { useAppSelector } from 'redux/hooks'
import { ApplicationItems } from "pages/admin/Home/pages/ApplicationItems";

export const NotDeparted = () => {
  const { tours_all } = useAppSelector(state => state);

  const [filtered, setFiltered] = useState<any[]>([]);

  useEffect(() => {
    setFiltered(tours_all.results.filter((item: any) => item.is_archived === false))
  }, [tours_all])

  return (
      <ApplicationItems loading={tours_all.loading} items={filtered} title=''/>
  )
}