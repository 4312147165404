import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { editFlight, fetchFlights } from "redux/slices/flights";
import { useForm, Controller } from "react-hook-form";
import nextArrowSvgRed from "assets/svg/icons/next-arrow-blue.svg";
import checked_popup from "assets/svg/icons/checked_popup.svg";
import { fetchAirline } from "redux/slices/airlines";
import { fetchCities } from "redux/slices/cities";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { RoutesModal } from "./RoutesModal";
import { EditRoutesModal } from "./EditRoutesModal";
import { API_URL } from "services"
import { Page404 } from "pages/admin";

export const EditFlight = () => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const [flight, setFlight] = useState<any>([]);

  const { airlines, cities } = useAppSelector((state) => state);

  useEffect(() => {
    axios.get(`${API_URL}/flights/${id}/`).then((response) => {
      setFlight(response.data);
    }).catch(error => {      
      if(error.response && error.response.status === 404){
        navigate('*')
      }
    });
    dispatch(fetchAirline());
    dispatch(fetchCities());
  }, []);

  const [air, setAir] = useState({
    id: undefined,
    title: undefined,
    value: undefined,
    label: undefined,
  })

  useEffect(() => {
    setAir(airlines.items.find((air: any) => air.id === flight?.airline))
  }, [airlines, flight])

  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 250,
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };
  const handleRoutesChange = (newValue: any) => {
    setValue("routes", newValue);
  };
  const [editable, setEditable] = useState(false);

  const [yet, setYet] = useState(false);
  const [active, setActive] = useState(false);

  const handleAirlineChange = (newValue: any) => {
    setValue("airline", newValue);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    setRoutes(flight?.routes);
    setValue('oldRoutes', flight?.routes )
  }, [flight?.routes]);


  const dataStart = new Date(`${startDate}`);
  const dataEnd = new Date(`${endDate}`);

  const onSubmit = (data: any) => {
    // new Date(dataEnd.setDate(dataEnd.getDate() + 1))
    dispatch(
      editFlight(id, {
        airline: data.airline.id,
        city: data.city.id,
        date_from:
          startDate !== null
            ? new Date(dataStart.setDate(dataStart.getDate() + 1)).toISOString().slice(0, 10)
            : flight?.date_from,
        date_to:
          endDate !== null
            ? new Date(dataEnd.setDate(dataEnd.getDate() + 1)).toISOString().slice(0, 10)
            : flight?.date_to,
        price: data.price,
        places: data.places,
        routes: routes,
        removed_routes : data.oldRoutes.map((i:any) => i.id)
        // updated_routes : routes.map((i:any) => ({route_id : i.id, city : i.city, type : i.type}))
      })
    );
    setEditable(!editable);
    dispatch(fetchFlights());
    navigate("/admin/directory/flights/");
  };

  useEffect(() => {
    setValue("city", {
      id: flight?.city,
      title: flight?.city_title,
      value: flight?.city,
      label: flight?.city_title,
    });
    setValue("airline", {
      id: air?.id,
      title: air?.title,
      value: air?.value,
      label: air?.label,
    });
    setValue("price", flight?.price);
    setValue("places", flight?.places);
  }, [flight, air]);

  return (
    <>
      <div className="editFlight">
        <div style={{ paddingTop: "30px", width: "115px" }}>
          <Link to="/admin/directory/flights" className="back">
          Назад
          </Link>
        </div>
        <div className="flight__list_item">
          <div>
            <div className="flight_text">Авиакомпании</div>
            <div className={`form--item ${errors?.airline && "error"}`}>
              <Controller
                name="airline"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={airlines.loading}
                    isLoading={airlines.loading}
                    options={airlines.items}
                    styles={customStyles}
                    onChange={handleAirlineChange}
                  />
                )}
              />
              {errors?.airline && (
                <div className="error_text">Выберите категорию номера</div>
              )}
            </div>
          </div>

          <div>
            <div className="flight_text">Город вылета</div>
            <div className={`form--item ${errors?.city && "error"}`}>
              {flight?.city?.title}
              {errors?.city && <div className="error_text">Выберите город</div>}
            </div>
          </div>

          <div>
            <div className="flight_text">Маршрут</div>
            <div className="form--item ">
              <input type="hidden" />
              <button
                style={{ background: "rgb(220, 236, 252)" }}
                className={`btn_route ${yet ? "added" : "add"}`}
                onClick={() => setActive(true)}
              >
                {yet ? (
                  <div>Маршрут добавлен </div>
                ) : (
                  <div className="change_title">
                    <div className="change_title_text">Изменить</div>{" "}
                    <div className="change_title_text">маршрут</div>
                  </div>
                )}
                <img src={yet ? checked_popup : nextArrowSvgRed} alt="" />
              </button>
              {errors?.routes && (
                <div className="error_text">Выберите Маршрут</div>
              )}
            </div>
          </div>
          <div>
            <div className="flight_text">Дата</div>
            <div className={`form--item ${errors?.date && "error"}`}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    locale="ru"
                    {...field}
                    className="DateInput_input"
                    placeholderText={
                      flight?.date_from + " - " + flight?.date_to
                    }
                    dateFormat="dd.MM.yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                )}
              />
              {errors?.date && <div className="error_text">Выберите дату </div>}
            </div>
          </div>
          <div>
            <div className="flight_text">Цена ($)</div>
            <div>
              <form>
                <input type="text" {...register("price")} />
              </form>
            </div>
          </div>
          <div>
            <div className="flight_text">Количество места</div>
            <div>
              <form>
                <input type="text" {...register("places")} />
              </form>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "30px" }}>
          <button onClick={handleSubmit(onSubmit)} className="back">
            Сохранить
          </button>
        </div>
      </div>

      <EditRoutesModal
        item={""}
        routes={routes}
        setRoutes={setRoutes}
        yet={yet}
        setYet={setYet}
        active={active}
        setActive={setActive}
        readOnly={false}
        handleRoutesChange={handleRoutesChange}
      />
    </>
  );
};
