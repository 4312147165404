import { useState, useEffect } from "react";
import CreatableSelect, { useCreatable } from "react-select/creatable";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { ToastContainer} from 'react-toastify';

import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  fetchHotels,
  saveHotel,
  deleteHotel,
  editHotel,
} from "redux/slices/hotels";
import {
  fetchRoomCategory,
  saveRoomCategory,
} from "redux/slices/room_category";
import { fetchCities, saveCity } from "redux/slices/cities";
import nextArrowSvg from "assets/svg/icons/next-arrow.svg";
import { Pagination } from "../../Pagination";
import { HotelsInfo } from "./HotelsInfo";

interface IFormInput {
  title: string;
  room_category: any;
  places: number;
  price: number;
  city: any;
  link: string;
}

export const Hotels = () => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 300,
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  // set up dispatch
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchHotels());
    dispatch(fetchRoomCategory());
    dispatch(fetchCities());
  }, [dispatch]);

  // hook to fetch items

  // fetch data from our store
  const { hotels, room_category, cities } = useAppSelector((state) => state);

  const handlePaginate = (page: number) => {
    dispatch(fetchHotels(page));
  }

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log("data", data);
    dispatch(
      saveHotel({
        ...data,
        city: data.city.value,
        room_category: data.room_category.value,
      })
    );

    reset();
    handleCityChange("");
    handleRoomChange("");
  };

  const handleCityChange = (newValue: any) => {
    setValue("city", newValue);
  };

  const handleRoomChange = (newValue: any) => {
    setValue("room_category", newValue);
  };

  const onRoomCreate = (inputValue: string) => {
    dispatch(
      saveRoomCategory(
        {
          title: inputValue,
        },
        handleRoomChange
      )
    );
  };

  const onCityCreate = (inputValue: string) => {
    dispatch(
      saveCity(
        {
          title: inputValue,
        },
        handleCityChange
      )
    );
  };

  const handleDeleteHotel = (id: number) => {
    dispatch(deleteHotel(id));
  };
  
  // const handleEditHotel = (id: number, data: any) => {
  //   console.log("hotels", data, id);
  
  //   // dispatch(
  //   //   editHotel(id, {
  //   //     city: data.city.id,
  //   //     places: data.places,
  //   //     price: data.price,
  //   //     room_category: data.room_category.id,
  //   //     title: data.title,
  //   //   })
  //   // );

  //   setGet(!get);
  // };


  const renderHotels = () => {
    return hotels.data.results.map((item: any, index: number) => (
      <HotelsInfo
        key={item.id}
        item={item}
        remove={handleDeleteHotel}
        // edit={handleEditHotel}
        index={index}
      />
    ));
  };  

  return (
    <div className="hotels">
      <div className="directory__title">Гостиницы</div>
      <form className="form hotels__form" onSubmit={handleSubmit(onSubmit)}>
        <div className={`form--item ${errors?.title && "error"}`}>
          <label>Название гостиницы</label>
          <input type="text" {...register("title", { required: true })} />
          {errors?.title && <div className="error_text">заполните поле </div>}
        </div>
        <div className={`form--item ${errors?.city && "error"}`}>
          <label>Город</label>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                isDisabled={cities.loading}
                isLoading={cities.loading}
                options={cities.items.filter(i => i.isHasHotel)}
                styles={customStyles}
                placeholder={<div>Выберите...</div>}
                onChange={handleCityChange}
              />
            )}
          />
          {errors?.city && <div className="error_text">Выберите город</div>}
        </div>
        <div className={`form--item ${errors?.room_category && "error"}`}>
          <label>Категория номера</label>
          <Controller
            name="room_category"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                isDisabled={room_category.loading}
                isLoading={room_category.loading}
                options={room_category.items}
                styles={customStyles}
                placeholder={<div>Выберите...</div>}
                formatCreateLabel={(userInput) => `Создать: ${userInput}`}
                onCreateOption={(option) => onRoomCreate(option)}
                onChange={handleRoomChange}
              />
            )}
          />
          {errors?.room_category && (
            <div className="error_text">Выберите категорию номера</div>
          )}
        </div>
        <div className={`form--item ${errors?.places && "error"}`}>
          <label>Мест в одном номере</label>
          <input
            type="number"
            {...register("places", { min: 1, max: 99, required: true })}
          />
          {errors?.places && <div className="error_text">заполните поле </div>}
        </div>
        <div className={`form--item ${errors?.price && "error"}`}>
          <label>Цена ($)</label>
          <input type="number" {...register("price", { required: true })} />
          {errors?.price && <div className="error_text">заполните поле </div>}
        </div>
        <div className={`form--item ${errors?.price && "error"}`}>
          <label>Ссылка на сайт</label>
          <input type="url" {...register("link")} />
          {errors?.price && <div className="error_text">заполните поле </div>}
        </div>
        <div className="form--item submit">
          <button className="btn" type="submit">
            Добавить
            <img src={nextArrowSvg} alt="" />
          </button>
        </div>
      </form>

      <table className="hotel_table">
        <thead>
          <tr>
            <td>№</td>
            <td></td>
            <td>Название гостиницы</td>
            <td>Город</td>
            <td>Категория номера</td>
            <td>Мест в одном номере</td>
            <td>Цена ($)</td>
          </tr>
        </thead>
        <tbody>{renderHotels()}</tbody>
      </table>

     

      <Pagination data={hotels.data} handle={handlePaginate} />
    </div>
  );
};
