import React, { SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { SidebarItem } from './admin/Sidebar/Item';
import sidebar_icon1 from '../assets/svg/icons/sidebar__icons/sidebar_icon1.svg';
import sidebar_icon2 from '../assets/svg/icons/sidebar__icons/sidebar_icon2.svg';
import sidebar_icon3 from '../assets/svg/icons/sidebar__icons/sidebar_icon3.svg';
import sidebar_icon4 from '../assets/svg/icons/sidebar__icons/sidebar_icon4.svg';
import sidebar_icon5 from '../assets/svg/icons/sidebar__icons/sidebar_icon5.svg';
import sidebar_icon6 from '../assets/svg/icons/sidebar__icons/sidebar_icon6.svg';
import sidebar_icon7 from '../assets/svg/icons/sidebar__icons/sidebar_icon7.svg';
import logout from '../assets/svg/icons/logout.svg';

interface Props {
  active: any;
  setActive: React.Dispatch<SetStateAction<boolean>> | any;
}

const MenuResponse = ({ active, setActive }: Props) => {
  return (
    <div className="MenuResponse" onClick={() => setActive(!active)}>
      <div className="MenuResponse__content">
        <div className="links">
          <SidebarItem img={sidebar_icon1} to={''} title="Главная" />
          <SidebarItem img={sidebar_icon2} to={'directory'} title="Справочник" />
          <SidebarItem img={sidebar_icon3} to={'tour'} title="Создать тур" />
          {/* <SidebarItem img={sidebar_icon4} to={'tempaltes'} title="Готовый пакет" /> */}
          <SidebarItem img={sidebar_icon5} to={'control-panel'} title="Расписание рейсов" />
          <SidebarItem img={sidebar_icon6} to={'binding'} title="Агенты" />
          <SidebarItem img={sidebar_icon7} to={'profile'} title="Профиль" />
        </div>
        <Link to="/">
          <button>
            <img src={logout} alt="" />
            На главную
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MenuResponse;
