import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Link } from "react-router-dom";
import HeaderResponse from "components/HeaderResponse";
import Burger from "assets/svg/icons/header/menuBurgerIcon.svg";
import { MenuBurger } from "./MenuBurger";
import { useState } from "react";
import { logoutProfile } from "redux/slices/profile";
import logoSvg from 'assets/svg/logo.svg';
// import logout from "assets/svg/icons/logout.svg";
import { getRoleNameByID } from "utils/functions"

export const Header = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state);

  const { first_name, last_name, avatar, role } = profile.data;

  const [isOpen, setIsOpen] = useState(false)

  const [activeBurger, setActiveBurger] = useState(false)

  const handleLogout = () => {
    dispatch(logoutProfile())
  }

  const handleCloseMenu = (e: any) => {
    e.preventDefault()
    setIsOpen(false)
  }

  return (
    <>
      <div className="header">
        <div className="header__box">
          <div className="header__box__text">
            <div className="header__box_burger_icon">
              <img src={Burger} alt="" onClick={() => setActiveBurger(true)} />
            </div>
            <div className="logo_text">Umrah</div>
            <div className="logo_burger"><img width={72} height={62} src={logoSvg} alt="" /></div>
          </div>
          <div className="how__this-is__user">
            Вы вошли как <b>{getRoleNameByID(role)}</b>
          </div>

          <div className="header__profile">
            <div className="header__profile--title" onClick={() => setIsOpen(!isOpen)}>
              {first_name} {last_name}
              <img className="header__profile--photo" src={avatar} alt="" />
            </div>
            {isOpen && <div className="header__profile--menu">
              <div className="logout" onClick={() => handleLogout()}>Выйти из аккаунта</div>
            </div>}
          </div>
        </div>
        <div className="header__box2">
          <Link className="header__profile" to="profile">
            <div className="header__profile--title">
              {first_name} {last_name}
            </div>
            <img className="header__profile--photo" src={avatar} alt="" />
          </Link>
          <div className="header__box__text">

            <div className="logo_text">Umrah</div>
            <div className="logo_burger"><img width={72} height={62} src={logoSvg} alt="" /></div>

          </div>

          <div className="header__box_burger_icon">
            <img src={Burger} alt="" onClick={() => setActiveBurger(true)} />
          </div>
        </div>
        <div className="header__res">
          <HeaderResponse />
        </div>
      {isOpen && <div className="menu__cover" onClick={(e) => handleCloseMenu(e)}></div>}
      </div>

      <MenuBurger activeBurger={activeBurger} setActiveBurger={setActiveBurger} />
    </>
  );
};
