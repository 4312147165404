import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from 'redux/hooks'
import { getAccessToken } from 'redux/slices/profile'
import { useAppSelector } from 'redux/hooks';
import { fetchProfile } from 'redux/slices/profile';

export function InstaAuth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  const { profile } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(getAccessToken({
      redirect_uri: `https://zamzam.kg/insta-auth/`,
      code: code,
      user_id: profile?.data?.id
    }))
  }, [code]);

  return (
    <div>
      <h1>{code}</h1>
    </div>
  );
}