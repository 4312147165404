import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { HotelsByCityState } from './types'

const initialState: HotelsByCityState = {
  loading: false,
  error: false,
  items: [],
}

export const hotelsByCitySlice = createSlice({
  name: 'hotels_by_city',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload.filter((item: any) => item.hotels && item.hotels.length > 0);
    },
    setError: (state) => {
      state.error = true;
    }
  },
})

// export the actions
export const { setLoading, setItems, setError } = hotelsByCitySlice.actions;

// export the default reducer
export default hotelsByCitySlice.reducer;


// fetch all hotelsByCityCities
export function fetchHotelsByCity() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get("/hotels/get_hotels_by_city/?use_pagination=false")
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}