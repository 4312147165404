import React, { useEffect, useState } from "react";

import profilAji__item__1 from "assets/svg/icons/profileAji__icons/profilAji__item__1.svg";
import profilAji__item__2 from "assets/svg/icons/profileAji__icons/profilAji__item__2.svg";
import profilAji__item__3 from "assets/svg/icons/profileAji__icons/profilAji__item__3.svg";
import profilAji__item__4 from "assets/svg/icons/profileAji__icons/profilAji__item__4.svg";
import profilAji__item__5 from "assets/svg/icons/profileAji__icons/profilAji__item__5.svg";
import facebook_icon from "assets/svg/facebook_icon.svg"
import profileIcon from "assets/svg/icons/profileIcon.svg";
import instagram from "assets/svg/instagram.svg"


import { Instagram } from "components"
import { Item } from "components/admin/Binding/Item";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAjiModal } from "components/admin/Binding/ProfileAjiModal";
import { editProfile, } from "redux/slices/profile";

import iks from "assets/site-svgs/fucked.svg";
import screen from "assets/site-svgs/ajy_screen.svg";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { galleryCRUD } from "redux/slices/gallery";
import { descriptionsCRUD } from "redux/slices/descriptions";
import { ToursCRUD } from "redux/slices/tours";
import { getRoleNameByID } from "utils/functions"
import { API_URL } from "services"


interface Props {
  role: number;
  gallerList: any[];
  profile: any;
  filterDescriptions: any[];
}

export const Aji = ({
  role,
  gallerList,
  profile,
  filterDescriptions,
}: Props) => {
  // const { loading, error, data } = useAppSelector((state) => user);
  const { tours, users } = useAppSelector((state) => state);

  // const { id }: Readonly<Params<string>> = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(galleryCRUD.fetch())
    dispatch(descriptionsCRUD.fetch())
    dispatch(ToursCRUD.fetch({
      flight_date_min: '',
      flight_date_max: '',
      category: '',
      price__gt: '',
      price__lt: '',
      user: profile.id
    }))
  }, []);

  useEffect(() => {
    if (profile) {
      axios.get(`${API_URL}/accounts/users/${profile.id}/`)
        .then((res) => {
          setRenderUsers(res.data);
        });
      axios.get(`${API_URL}/incomes/?use_pagination=false`).then((res) => {
        setIncomes(res.data);
      });
    }

  }, []);

  const [incomes, setIncomes] = useState<any>([]);

  const [renderUsers, setRenderUsers] = useState<any>([]);


  const filterTours = tours.data.results.filter(
    (item: any) => item.user.id === profile.id
  )

  const [modalActive, setModalActive] = useState(false);

  const renderIncome =
    incomes.find(
      (item: any) => item.id === renderUsers.income
    )

  const [number, setNumber] = useState(profile.income);

  const [inf, setInf] = useState<any>({
    id: renderIncome?.id,
    price: renderIncome?.price
  });

  useEffect(() => {
    setInf({
      id: renderIncome?.id,
      price: renderIncome?.price,
    });

  }, [users.data.results.length, renderIncome]);

  const SendIncome = (item: any) => {
    setInf(item);
    editProfile({income: item.id},"")

    setModalActive(false);
  };
  const [model, setModel] = useState(false);

  const [tempImgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc: any) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };
  const modules = {
    toolbar: false
  }

  return (
    <>
      <div className="profileAji">
        <div className="how__this-is__users">
          Вы вошли как <b>{getRoleNameByID(role)}</b>
        </div>
        <div className="profileAji__background"></div>
        <div className="profileAji-info">
          <div className="profileAji-info__block">
            <div className="profileAji__blocksflex">
              <div className="profileAji-info__left">
                <div>
                  <div className="profileAji__icon">
                    <img
                      src={profile.avatar
                        ? profile.avatar
                        : profileIcon}
                      alt=""
                    />
                  </div>
                  <div className="profileAji-fullinforms">
                    <div>
                      <div className="profileAji__info">
                        <img src={profilAji__item__1} alt="" />
                        <div className="profileAji__title">
                          {profile.first_name +
                            " " +
                            profile.last_name}
                        </div>
                      </div>

                    {profile?.city ? 
                     <div className="profileAji__info">
                        <img src={profilAji__item__2} alt="" />
                        <div className="profileAji__title">
                          {profile?.city}
                        </div>
                      </div> : null
                    }
                     

                    {profile?.experience && profile?.experience > 0 ?
                       <div className="profileAji__info">
                       <img src={profilAji__item__3} alt="" />
                       <div className="profileAji__title">
                         <span>
                           {profile?.experience}
                         </span>{" "}
                         лет профессионального опыта
                       </div>
                     </div>: null
                    }
                   

                      <div className="profileAji__info">
                        <img src={profilAji__item__4} alt="" />
                        <div className="profileAji__title">
                          <a className="black_link" target="_blank" href={`tel: ${profile.phone}`} >
                            {profile.phone}
                          </a>
                        </div>
                      </div>

                      {profile.whatsapp ? 
                      <div className="profileAji__info">
                        <img src={profilAji__item__5} alt="" />
                        <div className="profileAji__title">
                          <a className="black_link" target="_blank" href={`https://wa.me/${profile.whatsapp}`}>
                            {profile.whatsapp}
                          </a>
                        </div>
                      </div>
                      : null
                      }

                      {profile.facebook ? 
                       <div className="profileAji__info">
                       <img src={facebook_icon} alt="" />
                       <div className="profileAji__title" style={{whiteSpace:'nowrap', overflow:"hidden", textOverflow:"ellipsis", maxWidth:"150px"}}>
                         <a style={{textDecoration: 'underline'}} className="black_link" target="_blank" href={`${profile.facebook}`}>
                           {profile.facebook}
                         </a>
                       </div>
                     </div>
                      :null
                      }

                      {profile.instagram ? 
                       <div className="profileAji__info">
                       <img style={{width:'24px', height:'24px'}} src={instagram} alt="" />
                       <div className="profileAji__title" style={{whiteSpace:'nowrap', overflow:"hidden", textOverflow:"ellipsis", maxWidth:"150px"}}>
                         <a style={{textDecoration: 'underline'}} className="black_link" target="_blank" href={`${profile.instagram}`}>
                           {profile.instagram}
                         </a>
                       </div>
                     </div>
                      :null
                      }
                  
                      
                      {/* {
                        profile.role === 1 || profile.role === 2 ?
                          <div
                            className="profileAji__info"
                            onClick={() => setModalActive(true)}
                          >
                            <div className="profileAji__add--btn">
                              {inf.id ? (
                                <div className="radio">
                                  <div
                                    style={{ width: "200px" }}
                                    className="radio_item"
                                  >
                                    <div className="radio_number">
                                      {inf.id !== null ? inf.id : null}
                                    </div>
                                    <div className="radio_twice">
                                      <div className="radio_text">
                                        Категория наценок
                                      </div>
                                      <div className="radio_price">
                                        {inf.price !== null ? inf.price : null}$
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                "Установить категорию наценок"
                              )}
                            </div>
                          </div> : null
                      } */}
                    </div>
                  </div>
                  {renderUsers.instagram_long_token && <Instagram role={role} token={renderUsers.instagram_long_token} limit={4} />}
                </div>
              </div>
              <div className="profileAji-info__right">
                {filterDescriptions.map(
                  (item: any) => (
                    // item.user === 2 ? (
                    <div key={item.id} className="profile__about">
                      <div className="profile__about__title">{item.title}</div>
                      <div className="profile__about__text">
                        <ReactQuill
                          readOnly={true}
                          theme="snow"
                          value={item?.description}
                          modules={modules}
                        />

                      </div>
                      <div></div>
                    </div>
                  )
                  // ) : null
                )}

                {filterTours.length !== 0 ?
                  <div className="profileAji__raises">
                    <div className="title">Рейсы</div>
                    {filterTours.length !== 0 ? <table>
                      <thead>
                        <tr>
                          <td>№</td>
                          <td>Авиакомпания</td>
                          <td>Дата вылета</td>
                          <td>Дата прилета</td>
                          <td>Маршрут</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filterTours.map((item: any) => (
                          <Item isPublic={false} key={item.id} item={item} />
                        ))}
                      </tbody>
                    </table> :
                      <div className="profileAji__warning">
                        <div>Вы не создали рейс.</div>
                      </div>}
                  </div> : null}
              </div>
            </div>
          </div>

          <div className="gallery">
            <div>
              <div className="title">Галерея</div>
              <div className="gallery__photos">
                <div className={model ? "model open" : "model"}>
                  <div className="appears">
                    <img src={tempImgSrc} alt="" />
                  </div>
                  <div
                    className="closed__gallery"
                    onClick={() => setModel(false)}
                  >
                    <img src={iks} alt="" />
                  </div>
                </div>
                {gallerList.length !== 0
                  ? gallerList.map((item: any) => (
                    <div
                      className="pics"
                      key={item.id}
                      onClick={() => getImg(item.image)}
                    >
                      <img
                        className="item__image__gallery"
                        src={item.image}
                        alt=""
                      />
                      <div className="overlay">
                        <div className="hover">
                          <h4>
                            <img src={screen} alt="" /> на весь экран
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                  : "Пользователь не добавил фото"}
              </div>
              {/* <Pagination items={gallery.list} setItems={setItems} len={4} /> */}
            </div>

            {/* <div className="gallery__btn">
              <button>Увидеть больше</button>
            </div> */}
          </div>
        </div>
      </div>

      <ProfileAjiModal
        incomes={incomes}
        setNumber={setNumber}
        number={number}
        SendIncome={SendIncome}
        renderUsers={renderUsers}
        profile={profile}
        setModalActive={setModalActive}
        modalActive={modalActive}
      />
    </>
  );
};
