import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import './style.scss'

import Feed from './Feed'

export const Instagram = ({ token, limit, role, ...props }: any) => {
    const [feeds, setFeedsData] = useState<any[]>([]);

    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            try {
                axios
                    .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,username,thumbnail_url,caption&limit=${limit}&access_token=${tokenProp.current}`)
                    .then((resp) => {
                        setFeedsData(resp.data.data)
                    })
            } catch (err) {
                console.log('error', err)
            }
        }

        fetchInstagramPost();

        return () => {
            abortController.abort();
        };
    }, [props.limit])

    if (role !== 3 && role !== 4) {
        return null
    }

    if(!token){
        return null
    }

    return (
        <div className="instagram" style={{margin:"30px 0"}}>
            <div className="instagram__title">Инстаграм</div>
            <div className="instagram__feed">
                {feeds.map((feed: any) => (
                    <Feed key={feed.id} feed={feed} />
                ))}
            </div><div className="instagram__btn">
                <a target="_blank" href={`http://instagram.com/_u/${feeds[0]?.username}`}>Посмотреть профиль</a>
            </div>
        </div>
    );
}