import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { apiWithoutToken, api } from 'services'

import { IPaginationState } from 'redux/types/RootState'

const URL = 'flights'

const initialState: IPaginationState = {
  loading: false,
  error: false,
  data: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
}

export const flightsSlice = createSlice({
  name: 'flights',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.data.results.push(payload);
    },
    editItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => {
        if(item.id !== payload.id){          
          return {
            ...item,
            payload
          }
        }
        return item
      })
    },
    deleteItem: (state, {payload }) => {
      state.data.results = state.data.results.filter(item => item.id !== payload)
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem, editItem, deleteItem } = flightsSlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const flightsSelector = (state: { items: any }) => state.items;

// export the default reducer
export default flightsSlice.reducer;


// fetch all flights
export function fetchFlights( localPage = 1, with_available_places=false) {
  setLoading()
  return async (dispatch: Dispatch) => {
    apiWithoutToken.get(`/${URL}/?page=${localPage}&ordering=-date_from&with_available_places=${with_available_places}`)
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {        
        dispatch(setError());
      });
  };
}

// add new Item
export function saveFlight(data={}) {
  return async (dispatch: Dispatch) => {
    api.post(`/${URL}/`, data)
      .then((response) => {
        dispatch(addItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new Item
export function editFlight(id: number,  data={}) {
  return async (dispatch: Dispatch) => {
    api.patch(`/${URL}/${id}/`, data)
      .then((response) => {
        dispatch(editItem(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

/** add new Item  */
export function deleteFlight(id: number) {
  return async (dispatch: Dispatch) => {
    api.delete(`/${URL}/${id}/`,)
      .then((response) => {
        dispatch(deleteItem(id));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}