import React, { useState, useEffect, SetStateAction } from "react";

import { deleteFlight, fetchFlights } from "redux/slices/flights";
import { List } from "./List";

import DatePicker from "react-datepicker";

import CreatableSelect from "react-select/creatable";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useAppDispatch } from "../../../../redux/hooks";
import { saveCity } from "../../../../redux/slices/cities";
import nextArrowSvg from "../../../../assets/svg/icons/next-arrow.svg";
import { PriceList } from "./PriceList";
import airlines__prices, {
  deleteAirline_prices,
  fetchAirline_prices,
  postAirline_prices,
} from "redux/slices/airlines__prices";
import { fetchAirline } from "redux/slices/airlines";

interface Props {
  modal: boolean;
  setModal: React.Dispatch<SetStateAction<boolean>>;
  item: any[];
  cities: any;
  airlines: any;
  airlines_prices: any;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  id: number;
}

interface IFormInput {
  title: string;
  room_category: any;
  places: number;
  price1: number;
  price2: number;
  city: any;
  date: Date;
}

export const Modal = ({
  modal,
  setModal,
  item,
  airlines,
  cities,
  airlines_prices,
  id,
  isLoading,
  setIsLoading,
}: Props) => {

  useEffect(() => {
    dispatch(fetchAirline_prices())
    dispatch(fetchAirline())
  }, [isLoading])
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 300,
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  const dispatch = useAppDispatch();

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  const dataStart = new Date(`${startDate}`);
  const dataEnd = new Date(`${endDate}`);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      postAirline_prices({
        price: data.price2,
        date_from: dataEnd.toISOString().slice(0, 10),
        date_to: dataStart.toISOString().slice(0, 10),
        airline: id,
        city: data.city.id,
      })
    );
    setIsLoading(!isLoading);

    reset();
    handleCityChange("");
  };

  const handleCityChange = (newValue: any) => {
    setValue("city", newValue);
  };

  const onCityCreate = (inputValue: string) => {
    dispatch(
      saveCity(
        {
          title: inputValue,
        },
        handleCityChange
      )
    );
  };

  const deleteAirline_price = (id: number) => {
    dispatch(deleteAirline_prices(id));
    setIsLoading(!isLoading);
  };

  const sortedPrice = airlines_prices.items.filter(
    (item: any) => item.airline === id
  );

  return (
    <div
      onClick={() => setModal(false)}
      className={modal ? "modal active" : "modal"}
    >
      <div onClick={(e) => e.stopPropagation()} className="modal__content">
        <div style={{ textAlign: "start" }} className="directory__title">
          Цены авиакомпани
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: "25px" }}
        >
          <div className="form_price" style={{ textAlign: "start" }}>
            <div className={`form--item ${errors?.city && "error"}`}>
              <label>Город</label>
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    isClearable
                    isDisabled={cities.loading}
                    isLoading={cities.loading}
                    options={cities.items}
                    styles={customStyles}
                    placeholder={<div>Выберите...</div>}
                    formatCreateLabel={(userInput) => `Создать: ${userInput}`}
                    onCreateOption={(option) => onCityCreate(option)}
                    onChange={handleCityChange}
                  />
                )}
              />
              {errors?.city && <div className="error_text">Выберите город</div>}
            </div>
            <div className={`form--item ${errors?.price2 && "error"}`}>
              <label>Цена ($)</label>
              <input
                type="number"
                {...register("price2", { required: true })}
              />
              {errors?.price2 && (
                <div className="error_text">заполните поле </div>
              )}
            </div>

            <div className={`form--item ${errors?.date && "error"}`}>
              <label>Дата (от и до)</label>

              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    locale="ru"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd.MM.yyyy"
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                )}
              />
              {errors?.date && <div className="error_text">Выберите дату </div>}
            </div>

            <div className="form--item">
              <button>
                Добавить
                <img src={nextArrowSvg} alt="" />
              </button>
            </div>
          </div>
        </form>

        <div style={{ height: 300, overflowY: "scroll" }}>
          <table>
            <thead>
              <tr style={{ height: "40px" }}>
                <td></td>
                <td>Город</td>
                <td>Цена ($)</td>
                <td>Дата (от) - (до)</td>
              </tr>
            </thead>
            {item.length !== 0 ? (
              <tbody>
                {sortedPrice.map((item: any) => (
                  <PriceList
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    deleteAirline_price={deleteAirline_price}
                    id={item.id}
                    airlineId={id}
                    cities={cities}
                    key={item.id}
                    setModal={setModal}
                    //  remove={handleDeleteHotel}
                    item={item}
                  />
                ))}
              </tbody>
            ) : null}
          </table>
          {item.length == 0 ? (
            <div className="profileAji__warning">
              <div>Вы не создали цену.</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
