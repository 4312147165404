import React from 'react'
import { CRUD, EMode, EType } from '../CRUD'

export const TourCreate = () => {
  return (
    <div>
      <CRUD mode={EMode.CREATE} type={EType.TOUR} />
    </div>
  )
}
