import DatePicker from "react-datepicker";
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import Select from 'react-select'
import { useEffect, useState } from 'react'


import clearFilter from 'assets/svg/material-symbols_filter-alt-off-outline-rounded.svg'
import './HeaderFoot.css'
import { FlightThings } from './Flights/component/flight-things'
import { ToursCRUD } from 'redux/slices/tours'
import { fetchTourCategory } from 'redux/slices/tour_category'
import { fetchUsers } from 'redux/slices/users'
import { Pagination } from 'components/admin/Pagination'
import imgDate from 'assets/site-svgs/dateFilter.svg'
import bookmark from 'assets/site-svgs/bookmark.svg'
import imgPrice from 'assets/site-svgs/priceFIlter.svg'
import usersFilt from 'assets/site-svgs/userFilter.svg'
import searchFilter from 'assets/site-svgs/searchFilt.svg'
import {useTranslation } from 'react-i18next';


const priceOption = [
  { id: 2, value: '1000', label: '1000$' },
  { id: 3, value: '1200', label: '1200$' },
  { id: 4, value: '1400', label: '1400$' },
  { id: 5, value: '1800', label: '1800$' },
  { id: 6, value: '2200', label: '2200$' },
]

const defaultState = {
  date: ['', ''],
  category: '',
  price__gt: '',
  price__lt: '',
  user: ''
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
  }),
}


const HeaderFoot = ({ boxList, loading }) => {
  const {t} = useTranslation()
  const { tours,users, tour_categories } = useAppSelector(state => state);

  const dispatch = useAppDispatch()
  const [query, setQuery] = useState(defaultState)
  const handlePaginate = (number) => {
    dispatch(ToursCRUD.fetchPublic({
      flight_date_min: query.date[0].constructor === Date ? query.date[0]?.toISOString().slice(0, 10) : '',
      flight_date_max: query.date[1].constructor === Date ? query.date[1]?.toISOString().slice(0, 10) : '',
      category: query.category.value ? query.category.value : '',
      price__gt: query.price__gt.value ? query.price__gt.value : '',
      price__lt: query.price__lt.value ? query.price__lt.value : '',
      user: query.user.value ? query.user.value : '',
      page: number
    }))
    window.scrollTo(0, 600)
  }

  useEffect(() => {
    dispatch(fetchTourCategory())
    dispatch(fetchUsers())
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(ToursCRUD.fetchPublic({
      flight_date_min: query.date[0].constructor === Date ? query.date[0]?.toISOString().slice(0, 10) : '',
      flight_date_max: query.date[1].constructor === Date ? query.date[1]?.toISOString().slice(0, 10) : '',
      category: query.category.value ? query.category.value : '',
      price__gt: query.price__gt.value ? query.price__gt.value : '',
      price__lt: query.price__lt.value ? query.price__lt.value : '',
      user: query.user.value ? query.user.value : '',
      page: 1
    }))

  }

  const handleSet = (data, name) => {
    console.log('name', data, name);
    setQuery({
      ...query,
      [name]: data
    })
  }

  let index = priceOption.filter((item) => item.id !== 1);

  const handleReset = () => {
    setQuery({
      ...query,
      date: ['', ''],
      category: '',
      price__gt: '',
      price__lt: '',
      user: ''
    })
    dispatch(ToursCRUD.fetchPublic({
      flight_date_min: '',
      flight_date_max: '',
      category:  '',
      price__gt: '',
      price__lt: '',
      user: '',
      page: 1
    }))
  }

  const FilterUsers = users.data.results.filter((user) => user.role === 4 || user.role === 3)

  return (
    <div className="container">
      <div className='wrapper__container-headerfoot'>
        <form>
          <div className="Header__footer-bottom">
            <div className="wrapper-footer__inside">
              <div className="wrapper-footer__about-filters">

                <div className="wrapper-footer__about-information">
                  <div className="wrapper-begin">
                    <img src={imgDate} alt="" />
                    <div className="right-begin__wrapper">
                      {t('home.text4')}
                    </div>
                  </div>
                  <div className="wrapper-date">

                    <DatePicker
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      name='date'
                      placeholderText={t('home.text7')}
                      styles={customStyles}
                      selectsRange={true}
                      minDate={new Date()}
                      className='selected'
                      startDate={query.date[0]}
                      endDate={query.date[1]}
                      onChange={(e) => {
                        handleSet(e, 'date');
                      }}
                    />
                  </div>

                </div>
                <div className="wrapper-footer__about-information">
                  <div className="wrapper-begin">
                    <img src={bookmark} alt="" />
                    <div className="right-begin__wrapper">
                      {t('home.text26')}
                    </div>
                  </div>

                  <div className="wrapper-date">

                    <Select
                      name='category'
                      placeholder={t('home.text8')}
                      className='select_category'
                      styles={customStyles}
                      defaultValue={query.category}
                      value={query.category}
                      options={
                        tour_categories.items.map((tour) => {
                          return {
                            label: tour.title,
                            value: tour.id,
                          }
                        })
                      }
                      onChange={e => handleSet(e, 'category')}
                    />
                  </div>
                </div>
                <div className="wrapper-footer__about-information">
                  <div className="wrapper-begin">
                    <img src={imgPrice} alt="" />
                    <div className="right-begin__wrapper">
                    {t('home.text6')}
                    </div>
                  </div>
                  <div className="wrapper-date">
                    <Select
                      name='price__gt'
                      className='fromSelect'
                      placeholder='1000$'
                      isSearchable={false}
                      styles={customStyles}
                      defaultValue={priceOption[0]}
                      value={query.price__gt}
                      options={priceOption}
                      onChange={e => handleSet(e, 'price__gt')} />
                    <p>-</p>
                    <Select
                      name='price__lt'
                      className='toSelect'
                      placeholder='2200$'
                      isSearchable={false}
                      defaultValue={index[0]}
                      value={query.price__lt}
                      options={index}
                      styles={customStyles}
                      onChange={e => handleSet(e, 'price__lt')} />
                  </div>
                </div>
                <div className="wrapper-footer__about-information">
                  <div className="wrapper-begin">
                    <img src={usersFilt} alt="" />
                    <div className="right-begin__wrapper">
                      Ажы-Баши
                    </div>
                  </div>
                  <div className="wrapper-date">
                    <Select
                      placeholder={t('home.text9')}
                      name='user'
                      className='select_category'
                      defaultValue={query.user}
                      value={query.user}
                      styles={customStyles}
                      options={ 
                        FilterUsers.flatMap((user) => {
                          if((user.role === 3 || user.role === 4) && user.is_active === true){
                              return {
                              label: user.first_name,
                              value: user.id
                              }
                          }else{
                            return []
                          }
                        })
                      }
                      onChange={e => handleSet(e, 'user')}
                    />
                  </div>
                </div>
              </div>
              <div className="btns_information">
                <button onClick={handleSubmit} className="wrapper-footer__search" type='submit'>
                  <div className="input-in__hiiden">
                    <label>Найти туры</label>
                  </div>
                  <img src={searchFilter} alt="" />
                </button>
                {
                  query.category || query.user || query.price__gt || query.price__lt || query.date[0] ?
                    <div className="wrapper-foter__clear" onClick={() => handleReset()}>
                      <img src={clearFilter} alt="" />
                      <p>
                        {t('home.text25')}
                      </p>
                    </div>
                    :
                    ''
                }
              </div>
            </div>
          </div>
          <div className="flights-wrapper">
            {
              tours.data.results.flatMap((result) => result.amount_of_people > result.registered_people ? <FlightThings key={result.id} result={result} loading={loading}/> : [] )
            }
          </div>
          <Pagination data={tours.data} handle={handlePaginate} />
        </form>

      </div>
    </div>

  )
}

export default HeaderFoot;