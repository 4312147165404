import React, { useEffect, useState } from 'react'
import Answer from '../../components/Answers/answer'
import Uscontact from '../../components/ContactUS/Uscontact'
import Footer from '../../components/Footer/Footer'
import Hadja from '../../components/Hadja/hadja'
import Header from '../../components/Header/header'
import Main from '../../components/Main/main'
import Question from '../../components/Questions/question'
import './home-page.css'
import HeaderFoot from 'components/HeaderFoot/HeaderFoot'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ToursCRUD } from 'redux/slices/tours'
import arrow from 'assets/site-svgs/aroow_top.svg'
import { ToastContainer, toast } from 'react-toastify';


export const HomePage = () => {
  const dispatch = useAppDispatch();

  const { error, loading, data } = useAppSelector((state) => state.tours);
  const { count, next, previous, results } = data;


  useEffect(() => {
    dispatch(ToursCRUD.fetchPublic());
  }, []);

  const [activeIcon, setActiveIcon] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        setActiveIcon(true)
      } else {
        setActiveIcon(false)
      }
    })
  }, [])

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className='home-page'>
      <Header />
      <HeaderFoot boxList={results} loading={loading} />
      <Main />
      <Hadja />
      <Question />
      <Answer />
      <Uscontact />
      <Footer />
      {
        activeIcon ?

          <div className='icon-arow' onClick={scrollUp} >
            <img src={arrow} alt="" />
          </div> : null

      }
      <ToastContainer />
    </div>
  )
}

