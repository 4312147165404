import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ResetCRUD } from "redux/slices/reset";
import resetSave from 'assets/svg/save-password.svg'
import whatsSave from 'assets/svg/whats-reset.svg'
import profileIcon from "../../../assets/svg/icons/profileIcon.svg";
import { Link, useNavigate, useNavigation } from "react-router-dom";


export const User = ({uuid, item, ConfirmUser, RejectNewUser }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(ResetCRUD.reset(item.id))
  }, [])

  const [sendLink, setSendLink] = useState(`${window.location.origin}/reset/${item.id}/${uuid}`)

  const params = new URLSearchParams({text: sendLink}).toString()

  const [appearLink, setAppearLink] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(sendLink)
  }
  return (
    <div className="AjiHeads" key={item.id}>
      {
        <div key={item.id} className="user_list">
          <div className="block__to-media__users-img">

            <div className="user_info_icon">
              <img src={item.avatar ? item.avatar : profileIcon} alt="" />
            </div>
            <div className="user_info">
              <div className="user_info_title">Имя</div>
              <div className="user_info_text">
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </div>
          <div className={appearLink ? "about__phones-user active" : "about__phones-user"}>


            <div className="user_info">
              <div className="user_info_title">Номер телефона</div>
              <div className="user_info_text">{item.phone}</div>
            </div>

            {
              appearLink ?
                <div className={"user_info active"}>
                  <div className="user_info_title">Ссылка для восстановления аккаунта</div>
                  <div className="user_info_input">
                    <Link to={`/reset/${item.id}/${uuid}`}>
                      <label style={{borderBottom: '1px solid #125d98', cursor: 'pointer'}} className="user_info_title">{uuid}</label>
                    </Link>
                    {/* <a href="" className="reset__pasword" onClick={ToResetPassword}>{item.reset_password[0].uuid}</a> */}
                  </div>
                </div>
                :
                <div className="user_info">
                  <div className="user_info_title">Номер телефона ватсап</div>
                  <div className="user_info_text">{item.whatsapp}</div>
                </div>
            }

          </div>
          {(item.is_active === false) ? <div className="user_info_btns">
            <button onClick={() => ConfirmUser(item.id)} className="user_info_btn_1">Потвердить</button>
            <button onClick={() => RejectNewUser(item.id)} className="user_info_btn_2">Отклонить</button>
          </div> : null}

          {
            // appearLink ?
              <div className="user_info_btns">
                <img src={resetSave} alt="" onClick={copy} />
                <a href={`https://api.whatsapp.com/send?${params}`}>
                  <img src={whatsSave} alt="" onClick={() => setAppearLink(false)} />
                </a>
              </div>
              // :
              // (item.is_active === true) ? <div className="user_info_btns">
              //   <button className="user_info_btn_1" onClick={() => setAppearLink(true)}>Восстановить</button>
              //   <button className="user_info_btn_2">Редактировать</button>
              // </div> : null
          }
        </div>
      }

      <div className="copy__obman">
      </div>
    </div>
  );
};
