import { useAppSelector,useAppDispatch } from 'redux/hooks'
import { RequestItem } from "pages/admin/Home/pages/RequestItem";
import { useState } from "react";
import angle from "assets/svg/angle_down_icon.svg";
import { NCToursCRUD } from 'redux/slices/not_confirmed_tours';
import loading_icon from "assets/loading_umra.gif"



interface Props {
  items: any[];
  title?: string;
  setItems?: any;
  loading? :boolean;
}


export const Items = ({ items, title, loading=false }: Props) => {
  const {profile} = useAppSelector(state => state)
  const dispatch = useAppDispatch()

  const isAgent = profile.data.role === 3 || profile.data.role === 4
  const  [sortByFlight, setSortByFlight] = useState(false)
  const  [isLoading, setLoading] = useState(false)

  const hundleSubmit = async (e:any) => {
    e.preventDefault();
    setLoading(prev => !prev)
      dispatch(NCToursCRUD.fetch(
      isAgent ?`${profile.data.id}`:'',
      sortByFlight ? 'date' :'-date'))
    setLoading(prev => !prev) ;setSortByFlight(prev => !prev)
  }

  return (
    <div className="Applications">

      <div className="application__items">
        <div className="title">{title}</div>
        <table>
          <thead>
            <tr>
              <td>№ / ID</td>
              <td>Заявка</td>
              <td>Kоличество<br />дней</td>
              <td
              style={{cursor : 'pointer'}}
              onClick={(e) => {hundleSubmit(e)}}
              >
              <span>Дата<br />Город вылета</span>
              {
                isLoading ? '...' : <img style={sortByFlight ? {rotate:'180deg'} : undefined} src={angle} alt="" /> 
              }
              </td>
              <td>Авиакомпания</td>
              <td>Гостиницы</td>
              <td>Сумма</td>
              <td>Места</td>
              <td>Действия</td>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={10}>
                  <div className="center-msg">Данные загружаются... (<img width={15} height={15} src={loading_icon} alt='image'/>)</div>
                </td>
              </tr> 
            ) : (
              
               items.length > 0 ? items.map((item, index) => (
                  <RequestItem key={item.id} item={item} index={index} />
                )) : (
                <tr>
                    <td colSpan={10}>
                      <div className="center-msg">Пока пусто!</div>
                    </td>
                </tr>
                )
            )}
      
          </tbody>
        </table>
        {/* <Pagination items={items} setItems={setItems} len={2} /> */}
      </div>
    </div>
  );
};


