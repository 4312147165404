import React, { SetStateAction, useState } from "react";
import logout from 'assets/svg/icons/logout.svg';
import { Sidebar } from "./Sidebar";
import logoSvg from 'assets/svg/logo.svg';
import sidebar_icon1 from 'assets/svg/icons/sidebar__icons/sidebar_icon1.svg'
import sidebar_icon2 from 'assets/svg/icons/sidebar__icons/sidebar_icon2.svg'
import sidebar_icon3 from 'assets/svg/icons/sidebar__icons/sidebar_icon3.svg'
import sidebar_icon4 from 'assets/svg/icons/sidebar__icons/sidebar_icon4.svg'
import sidebar_icon5 from 'assets/svg/icons/sidebar__icons/sidebar_icon5.svg'
import sidebar_icon6 from 'assets/svg/icons/sidebar__icons/sidebar_icon6.svg'
import sidebar_icon7 from 'assets/svg/icons/sidebar__icons/sidebar_icon7.svg'
import sidebar_icon8 from 'assets/svg/icons/sidebar__icons/sidebar_icon8.svg'
import { SidebarItem } from "./Sidebar/Item";
import { PermissionsGate } from "hooks/usePermissions";
import { Link } from "react-router-dom";
interface Props {
  setActiveBurger: React.Dispatch<SetStateAction<boolean>>;
  activeBurger: boolean;
}

export const MenuBurger = ({ activeBurger, setActiveBurger }: Props) => {
  return (
    <div className={activeBurger ? 'MenuBurger' : 'none'} onClick={() => setActiveBurger(false)}>
      <div className="MenuBurger_content" onClick={(e) => e.stopPropagation()}>
        <div className="burger" onClick={() => setActiveBurger(false)}>
          <div className="burger__main">
            <Link to='/' className="logo">
              <img src={logoSvg} alt="" />
            </Link>
            <div className="burger_links">
              <SidebarItem img={sidebar_icon1} to={''} title='Главная' />
              <SidebarItem img={sidebar_icon2} to={'directory'} title='Справочник' />
              <PermissionsGate place='create_tour' children={<SidebarItem img={sidebar_icon3} to={'tour'} title='Создать тур' />} />
              <PermissionsGate place='create_nct' children={<SidebarItem img={sidebar_icon3} to={'nct'} title='Создать заявку' />} />
              <SidebarItem img={sidebar_icon4} to={'templates'} title='Готовый пакет' />
              <SidebarItem img={sidebar_icon5} to={'control-panel'} title='Расписание рейсов' />
              <PermissionsGate place='agents' children={<SidebarItem img={sidebar_icon6} to={'agents'} title='Агенты' />} />
              <PermissionsGate place='users' children={<SidebarItem img={sidebar_icon8} to={'users'} title='Заявки на вход' />} />
              <SidebarItem img={sidebar_icon7} to={'profile'} title='Профиль' />
            </div>
          </div>
          <Link to='/' className="menu_btn">
            <button>
              <img src={logout} alt="" />
              На главную
            </button>
          </Link>
      </div>
      </div>
    </div>
  );
};
