import { useState, useEffect } from 'react';
// import { useForm, useController, Controller, SubmitHandler, useWatch } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from '../../../../../redux/hooks';
import { fetchVisas, saveVisa } from '../../../../../redux/slices/visa';

export const Visa = () => {
  const dispatch = useAppDispatch();

  // hook to fetch items
  useEffect(() => {
    dispatch(fetchVisas());
  }, [dispatch]);

  // fetch data from our store
  const { visa } = useAppSelector((state) => state);

  const [isEdit, setIsEdit] = useState(false);

  const [price, setPrice] = useState<number | string>(visa.price);

  const [priceError, setPriceError] = useState(false);

  useEffect(() => {
    setPrice(visa.price);
  }, [visa.price]);

  useEffect(() => {
    if (Number(price) < 1) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  }, [price]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      saveVisa({
        price: price,
      }),
    );
    setIsEdit(false);
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    setIsEdit(true);
  };

  return (
    <div className="Visa">
      <div className="title">Виза</div>

      <form className="visa_form">
        <div className="price">
          <input
            // defaultValue={visa.price}
            value={price}
            placeholder="Виза - цена не указано"
            className={`${priceError ? 'error' : ''}`}
            type="number"
            name="price"
            id="price"
            min={1}
            onChange={(e) => setPrice(Number(e.target.value))}
            disabled={!isEdit}
          />
          {isEdit ? <button onClick={(e) => onSubmit(e)}>Сохранить</button> : null}
          {Number(price) > 0 && !isEdit ? (
            <label className="label_price" htmlFor="price" onClick={(e) => handleEdit(e)}>
              Редактировать
            </label>
          ) : null}
          {Number(price) === 0 && !isEdit ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsEdit(true);
              }}>
              Указать цену
            </button>
          ) : null}
        </div>
      </form>
    </div>
  );
};
