import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ResetCRUD } from 'redux/slices/reset';

import { editUser, fetchUsers } from 'redux/slices/users';
import { User } from './User';

export const Recover = () => {
  const dispatch = useAppDispatch();

  const { users, reset } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(ResetCRUD.fetch());
  }, [reset.data.results.length]);

  const [category, setCategory] = useState(0);

  const [list, setList] = useState<any>(
    users.data.results.filter((item) => item.role === category && item.is_active === false) || [],
  );

  useEffect(() => {
    setList(users.data.results.filter((item) => item.role === category && item.is_active === false) || []);
  }, [users.data.results.length, reset.data.results.length]);

  const ConfirmUser = (id: any) => {
    dispatch(
      editUser(id, {
        is_active: true,
      }),
    );
  };

  const handleChangeTab = (tab: number) => {
    if (tab === 4 || tab === 3 || tab === 2) {
      setCategory(tab);
      setList(users.data.results.filter((item) => item.role === tab && item.is_active === false) || []);
    } else if (tab === 0) {
      setCategory(tab);
      setList(users.data.results.filter((item) => item.is_active === true) || []);
    }
  };

  return (
    <>
      {category === 0
        ? reset.data.results.length !== undefined
          ? reset.data.results.map((item: any) => (
              <User
                uuid={item.uuid}
                key={item.user.id}
                item={item.user}
                ConfirmUser={ConfirmUser}
              />
            ))
          : null
        : list.map((item: any) => (
            <User uuid={category} key={item.id} item={item} ConfirmUser={ConfirmUser} />
          ))}
    </>
  );
};
