import {useAppDispatch, useAppSelector} from "redux/hooks";
import {ApplicationItem} from "./ApplicationItem";
import {ToursAllCRUD} from 'redux/slices/tours_all';
import {useState} from "react";
import angle from "assets/svg/angle_down_icon.svg";
import loading_icon from "assets/loading_umra.gif"

interface Props {
    items: any[];
    title: string;
    loading?: boolean
}


export const ApplicationItems = ({items, title, loading = false}: Props) => {
    const dispatch = useAppDispatch()
    const {profile, tours_all} = useAppSelector(state => state)
    const is_agent = profile.data.role === 3 || profile.data.role === 4 ? true : false

    const [sortByFlight, setSortByFlight] = useState(false)
    const [isLoading, setLoading] = useState(false)


    const hundleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(prev => !prev)
        dispatch(ToursAllCRUD.fetch(
            is_agent ? `${profile.data.id}` : '',
            sortByFlight ? 'flight__date_from' : '-flight__date_from'))
        setLoading(prev => !prev);
        setSortByFlight(prev => !prev)
    }

    return (
        <div className="application__items">
            <div className="title">{title}</div>

            <table>
                <thead>
                <tr>
                    <td>№ / ID</td>
                    <td>Агент</td>
                    <td>Дни<br/>Пакет</td>
                    <td
                        style={{cursor: 'pointer'}}
                        onClick={(e) => {
                            hundleSubmit(e)
                        }}>
                        <span>Дата<br/>Город вылета</span>
                        {
                            isLoading ? '...' :
                                <img style={sortByFlight ? {rotate: '180deg'} : undefined} src={angle} alt=""/>
                        }
                    </td>
                    <td>Авиакомпания<br/>Маршрут</td>
                    <td>Гостиницы</td>
                    <td>Сумма</td>
                    <td>Места</td>
                    {/* <td>Статус</td> */}
                    <td>Действия</td>
                </tr>
                </thead>

                <tbody>
                {loading ? (
                    <tr>
                        <td colSpan={10}>
                            <div className="center-msg">Данные загружаются... (<img width={15} height={15}
                                                                                    src={loading_icon} alt='Image'/>)
                            </div>
                        </td>
                    </tr>
                ) : (
                    tours_all.error ? (
                        <tr>
                            <td colSpan={10}>
                                <div className="center-msg">Упс, что-то пошло не так или плохое соединение!</div>
                            </td>
                        </tr>
                    ) : (
                        items?.length > 0 ? items.map((item, index) => (
                                <ApplicationItem key={item.id} item={item} index={index}/>
                            )) :
                            <tr>
                                <td colSpan={10}>
                                    <div className="center-msg">Пока пусто!</div>
                                </td>
                            </tr>
                    )
                )
                }
                </tbody>
            </table>

        </div>
    )
}