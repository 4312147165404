import React, { useState, useEffect } from 'react'

import './flights-book.css'
import { useAppDispatch } from 'redux/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import AboutHotels from './AboutHotels/AboutHotels'
import ModalBook from '../component/ModalBook/ModalBook'


import moment from 'moment'
import defaultImg from 'assets/imgs/user_default.svg'
import mecca from 'assets/imgs/mecca.svg'
import cityosh from 'assets/site-svgs/cityosh.svg'
import date_of from 'assets/site-svgs/date.svg'
import port from 'assets/site-svgs/airway.svg'
import standart from 'assets/imgs/standarter.svg'
import bajus from 'assets/site-svgs/bajys.svg'
import priceTour from 'assets/site-svgs/tour-price.svg'
import vopros from 'assets/site-svgs/tour_thing.svg'
import present from 'assets/site-svgs/present.svg'
import takyr from 'assets/site-svgs/takyr.svg'
import bus from 'assets/site-svgs/bus.svg'
import visa from 'assets/site-svgs/visa.svg'
import jidda from 'assets/site-svgs/jidda.svg'
import excursion from 'assets/site-svgs/excursion.svg'
import documenters from 'assets/site-svgs/documenters.svg'
import { ToursCRUD } from 'redux/slices/tours'
import { getRemainingSeats } from 'utils/functions'
import { FlightTime } from '../component/Flight-Times/FlightTIme'
import { API_URL } from 'services';
import { useTranslation } from 'react-i18next'



const FlightsBook = () => {
    const { t } = useTranslation()
    const [modalBook, setModalBook] = useState(false)
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [tour, setTour] = useState({})
    const [error, setError] = useState()

    useEffect(() => {
        fetch(`${API_URL}/tours/${id}`)
            .then(res => res.json())
            .then(data => {
                setTour(data)
            })
    }, [])

    useEffect(() => {
        if (Object.keys(tour).length === 0) {
            dispatch(ToursCRUD.fetchItem(id, setTour, setError))
        }

    }, [tour])

    // const { hotels } = useAppSelector(state => state)
    const { flight, city, user, amount_of_people, firm_registered, hajhead_registered, category, amount_of_days, desired_hotels, public_price, description, desired_services, desired_excursions } = tour

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/profile/${user.id}`)
    }

    if (!tour) {
        return <div className='center-msg'>Нет тура с таким ID</div>
    }


    return (
        <div className='Flights'>
            <div className="container">
                <div className="flights-wrapper__book">
                    <div className="inner__book">

                        <div className="flights__box2">
                            <div className="flights-box__inside">

                                <div className="left_the-icon__ajy">  {

                                    user?.avatar ?
                                        <img src={user?.avatar} alt="" className='fliht-ajy' width='102' height='102' />
                                        :
                                        <img src={defaultImg} alt="" className='fliht-ajy' width='102' height='102' />

                                }
                                    <div className="flights-tours_hide" onClick={handleClick}> <p className='city'>{amount_of_days} {t('home.text13')}</p> <b>{user?.first_name} Ажы</b> </div>

                                </div>
                                <div className="right_flight-all">
                                    <div className="top-display__flexed">
                                        <div className="flights-tours" onClick={handleClick}> <p className='city'>{amount_of_days} {t('home.text13')}</p> <b>{user?.first_name} Ажы</b> </div>
                                    </div>
                                    <div className="right__side">
                                        <div className="indise__right-side">
                                            <div className="flight-inspection">
                                                <div className="date-media">
                                                    <div className="flights-date">
                                                        <img src={date_of} alt="" />
                                                        <div className="date">
                                                            {moment(flight?.date_from).format('L')} - {moment(flight?.date_to).format('L')}
                                                        </div>
                                                    </div>
                                                    <div className="flight-city">
                                                        <img src={cityosh} alt="" />

                                                        <div className="city">
                                                            {city?.title}
                                                        </div>
                                                    </div>
                                                    <div className="flight-plane">
                                                        <img src={port} alt="" />

                                                        <div className="city">
                                                            {flight?.airline_detail.title}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hotel-media">
                                                    <div className="city">
                                                        {t('book.word5')}:
                                                    </div>

                                                    {desired_hotels?.map((item) => {
                                                        return (
                                                            <div className='two-hotelmap' key={item.id}>
                                                                <div className='icon-in__hotel'>
                                                                    {
                                                                        item.hotel.icon ?
                                                                            <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><img src={item.hotel.icon} alt="" /></a>
                                                                            :
                                                                            <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><img src={mecca} alt="" /></a>
                                                                    }
                                                                </div>
                                                                <a target="_blank" rel="noopener noreferrer" href={item.hotel.link}><span className='city'>{item.hotel.title}</span></a>

                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                            <div className="about-flight__times1">
                                                <FlightTime flight={flight} />
                                                <div className="flight-times__right">
                                                    <div className="standart-flight">
                                                        {
                                                            category?.icon ?
                                                                <img src={category?.icon} alt="" />
                                                                :
                                                                <img src={standart} alt="" />

                                                        }
                                                        <div className="standart">
                                                            {category?.title ?
                                                                category.title
                                                                :
                                                                'Стандарт'
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flight-price">
                                                        <img src={priceTour} alt="" />
                                                        <div className="price" >
                                                            {public_price}
                                                        </div>
                                                    </div>
                                                    <div className="other-people">

                                                        <div className="people_icon">
                                                            <img src={bajus} alt="" />
                                                            <div className="many__place">  {t('home.text11')}: </div>
                                                            <b>
                                                                {getRemainingSeats((amount_of_people - hajhead_registered) - firm_registered)}
                                                            </b>
                                                        </div>
                                                        <ModalBook modalBook={modalBook} setModalBook={setModalBook} />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="flight__footer">
                                                <button className="book" onClick={() => setModalBook(true)}>{t('home.text12')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            description && description !== "<p><br></p>" ?
                                <div className="description__wrappe">
                                    <div className="include__in-tour">{t('book.word1')}</div>

                                    <div style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: description }} />


                                </div> :
                                null
                        }

                        <div className="about__wrapper-tours">
                            <div className="media__block-tours">

                                <div className="left-tours__excursion">
                                    <div className="include__in-tour">{t('book.word1')} <img src={vopros} alt="" /></div>
                                    <div className="include-tour__inside">
                                        <ul>
                                            <li><img src={takyr} alt="" /> Опытный Гид</li>
                                            <li><img src={visa} alt="" /> Виза</li>
                                            <li><img src={bus} alt="" /> Транспорт</li>
                                        </ul>
                                    </div>
                                    <div className="include__in-tour">Экскурсия <img src={excursion} alt="" /></div>
                                    <div className="include-tour__inside">
                                        <ul>
                                            {desired_excursions?.map(excursion => <li key={excursion.id} ><img src={jidda} alt="" /> {excursion?.excursion?.place_title}</li>)}
                                        </ul>
                                    </div>
                                </div>
                                <div className="right-tours__excursion">
                                    <div className="include__in-tour">{t('book.word3')} <img src={present} alt="" /></div>
                                    <div className="include-tour__inside">
                                        <ul>
                                        {desired_services?.map(service => <li key={service.id}><img src={present} alt="" /> {service?.additional_services?.title}</li>)}
                                        </ul>
                                    </div>
                                    <div className="include__in-tour">{t('book.word4')} <img src={documenters} alt="" /></div>
                                    <div className="include-tour__inside">
                                        <ul>
                                            <li><img src={visa} alt="" /> Паспорт</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="two__hotels">
                            <div className="about__hotels">
                                {
                                    desired_hotels?.map((hotel, index) => <AboutHotels key={hotel.id} hotel={hotel} />)
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlightsBook
