import { useEffect, useState } from "react";

import CreatableSelect, { useCreatable } from "react-select/creatable";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import {
  setLoading,
  deleteExcursion,
  editExcursion,
  fetchExcursions,
  saveExcursion,
} from "../../../../../redux/slices/excursions";
import { ExcursionList } from "./ExcursionList";
import { fetchCities, saveCity } from "../../../../../redux/slices/cities";
import { editHotel } from "redux/slices/hotels";


interface IFormInput {
  price: number;
  place: any;
}

export const Excursion = () => {
  const [editId, setEditId] = useState("")
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 300,
      border: "none",
      borderBottom: "1px solid #474665",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const [get, setGet] = useState(false);

  // set up dispatch
  const dispatch = useAppDispatch();

  // hook to fetch items
  useEffect(() => {
    dispatch(fetchExcursions());
    dispatch(fetchCities());
  }, [dispatch,get]);

  // fetch data from our store
  const { excursions, cities } = useAppSelector((state) => state);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log("data", data);
    dispatch(
      saveExcursion({
        ...data,
        place: data.place.value,
      })
    );

    reset();
    handleCityChange("");
  };

  const handleCityChange = (newValue: any) => {
    setValue("place", newValue);
  };

  const onCityCreate = (inputValue: string) => {
    dispatch(
      saveCity(
        {
          title: inputValue,
        },
        handleCityChange
      )
    );
  };

  const handleDeleteHotel = (id: number) => {
    dispatch(deleteExcursion(id));
  };


  //____________UPDATE______________________
  const handleEditHotel = (id: number, data: any) => {
    dispatch(setLoading())
    dispatch(
      editExcursion(id, {
        ...data
      })
    );
    dispatch(fetchExcursions())
    setGet(!get);
  };

  

  
  // render the items
  const renderExcursions = () => {
    // loading state
    
    // error state
    if (excursions.error)
      return (
        <tr>
          <td colSpan={10}>Список, недоступен в данный момен...</td>
        </tr>
      );

    // regular data workflow
    return excursions.items.map((item: any, index: number) => (
      <ExcursionList
        editId={editId}
        setEditId={setEditId}
        loading ={excursions.loading}
        index={index}
        key={item.id}
        item={item}
        remove={handleDeleteHotel}
        edit={handleEditHotel}
      />
    ));
  };

  return (
    <div className="excursion__inner">
      

      <div className="title">Доп.экскурсии</div>
      <div className="excursion__data">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={`form--item ${errors?.place && "error"}`}>
            <label>Город</label>
            <Controller
              name="place"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isClearable
                  isDisabled={cities.loading}
                  isLoading={cities.loading}
                  options={cities.items}
                  styles={customStyles}
                  placeholder={<div>Выберите...</div>}
                  formatCreateLabel={(userInput) => `Создать: ${userInput}`}
                  onCreateOption={(option) => onCityCreate(option)}
                  onChange={handleCityChange}
                />
              )}
            />
            {errors?.place && <div className="error_text">Выберите город</div>}
          </div>

          <div className={`form--item ${errors?.price && "error"}`}>
            <label>Цена ($)</label>
            <input
              type="number"
              {...register("price", { required: true, min: 0 })}
            />
            {errors?.price && <div className="error_text">заполните поле </div>}
          </div>

          <div className="form--item submit">
            <button type="submit">Добавить экскурсию</button>
          </div>
        </form>

        <table>
          <thead>
            <tr>
              <td>№</td>
              <td></td>
              <td>Место Экскурсии</td>
              <td>Цена ($)</td>
              <td>Активный</td>
              <td>Выбранный</td>
              <td>Обязательный</td>
            </tr>
          </thead>

          <tbody>{renderExcursions()}</tbody>
        </table>

       
      </div>
    </div>
  );
};
