import React, { SetStateAction } from 'react';
import close_x from '../../../../assets/svg/icons/x_close.svg';

interface Props {
  activeDescribe: boolean;
  setActiveDescribe: React.Dispatch<SetStateAction<boolean>>;
}

export const ModalDescribe = ({ activeDescribe, setActiveDescribe }: Props) => {
  return (
    <div
      className={activeDescribe ? 'modal_describe' : 'modal_active'}
      onClick={() => setActiveDescribe(false)}>
    </div>
  );
};
