import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { api } from '../../../services'

import { RoomCategoryState } from './types'

const initialState: RoomCategoryState = {
  loading: false,
  error: false,
  items: [],
}

export const roomCategorySlice = createSlice({
  name: 'room_category',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload.map((item: any) => {
        return {
          ...item,
          value: String(item.id),
          label: String(item.title)
        }
      });
    },
    setError: (state) => {
      state.error = true;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        ...payload,
        value: String(payload.id),
        label: String(payload.title)
      });
    }
  },
})

// export the actions
export const { setLoading, setItems, setError, addItem } = roomCategorySlice.actions;


// // export the selector (".items" being same as in slices/index.js's "items: something")
// export const roomCategorySelector = (state: { items: any }) => state.items;

// export the default reducer
export default roomCategorySlice.reducer;


// fetch all roomCategory
export function fetchRoomCategory() {
  setLoading()
  return async (dispatch: Dispatch) => {
    api.get("/room_categories/?use_pagination=false")
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

// add new hotel
export function saveRoomCategory(data={}, setRoom: any) {
  return async (dispatch: Dispatch) => {
    api.post("/room_categories/", data)
      .then((response) => {
        dispatch(addItem(response.data));
        setRoom({
          ...response.data,
          value: String(response.data.id),
          label: String(response.data.title)
        })
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}